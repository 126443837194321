/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal,
    "primary": #151d41,
    "secondary": rgb(0, 146, 137),
);


@import "~bootstrap/scss/bootstrap";

@import url(./scss/_home.scss);
@import url(./scss/_footer.scss);
@import url(./scss/_ova-store.scss);
// @import url(./scss/_insur-dark.scss);
@import url(./scss/_insur-responsive.scss);
@import url(./scss/_insur.scss);
@import url(./scss/_common.scss);