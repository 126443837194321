@font-face {
  font-family: Hurme;
  src: url(../../assets/font/HurmeGeometricSans1.otf);
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
select,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: Hurme;
  color: #151d41;
  line-height: 1.4;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
.main-content {
  position: relative;
  z-index: 1;
}
@-webkit-keyframes agency-slider-bar {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes agency-slider-bar {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.main-scroller {
  position: absolute;
  bottom: 30px;
  right: calc(50% - 75px);
  width: 150px;
  z-index: 200;
  text-align: center;
  z-index: 10;
  max-height: 130px;
}
@media screen and (min-width: 768px) {
  .main-scroller {
    bottom: 200px;
  }
}
@media screen and (min-width: 992px) {
  .main-scroller {
    position: fixed;
    width: auto;
    top: 30vh;
    right: 50px;
    bottom: unset;
    text-align: right;
  }
}
.main-scroller .link {
  display: none;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.2s;
}
.main-scroller .link:hover {
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  .main-scroller .link {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .main-scroller .link {
    font-size: 13px;
  }
  .main-scroller .link:after {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 1px solid #151d41;
    border-radius: 50%;
    margin-left: 10px;
  }
}
.main-scroller .link.active {
  font-weight: 700;
}
.main-scroller .link.active:after {
  background-color: #151d41;
}
.main-scroller .scrolltop {
  height: 50px;
  width: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 50px auto 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.034' height='19.529' fill='%23fff'%3E%3Cpath d='M17.76 8.303l.968.968a1.042 1.042 0 010 1.478l-8.469 8.473a1.042 1.042 0 01-1.478 0L.303 10.75a1.042 1.042 0 010-1.478l.968-.968a1.047 1.047 0 011.5.017l5 5.252V1.05A1.044 1.044 0 018.82 0h1.395a1.044 1.044 0 011.046 1.05v12.523l5-5.252a1.04 1.04 0 011.5-.017z'/%3E%3C/svg%3E");
  background-size: auto 50%;
  background-position: 50%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 992px) {
  .main-scroller .scrolltop {
    background: none;
    cursor: pointer;
    position: absolute;
    bottom: -200px;
    right: -10px;
    height: 50px;
    width: 30px;
    border: 1px solid #151d41;
    border-radius: 30px;
  }
  .main-scroller .scrolltop:after {
    position: absolute;
    bottom: 70px;
    right: 10px;
    margin-right: 5px;
    content: "";
    display: block;
    height: 70px;
    width: 1px;
    background-color: #151d41;
    transition: 0.2s;
  }
  .main-scroller .scrolltop:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 9px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #151d41;
  }
}
.main-scroller.dark-bg .scrolltop {
  border-color: #fff;
}
.main-scroller.dark-bg .link {
  color: #fff;
}
.main-scroller.dark-bg .scrolltop:after,
.main-scroller.dark-bg .scrolltop:before {
  background-color: #fff;
}
.main-scroller.dark-bg .link:after {
  border-color: #fff;
}
.main-scroller.dark-bg .link.active:after {
  background-color: #fff;
}
footer.main-footer {
  padding: 70px 15px;
  background-color: #151d41;
}
footer.main-footer .footer-top {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
footer.main-footer .footer-top .logo {
  flex: 0 0 10%;
  font-size: 0;
  text-align: center;
  color: #fff;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-top .logo {
    font-size: 40px;
    font-weight: 700;
  }
}
footer.main-footer .footer-top .logo:before {
  content: "";
  display: block;
  margin: 0 auto 20px;
  height: 100px;
  width: 100px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='85.365' height='85.367'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23fff}.b{fill:%2351b4b0}%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M38.364 85.141q-.611-.063-1.222-.144a3.073 3.073 0 11.8-6.093A36.533 36.533 0 106.204 44.816a3.073 3.073 0 11-6.136.356A42.683 42.683 0 1138.36 85.141z'/%3E%3Cpath class='b' d='M24.091 78.463a.477.477 0 01-.22-.054 40.373 40.373 0 01-18.1-19.307.473.473 0 01.864-.384 39.419 39.419 0 0017.677 18.853.473.473 0 01-.22.892z'/%3E%3Cpath class='a' d='M40.854 23.788l-8.312 15.836-.008.016-4.826-9.52-3.221-6.332h-10.39l18.259 36.965 5.44-10.8 13.175-26.162z'/%3E%3Cpath class='b' d='M52.862 24.229l-5.295 10.017 8.32 16.622H39.908l-4.633 9.907h36.212z'/%3E%3C/svg%3E");
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
footer.main-footer .footer-top .menu {
  margin: 40px 0;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-top .menu {
    flex: 0 0 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }
}
footer.main-footer .footer-top .menu a {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: 0.2s;
  margin-bottom: 20px;
}
footer.main-footer .footer-top .menu a:hover,
footer.main-footer .footer-top .menu a:link,
footer.main-footer .footer-top .menu a:visited {
  text-decoration: none;
  outline: none;
}
footer.main-footer .footer-top .menu a:active,
footer.main-footer .footer-top .menu a:focus {
  background: none;
  outline: none;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  footer.main-footer .footer-top .menu a {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-top .menu a {
    display: inline-block;
    text-align: left;
    margin-bottom: 0;
  }
}
footer.main-footer .footer-top .menu a:hover {
  opacity: 0.5;
}
footer.main-footer .footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
}
footer.main-footer .footer-bottom .branding {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-bottom .branding {
    justify-content: flex-end;
  }
}
footer.main-footer .footer-bottom .branding .socials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
footer.main-footer .footer-bottom .branding .socials:after {
  content: "";
  height: 1px;
  width: 150px;
  background-color: #fff;
}
footer.main-footer .footer-bottom .branding .socials .behance,
footer.main-footer .footer-bottom .branding .socials .facebook,
footer.main-footer .footer-bottom .branding .socials .instagram,
footer.main-footer .footer-bottom .branding .socials .twitter {
  position: relative;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s;
}
footer.main-footer .footer-bottom .branding .socials .behance:hover,
footer.main-footer .footer-bottom .branding .socials .facebook:hover,
footer.main-footer .footer-bottom .branding .socials .instagram:hover,
footer.main-footer .footer-bottom .branding .socials .twitter:hover {
  opacity: 0.5;
}
footer.main-footer .footer-bottom .branding .socials .behance a,
footer.main-footer .footer-bottom .branding .socials .facebook a,
footer.main-footer .footer-bottom .branding .socials .instagram a,
footer.main-footer .footer-bottom .branding .socials .twitter a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
footer.main-footer .footer-bottom .branding .socials .facebook {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.126' height='17.475' fill='%23fff'%3E%3Cpath d='M5.922 17.475V9.514H8.64l.388-3.107H5.922V4.466c0-.874.291-1.553 1.553-1.553h1.65V.1C8.737.1 7.767 0 6.7 0a3.748 3.748 0 00-3.98 4.077v2.33H0v3.107h2.718v7.961z' fill-rule='evenodd'/%3E%3C/svg%3E");
}
footer.main-footer .footer-bottom .branding .socials .twitter {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.475' height='14.174' fill='%23fff'%3E%3Cpath d='M5.534 14.174A10.1 10.1 0 0015.727 3.98v-.485a7.891 7.891 0 001.748-1.845 8.059 8.059 0 01-2.039.582A3.777 3.777 0 0016.99.291a8.9 8.9 0 01-2.233.874A3.467 3.467 0 0012.135 0a3.648 3.648 0 00-3.592 3.592 1.893 1.893 0 00.1.777A10.037 10.037 0 011.265.583 3.718 3.718 0 00.78 2.428a3.857 3.857 0 001.553 3.01 3.273 3.273 0 01-1.65-.485 3.548 3.548 0 002.912 3.495 2.993 2.993 0 01-.971.1 1.652 1.652 0 01-.68-.1 3.678 3.678 0 003.4 2.524 7.33 7.33 0 01-4.466 1.553 2.688 2.688 0 01-.874-.1 9.164 9.164 0 005.534 1.747' fill-rule='evenodd'/%3E%3C/svg%3E");
}
footer.main-footer .footer-bottom .branding .socials .instagram {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.475' height='17.475' fill='%23fff'%3E%3Cpath d='M8.737 1.553a26.791 26.791 0 013.495.1 4.5 4.5 0 011.65.291 3.41 3.41 0 011.65 1.65 4.5 4.5 0 01.291 1.65c0 .874.1 1.165.1 3.495a26.791 26.791 0 01-.1 3.495 4.5 4.5 0 01-.291 1.65 3.41 3.41 0 01-1.65 1.65 4.5 4.5 0 01-1.65.291c-.874 0-1.165.1-3.495.1a26.791 26.791 0 01-3.495-.1 4.5 4.5 0 01-1.65-.291 3.41 3.41 0 01-1.65-1.65 4.5 4.5 0 01-.291-1.65c0-.874-.1-1.165-.1-3.495a26.791 26.791 0 01.1-3.495 4.5 4.5 0 01.291-1.65 3.487 3.487 0 01.68-.971 1.641 1.641 0 01.971-.68 4.5 4.5 0 011.65-.291 26.791 26.791 0 013.495-.1M8.737 0a28.685 28.685 0 00-3.592.1A5.994 5.994 0 003.01.485a3.8 3.8 0 00-1.553.971A3.8 3.8 0 00.485 3.01 4.423 4.423 0 00.1 5.145 28.685 28.685 0 000 8.737a28.685 28.685 0 00.1 3.593 5.994 5.994 0 00.388 2.136 3.8 3.8 0 00.971 1.553 3.8 3.8 0 001.553.971 5.994 5.994 0 002.136.388 28.685 28.685 0 003.592.1 28.685 28.685 0 003.592-.1 5.994 5.994 0 002.136-.388 4.073 4.073 0 002.524-2.524 5.994 5.994 0 00.388-2.136c0-.971.1-1.262.1-3.592a28.685 28.685 0 00-.1-3.592 5.994 5.994 0 00-.39-2.136 3.8 3.8 0 00-.971-1.553 3.8 3.8 0 00-1.554-.972A5.994 5.994 0 0012.33.1 28.685 28.685 0 008.737 0m0 4.272a4.394 4.394 0 00-4.465 4.465 4.466 4.466 0 104.465-4.465m0 7.378a2.861 2.861 0 01-2.912-2.913 2.861 2.861 0 012.912-2.912 2.861 2.861 0 012.913 2.912 2.861 2.861 0 01-2.913 2.913M13.4 3.01a1.068 1.068 0 101.068 1.068A1.077 1.077 0 0013.4 3.01' fill-rule='evenodd'/%3E%3C/svg%3E");
}
footer.main-footer .footer-bottom .branding .socials .behance {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 3.902 3.904' fill='%23fff'%3E%3Cpath d='M1.135.725a1.19 1.19 0 01.312.035.686.686 0 01.243.095.4.4 0 01.156.182.69.69 0 01.052.277c0 .12-.026.225-.087.303a.646.646 0 01-.243.2.58.58 0 01.329.225c.07.104.113.234.113.38a.69.69 0 01-.069.312.58.58 0 01-.19.217.86.86 0 01-.277.12 1.34 1.34 0 01-.312.043H.002V.725zm-.07.97c.095 0 .173-.026.234-.07s.087-.12.087-.217a.33.33 0 00-.026-.139.246.246 0 00-.078-.087c-.035-.017-.07-.035-.113-.043s-.087-.01-.14-.01h-.5v.563zM1.09 2.72a.77.77 0 00.147-.017.31.31 0 00.207-.147.417.417 0 00.035-.156c0-.12-.035-.208-.104-.27a.459.459 0 00-.277-.078h-.57v.667zm1.673-.01c.07.07.173.104.312.104a.46.46 0 00.25-.07.34.34 0 00.13-.156h.425c-.07.208-.173.355-.312.45a.926.926 0 01-.503.14 1.02 1.02 0 01-.373-.069.69.69 0 01-.277-.191.67.67 0 01-.173-.286 1.02 1.02 0 01-.061-.373c0-.13.017-.25.06-.364a.92.92 0 01.182-.295.88.88 0 01.277-.191.98.98 0 01.364-.07c.147 0 .277.026.39.087s.2.13.27.234a1.05 1.05 0 01.156.329c.017.12.026.243.017.38H2.643a.5.5 0 00.12.338zm.546-.91a.378.378 0 00-.27-.095c-.078 0-.14.017-.19.043s-.087.06-.12.095a.24.24 0 00-.06.12c-.008.042-.017.078-.017.113h.78A.46.46 0 003.31 1.8zm-.76-.91h.97v.234h-.97z'/%3E%3C/svg%3E");
}
footer.main-footer .footer-bottom .branding .corporate {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
}
footer.main-footer .footer-bottom .links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-bottom .links {
    justify-content: flex-end;
  }
}
footer.main-footer .footer-bottom .links a {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  transition: 0.2s;
}
footer.main-footer .footer-bottom .links a:hover,
footer.main-footer .footer-bottom .links a:link,
footer.main-footer .footer-bottom .links a:visited {
  text-decoration: none;
  outline: none;
}
footer.main-footer .footer-bottom .links a:active,
footer.main-footer .footer-bottom .links a:focus {
  background: none;
  outline: none;
  text-decoration: none;
}
footer.main-footer .footer-bottom .links a:hover {
  opacity: 0.5;
}
footer.main-footer .footer-bottom .links a:nth-child(2) {
  margin-right: 50px;
}
footer.main-footer .footer-bottom .links .copyright {
  display: none;
}
@media screen and (min-width: 1200px) {
  footer.main-footer .footer-bottom .links .copyright {
    display: block;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    margin-right: auto;
  }
}
header.main-header {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 15px;
  transition: 0.3s;
  z-index: 200;
}
@media screen and (min-width: 768px) {
  header.main-header {
    padding: 15px 30px;
  }
}
header.main-header .menu-toggle {
  position: relative;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
header.main-header .menu-toggle .toggle-bar {
  position: absolute;
  top: 50%;
  right: 0;
  height: 2px;
  width: 30px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
}
header.main-header .menu-toggle .toggle-bar:first-child {
  transform: translateY(-10px);
}
header.main-header .menu-toggle .toggle-bar:last-child {
  transform: translateY(10px);
}
header.main-header .menu-toggle.close-position .toggle-bar:first-child {
  transform: translateY(0) rotate(45deg);
}
header.main-header .menu-toggle.close-position .toggle-bar:nth-child(2) {
  background-color: transparent;
}
header.main-header .menu-toggle.close-position .toggle-bar:nth-child(2):after {
  transform: translateX(-11px);
}
header.main-header .menu-toggle.close-position .toggle-bar:last-child {
  transform: translateY(0) rotate(-45deg);
}
header.main-header .menu-toggle.toggle-on .toggle-bar:first-child {
  -webkit-animation: menu-burger-first-child 0.5s infinite;
  animation: menu-burger-first-child 0.5s infinite;
}
header.main-header .menu-toggle.toggle-on .toggle-bar:nth-child(2) {
  -webkit-animation: menu-burger-second-child 0.5s infinite;
  animation: menu-burger-second-child 0.5s infinite;
}
header.main-header .menu-toggle.toggle-on .toggle-bar:nth-child(2):after {
  -webkit-animation: menu-burger-second-child-after 0.5s infinite;
  animation: menu-burger-second-child-after 0.5s infinite;
}
header.main-header .menu-toggle.toggle-on .toggle-bar:last-child {
  -webkit-animation: menu-burger-third-child 0.5s infinite;
  animation: menu-burger-third-child 0.5s infinite;
}
header.main-header .menu-toggle.toggle-off .toggle-bar:first-child {
  animation: menu-burger-first-child 0.5s infinite reverse;
}
header.main-header .menu-toggle.toggle-off .toggle-bar:nth-child(2) {
  animation: menu-burger-second-child 0.5s infinite reverse;
}
header.main-header .menu-toggle.toggle-off .toggle-bar:nth-child(2):after {
  animation: menu-burger-second-child-after 0.5s infinite reverse;
}
header.main-header .menu-toggle.toggle-off .toggle-bar:last-child {
  animation: menu-burger-third-child 0.5s infinite reverse;
}
header.main-header .company {
  display: none;
}
@media screen and (min-width: 1200px) {
  header.main-header .company {
    cursor: pointer;
    display: block;
    height: 40px;
    width: 130px;
    opacity: 1;
    transform: translateX(-150px);
    transition: 0.3s;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  header.main-header .company:hover {
    opacity: 0.5;
  }
}
header.main-header > .logo {
  display: block;
  height: 40px;
  width: 40px;
  transition: 0.3s;
  transform: translateX(12vw);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='85.365' height='85.367'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23fff}.b{fill:%2351b4b0}%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M38.364 85.141q-.611-.063-1.222-.144a3.073 3.073 0 11.8-6.093A36.533 36.533 0 106.204 44.816a3.073 3.073 0 11-6.136.356A42.683 42.683 0 1138.36 85.141z'/%3E%3Cpath class='b' d='M24.091 78.463a.477.477 0 01-.22-.054 40.373 40.373 0 01-18.1-19.307.473.473 0 01.864-.384 39.419 39.419 0 0017.677 18.853.473.473 0 01-.22.892z'/%3E%3Cpath class='a' d='M40.854 23.788l-8.312 15.836-.008.016-4.826-9.52-3.221-6.332h-10.39l18.259 36.965 5.44-10.8 13.175-26.162z'/%3E%3Cpath class='b' d='M52.862 24.229l-5.295 10.017 8.32 16.622H39.908l-4.633 9.907h36.212z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  header.main-header > .logo {
    transform: translateX(7vw);
    height: 60px;
    width: 60px;
  }
}
@media screen and (min-width: 1200px) {
  header.main-header > .logo {
    height: 90px;
    width: 90px;
    transform: translateX(0);
  }
}
header.main-header .icon-menu {
  display: flex;
}
@media screen and (min-width: 1200px) {
  header.main-header .icon-menu {
    margin-right: -100px;
  }
}
header.main-header .icon-menu .phone,
header.main-header .icon-menu .position {
  display: block;
  height: 30px;
  width: 30px;
  background-position: 50%;
  background-size: 25px;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
  header.main-header .icon-menu .phone,
  header.main-header .icon-menu .position {
    height: 50px;
    width: 40px;
    background-size: 35px;
  }
}
header.main-header .icon-menu .position {
  cursor: pointer;
  transition: 0.3s;
  border-radius: 30px;
}
header.main-header .icon-menu .position .text {
  opacity: 0;
}
header.main-header .icon-menu .position:focus,
header.main-header .icon-menu .position:hover {
  height: 30px;
  width: 90px;
  background-color: #fff;
  background-position: left 5px top 50%;
}
header.main-header .icon-menu .position:focus .text,
header.main-header .icon-menu .position:hover .text {
  opacity: 1;
  display: inline-block;
  margin-left: 35px;
  line-height: 30px;
}
@media screen and (min-width: 992px) {
  header.main-header .icon-menu .position:focus,
  header.main-header .icon-menu .position:hover {
    height: 50px;
    width: 120px;
  }
  header.main-header .icon-menu .position:focus .text,
  header.main-header .icon-menu .position:hover .text {
    font-size: 20px;
    font-weight: 700;
    vertical-align: middle;
    margin-left: 45px;
    line-height: 50px;
  }
}
header.main-header .icon-menu .phone {
  cursor: pointer;
  transition: 0.3s;
  border-radius: 30px;
}
header.main-header .icon-menu .phone .text {
  opacity: 0;
}
header.main-header .icon-menu .phone:focus,
header.main-header .icon-menu .phone:hover {
  height: 30px;
  width: 140px;
  background-color: #fff;
  background-position: left 5px top 50%;
}
header.main-header .icon-menu .phone:focus .text,
header.main-header .icon-menu .phone:hover .text {
  opacity: 1;
  display: inline-block;
  margin-left: 35px;
  line-height: 30px;
}
@media screen and (min-width: 992px) {
  header.main-header .icon-menu .phone:focus,
  header.main-header .icon-menu .phone:hover {
    height: 50px;
    width: 180px;
  }
  header.main-header .icon-menu .phone:focus .text,
  header.main-header .icon-menu .phone:hover .text {
    font-size: 20px;
    font-weight: 700;
    vertical-align: middle;
    margin-left: 45px;
    line-height: 50px;
  }
}
header.main-header .icon-menu .recruitment {
  cursor: pointer;
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  margin-left: 15px;
  border-radius: 50%;
  z-index: 2;
  transition: 0.3s;
  background-color: #f58d7b;
  background-position: 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
  header.main-header .icon-menu .recruitment {
    height: 60px;
    width: 60px;
    background-size: 35px;
  }
}
header.main-header .icon-menu .recruitment .notifications {
  position: absolute;
  top: 5px;
  right: 3px;
  height: 10px;
  width: 10px;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #fff;
  background-color: #009289;
  border-radius: 50%;
}
@media screen and (min-width: 1200px) {
  header.main-header .icon-menu .recruitment .notifications {
    top: 10px;
    right: 5px;
    font-size: 10px;
    height: 15px;
    width: 15px;
  }
}
header.main-header .icon-menu .recruitment .text {
  display: block;
  height: 60px;
  width: 0;
  overflow: hidden;
  border-radius: 0 30px 30px 0;
  background-color: #f58d7b;
  margin-left: 25px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  transition: 0.3s;
}
@media screen and (min-width: 1200px) {
  header.main-header .icon-menu .recruitment:hover {
    border-radius: 50% 0 0 50%;
  }
  header.main-header .icon-menu .recruitment:hover .text {
    padding-left: 10px;
    width: 150px;
    margin-left: 50px;
  }
}
header.main-header .language-switcher {
  display: none;
}
@media screen and (min-width: 1200px) {
  header.main-header .language-switcher {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  header.main-header .language-switcher .lang {
    cursor: pointer;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    margin-left: 10px;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    transition: 0.2s;
  }
  header.main-header .language-switcher .lang:hover {
    color: #fff;
  }
  header.main-header .language-switcher .lang.is-active {
    color: #fff;
    background-color: #009289;
  }
}
header.main-header.js-scrolled {
  background-color: rgba(21, 29, 65, 0.5);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
header.main-header.js-scrolled > .logo {
  height: 30px;
  width: 30px;
}
@media screen and (min-width: 768px) {
  header.main-header.js-scrolled > .logo {
    height: 45px;
    width: 45px;
  }
}
@media screen and (min-width: 1200px) {
  header.main-header.js-scrolled > .logo {
    height: 60px;
    width: 60px;
  }
}
header.main-header.green:not(.js-scrolled) .menu-toggle .toggle-bar {
  background-color: #009289;
}
header.main-header.green:not(.js-scrolled) > .logo {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91.921' height='91.924'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23151d41}.b{fill:%23009289}%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M41.31 91.68q-.658-.068-1.316-.155a3.309 3.309 0 11.865-6.561A39.339 39.339 0 106.683 48.258a3.309 3.309 0 11-6.607.383A45.962 45.962 0 1141.31 91.679z'/%3E%3Cpath class='b' d='M25.941 84.49a.511.511 0 01-.236-.058 43.472 43.472 0 01-19.493-20.79.51.51 0 01.931-.413 42.449 42.449 0 0019.035 20.3.509.509 0 01-.237.96z'/%3E%3Cpath class='a' d='M43.993 25.615l-8.95 17.052-.009.017-5.2-10.251-3.469-6.818H15.178l19.661 39.8 5.858-11.632 14.187-28.172z'/%3E%3Cpath class='b' d='M56.922 26.089l-5.7 10.787 8.959 17.9H42.975l-4.989 10.668h38.993z'/%3E%3C/svg%3E");
}
header.main-header.green .main-menu {
  width: 100vw;
  border-radius: 0;
  transform: translateX(-100vw);
}
header.main-header.green:not(.js-scrolled) .icon-menu .position {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.704' height='25.053' viewBox='0 0 24.704 32.053' fill='%23009289'%3E%3Cpath class='a' d='M12.352 0A12.366 12.366 0 000 12.353c0 4.867 2.038 9.79 5.893 14.238a31.23 31.23 0 005.853 5.282 1.108 1.108 0 001.212 0 31.233 31.233 0 005.852-5.282c3.855-4.447 5.893-9.371 5.893-14.238A12.367 12.367 0 0012.352 0zm0 29.587C10.08 27.894 2.21 21.379 2.21 12.353a10.138 10.138 0 1120.275 0c.005 9.026-7.861 15.541-10.133 17.234z'/%3E%3Cpath class='a' d='M12.352 7.448a4.9 4.9 0 104.9 4.9 4.908 4.908 0 00-4.9-4.9zm0 7.59a2.688 2.688 0 112.688-2.687 2.691 2.691 0 01-2.688 2.687z'/%3E%3C/svg%3E");
}
header.main-header.green:not(.js-scrolled) .icon-menu .phone {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30.372' height='25.243' viewBox='0 0 30.372 31.243' fill='%23009289'%3E%3Cpath d='M26.28 4.093A13.882 13.882 0 0016.398 0a1.107 1.107 0 100 2.215 11.76 11.76 0 0111.76 11.76 1.107 1.107 0 002.215 0 13.882 13.882 0 00-4.093-9.882z'/%3E%3Cpath d='M21.725 13.975a1.107 1.107 0 002.215 0 7.551 7.551 0 00-7.541-7.541 1.107 1.107 0 000 2.215 5.334 5.334 0 015.326 5.326zM19.274 19.62a3.343 3.343 0 00-2.96 1.771 1.107 1.107 0 001.828 1.25c.487-.712.708-.824 1.007-.81.958.113 4.733 2.878 5.111 3.743a1.146 1.146 0 01-.01.808 3.821 3.821 0 01-1.9 2.39 3.553 3.553 0 01-2.846-.1 31.294 31.294 0 01-10.263-6.679 31.289 31.289 0 01-6.674-10.256 3.554 3.554 0 01-.1-2.847 3.82 3.82 0 012.389-1.9 1.144 1.144 0 01.806-.012c.868.379 3.634 4.153 3.745 5.1.016.311-.1.531-.809 1.018a1.11 1.11 0 101.253 1.833 3.34 3.34 0 001.771-2.965c-.1-1.769-3.537-6.454-5.181-7.058a3.348 3.348 0 00-2.29-.012 5.96 5.96 0 00-3.7 3.073 5.7 5.7 0 00.058 4.6 33.492 33.492 0 007.159 10.986l.023.022a33.5 33.5 0 0010.98 7.152 6.569 6.569 0 002.472.516 5.075 5.075 0 002.132-.457 5.959 5.959 0 003.074-3.7 3.349 3.349 0 00-.011-2.282c-.607-1.646-5.287-5.088-7.064-5.184z'/%3E%3C/svg%3E");
}
header.main-header.green:not(.js-scrolled) .language-switcher .lang {
  color: #009289;
}
header.main-header.green:not(.js-scrolled) .language-switcher .lang.is-active {
  background-color: rgba(0, 146, 137, 0.1);
}
@media screen and (min-width: 1200px) {
  header.main-header.green:not(.js-scrolled) .company {
    background-image: url(../../assets/img/logov2.png);
  }
}
header.main-header .main-menu {
  position: relative;
  display: flex;
  padding: 5vh;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  opacity: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 12;
  transform: translateX(-100vh);
  transition: 0.3s;
}
@media screen and (min-width: 1200px) {
  header.main-header .main-menu {
    width: 100vh;
    border-radius: 0 50% 50% 0;
    padding: 5vh 0 0 5vh;
  }
}
header.main-header .main-menu .menu-toggle {
  padding-left: 40px;
  line-height: 30px;
  vertical-align: middle;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
}
header.main-header .main-menu .menu-toggle:after,
header.main-header .main-menu .menu-toggle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  width: 30px;
  border-radius: 3px;
  background-color: #151d41;
  z-index: 10;
}
header.main-header .main-menu .menu-toggle:before {
  transform: rotate(45deg);
}
header.main-header .main-menu .menu-toggle:after {
  transform: rotate(-45deg);
}
header.main-header .main-menu .menu-links {
  text-align: center;
}
@media screen and (min-width: 1200px) {
  header.main-header .main-menu .menu-links {
    text-align: left;
    padding-left: 15vh;
  }
}
header.main-header .main-menu .menu-links a {
  line-height: 1;
  display: block;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  transition: 0.2s;
}
header.main-header .main-menu .menu-links a:hover,
header.main-header .main-menu .menu-links a:link,
header.main-header .main-menu .menu-links a:visited {
  text-decoration: none;
  outline: none;
}
header.main-header .main-menu .menu-links a:active,
header.main-header .main-menu .menu-links a:focus {
  background: none;
  outline: none;
  text-decoration: none;
}
header.main-header .main-menu .menu-links a:hover {
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  header.main-header .main-menu .menu-links a {
    font-size: 40px;
    font-weight: 700;
  }
}
header.main-header .main-menu .menu-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 5vh;
  padding-bottom: 5vh;
}
@media screen and (min-width: 768px) {
  header.main-header .main-menu .menu-footer .logo {
    display: block;
    height: 90px;
    width: 90px;
    transition: 0.3s;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91.921' height='91.924'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23151d41}.b{fill:%23009289}%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M41.31 91.68q-.658-.068-1.316-.155a3.309 3.309 0 11.865-6.561A39.339 39.339 0 106.683 48.258a3.309 3.309 0 11-6.607.383A45.962 45.962 0 1141.31 91.679z'/%3E%3Cpath class='b' d='M25.941 84.49a.511.511 0 01-.236-.058 43.472 43.472 0 01-19.493-20.79.51.51 0 01.931-.413 42.449 42.449 0 0019.035 20.3.509.509 0 01-.237.96z'/%3E%3Cpath class='a' d='M43.993 25.615l-8.95 17.052-.009.017-5.2-10.251-3.469-6.818H15.178l19.661 39.8 5.858-11.632 14.187-28.172z'/%3E%3Cpath class='b' d='M56.922 26.089l-5.7 10.787 8.959 17.9H42.975l-4.989 10.668h38.993z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
header.main-header .main-menu .menu-footer .socials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 20px;
}
header.main-header .main-menu .menu-footer .socials:after {
  content: "";
  height: 1px;
  width: 150px;
  background-color: #151d41;
}
header.main-header .main-menu .menu-footer .socials .behance,
header.main-header .main-menu .menu-footer .socials .facebook,
header.main-header .main-menu .menu-footer .socials .instagram,
header.main-header .main-menu .menu-footer .socials .twitter {
  position: relative;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s;
}
header.main-header .main-menu .menu-footer .socials .behance:hover,
header.main-header .main-menu .menu-footer .socials .facebook:hover,
header.main-header .main-menu .menu-footer .socials .instagram:hover,
header.main-header .main-menu .menu-footer .socials .twitter:hover {
  opacity: 0.5;
}
header.main-header .main-menu .menu-footer .socials .behance a,
header.main-header .main-menu .menu-footer .socials .facebook a,
header.main-header .main-menu .menu-footer .socials .instagram a,
header.main-header .main-menu .menu-footer .socials .twitter a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
header.main-header .main-menu .menu-footer .socials .facebook {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.126' height='17.475' fill='%23151D41'%3E%3Cpath d='M5.922 17.475V9.514H8.64l.388-3.107H5.922V4.466c0-.874.291-1.553 1.553-1.553h1.65V.1C8.737.1 7.767 0 6.7 0a3.748 3.748 0 00-3.98 4.077v2.33H0v3.107h2.718v7.961z' fill-rule='evenodd'/%3E%3C/svg%3E");
}
header.main-header .main-menu .menu-footer .socials .twitter {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.475' height='14.174' fill='%23151D41'%3E%3Cpath d='M5.534 14.174A10.1 10.1 0 0015.727 3.98v-.485a7.891 7.891 0 001.748-1.845 8.059 8.059 0 01-2.039.582A3.777 3.777 0 0016.99.291a8.9 8.9 0 01-2.233.874A3.467 3.467 0 0012.135 0a3.648 3.648 0 00-3.592 3.592 1.893 1.893 0 00.1.777A10.037 10.037 0 011.265.583 3.718 3.718 0 00.78 2.428a3.857 3.857 0 001.553 3.01 3.273 3.273 0 01-1.65-.485 3.548 3.548 0 002.912 3.495 2.993 2.993 0 01-.971.1 1.652 1.652 0 01-.68-.1 3.678 3.678 0 003.4 2.524 7.33 7.33 0 01-4.466 1.553 2.688 2.688 0 01-.874-.1 9.164 9.164 0 005.534 1.747' fill-rule='evenodd'/%3E%3C/svg%3E");
}
header.main-header .main-menu .menu-footer .socials .instagram {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.475' height='17.475' fill='%23151D41'%3E%3Cpath d='M8.737 1.553a26.791 26.791 0 013.495.1 4.5 4.5 0 011.65.291 3.41 3.41 0 011.65 1.65 4.5 4.5 0 01.291 1.65c0 .874.1 1.165.1 3.495a26.791 26.791 0 01-.1 3.495 4.5 4.5 0 01-.291 1.65 3.41 3.41 0 01-1.65 1.65 4.5 4.5 0 01-1.65.291c-.874 0-1.165.1-3.495.1a26.791 26.791 0 01-3.495-.1 4.5 4.5 0 01-1.65-.291 3.41 3.41 0 01-1.65-1.65 4.5 4.5 0 01-.291-1.65c0-.874-.1-1.165-.1-3.495a26.791 26.791 0 01.1-3.495 4.5 4.5 0 01.291-1.65 3.487 3.487 0 01.68-.971 1.641 1.641 0 01.971-.68 4.5 4.5 0 011.65-.291 26.791 26.791 0 013.495-.1M8.737 0a28.685 28.685 0 00-3.592.1A5.994 5.994 0 003.01.485a3.8 3.8 0 00-1.553.971A3.8 3.8 0 00.485 3.01 4.423 4.423 0 00.1 5.145 28.685 28.685 0 000 8.737a28.685 28.685 0 00.1 3.593 5.994 5.994 0 00.388 2.136 3.8 3.8 0 00.971 1.553 3.8 3.8 0 001.553.971 5.994 5.994 0 002.136.388 28.685 28.685 0 003.592.1 28.685 28.685 0 003.592-.1 5.994 5.994 0 002.136-.388 4.073 4.073 0 002.524-2.524 5.994 5.994 0 00.388-2.136c0-.971.1-1.262.1-3.592a28.685 28.685 0 00-.1-3.592 5.994 5.994 0 00-.39-2.136 3.8 3.8 0 00-.971-1.553 3.8 3.8 0 00-1.554-.972A5.994 5.994 0 0012.33.1 28.685 28.685 0 008.737 0m0 4.272a4.394 4.394 0 00-4.465 4.465 4.466 4.466 0 104.465-4.465m0 7.378a2.861 2.861 0 01-2.912-2.913 2.861 2.861 0 012.912-2.912 2.861 2.861 0 012.913 2.912 2.861 2.861 0 01-2.913 2.913M13.4 3.01a1.068 1.068 0 101.068 1.068A1.077 1.077 0 0013.4 3.01' fill-rule='evenodd'/%3E%3C/svg%3E");
}
header.main-header .main-menu .menu-footer .socials .behance {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 3.902 3.904' fill='%23151D41'%3E%3Cpath d='M1.135.725a1.19 1.19 0 01.312.035.686.686 0 01.243.095.4.4 0 01.156.182.69.69 0 01.052.277c0 .12-.026.225-.087.303a.646.646 0 01-.243.2.58.58 0 01.329.225c.07.104.113.234.113.38a.69.69 0 01-.069.312.58.58 0 01-.19.217.86.86 0 01-.277.12 1.34 1.34 0 01-.312.043H.002V.725zm-.07.97c.095 0 .173-.026.234-.07s.087-.12.087-.217a.33.33 0 00-.026-.139.246.246 0 00-.078-.087c-.035-.017-.07-.035-.113-.043s-.087-.01-.14-.01h-.5v.563zM1.09 2.72a.77.77 0 00.147-.017.31.31 0 00.207-.147.417.417 0 00.035-.156c0-.12-.035-.208-.104-.27a.459.459 0 00-.277-.078h-.57v.667zm1.673-.01c.07.07.173.104.312.104a.46.46 0 00.25-.07.34.34 0 00.13-.156h.425c-.07.208-.173.355-.312.45a.926.926 0 01-.503.14 1.02 1.02 0 01-.373-.069.69.69 0 01-.277-.191.67.67 0 01-.173-.286 1.02 1.02 0 01-.061-.373c0-.13.017-.25.06-.364a.92.92 0 01.182-.295.88.88 0 01.277-.191.98.98 0 01.364-.07c.147 0 .277.026.39.087s.2.13.27.234a1.05 1.05 0 01.156.329c.017.12.026.243.017.38H2.643a.5.5 0 00.12.338zm.546-.91a.378.378 0 00-.27-.095c-.078 0-.14.017-.19.043s-.087.06-.12.095a.24.24 0 00-.06.12c-.008.042-.017.078-.017.113h.78A.46.46 0 003.31 1.8zm-.76-.91h.97v.234h-.97z'/%3E%3C/svg%3E");
}
header.main-header .main-menu.js-open {
  opacity: 1;
  transform: translateX(0);
}

.form-inline {
  display: flex;
}

.first-content {
  padding-top: 120px;
}

.text-left {
  text-align: left;
}

.main-header {
  background-color: rgba(21, 29, 65, 0.5);
}

.app-layout .content {
  padding: 0px 0px 0px 0px;
}