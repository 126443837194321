/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/

:root {
  --insur-font: 'DM Sans', sans-serif;
  --insur-reey-font: "reeyregular";
  --insur-gray: #696e77; 
  --insur-gray-rgb: 105, 110, 119;
  --insur-white: #ffffff;
  --insur-white-rgb: 255, 255, 255;
  --insur-base: #009289;
  --insur-base-rgb: 1, 95, 201;
  --insur-black: #16243d;
  --insur-primary-rgb: 22, 36, 61;
  --insur-primary: #0ce0ff;
  --insur-primary-rgb: 0, 146, 137;
  --insur-extra: #f2f5f9;
  --insur-extra-rgb: 242, 245, 249;
  --insur-bdr-color: #e0e4e8;
  --insur-bdr-color-rgb: 224, 228, 232;
  --insur-bdr-radius: 10px;
  --insur-letter-spacing: -0.02em;

}


body.locked {
  overflow: hidden;
}

a {
  color: var(--insur-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--insur-black);
  margin: 0;
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--insur-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--insur-base, #a47c68);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--insur-base);
  opacity: .3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width .3s, height .3s, opacity .3s;
  transition: width .3s, height .3s, opacity .3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--insur-base);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: .4;
}


.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none !important;
  background-color: var(--insur-base);
  color: var(--insur-white);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  border-radius: var(--insur-bdr-radius);
  padding: 17px 40px 17px;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;

}

.thm-btn:hover {
  color: var(--insur-white);
}

.thm-btn:before {
  position: absolute;
  content: '';
  background-color: var(--insur-black);
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
  z-index: -1;
}

.thm-btn:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.thm-btn:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: var(--insur-black);
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  z-index: -1;
}

.thm-btn:hover::after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}

.section-title {
  position: relative;
  display: block;
  margin-top: -7px;
  margin-bottom: 48px;
}

.section-sub-title-box {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}

.section-sub-title {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 3px;
}

.section-title-shape-1 {
  position: absolute;
  top: -1px;
  left: -40px;
}

.section-title-shape-1 img {
  width: auto;
}

.section-title-shape-2 {
  position: absolute;
  top: -1px;
  right: -39px;
}

.section-title-shape-2 img {
  width: auto;
}

.section-title__title {
  margin: 0;
  color: var(--insur-black);
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 10px;
}

.bootstrap-select .dropdown-menu>li+li>a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--insur-primary);
  color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  color: #ffffff;
  background: var(--insur-base);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--insur-white);
  color: var(--insur-base);
  cursor: pointer;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-image: url(/assets/img/favicon.svg);
  // background-image: url(/assets/images/loader.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: var(--insur-base);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: var(--insur-base);
}

.scroll-to-top:hover i {
  color: #fff;
}


/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  position: relative;
  display: block;
  width: 100%;
  background: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 99;
  position: relative;
}

.main-header__top {
  position: relative;
  display: block;
  background-color: var(--insur-black);
  padding: 7px 0;
}

.main-header__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header__top-address {
  position: relative;
  display: block;
}

.main-header__top-address-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-address-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-address-list li+li {
  margin-left: 30px;
}

.main-header__top-address-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-address-list li .icon span {
  font-size: 13px;
  color: var(--insur-base);
}

.main-header__top-address-list li .text {
  margin-left: 10px;
}

.main-header__top-address-list li .text p {
  font-size: 13px;
  color: #97a2b7;
  line-height: 15px;
  letter-spacing: var(--insur-letter-spacing);
}

.main-header__top-address-list li .text p a {
  color: #97a2b7;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-address-list li .text p a:hover {
  color: var(--insur-base);
}

.main-header__top-right {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-menu-box {
  position: relative;
  display: block;
}

.main-header__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-menu li+li {
  margin-left: 20px;
}

.main-header__top-menu li a {
  font-size: 13px;
  color: #97a2b7;
  display: flex;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-menu li a:hover {
  color: var(--insur-base);
}

.main-header__top-social-box {
  margin-left: 40px;
}

.main-header__top-social {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header__top-social a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--insur-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header__top-social a:hover {
  color: var(--insur-base);
}

.main-header__top-social a+a {
  margin-left: 25px;
}

.main-menu {
  position: relative;
  display: block;
}

.main-menu__wrapper {
  position: relative;
  display: block;
}

.main-menu__wrapper-inner {
  display: block;
  padding: 30px 0;
}

.main-menu__left {
  display: block;
  float: left;
}

.main-menu__logo {
  float: left;
  display: block;
  padding: 11.2px 0px;
  margin-right: 45px;
}

.main-menu__main-menu-box {
  display: flex;
  float: left;
  align-items: center;
  background-color: var(--insur-extra);
  padding-left: 30px;
  border-radius: var(--insur-bdr-radius);
}

.main-menu__main-menu-box-inner {
  display: block;
}

.main-menu__main-menu-box-search-get-quote-btn {
  display: flex;
  align-items: center;
  padding-left: 70px;
}

.main-menu__main-menu-box-search {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.main-menu__main-menu-box-search:before {
  position: absolute;
  top: -5px;
  bottom: -5px;
  content: "";
  left: -20px;
  width: 1px;
  background-color: var(--insur-bdr-color);
}

.main-menu__search {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__search:hover {
  color: var(--insur-base);
}

.main-menu__main-menu-box-get-quote-btn {
  font-size: 14px;
  padding: 12px 30px 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-menu__right {
  position: relative;
  display: block;
  float: right;
}

.main-menu__call {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__call-icon {
  position: relative;
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-bdr-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--insur-primary);
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__call-icon:hover {
  background-color: var(--insur-base);
  border: 2px solid var(--insur-base);
  color: var(--insur-white);
}

.main-menu__call-content {
  position: relative;
  margin-left: 10px;
  top: -2px;
}

.main-menu__call-content a {
  font-size: 16px;
  font-weight: 700;
  color: var(--insur-black);
  display: inline-block;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__call-content a:hover {
  color: var(--insur-base);
}

.main-menu__call-content p {
  font-size: 14px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 16px;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}



@media (min-width: 1200px) {

  .main-menu .main-menu__list,
  .main-menu .main-menu__list>li>ul,
  .main-menu .main-menu__list>li>ul>li>ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list>li>ul,
  .stricky-header .main-menu__list>li>ul>li>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 40px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--insur-gray);
  letter-spacing: var(--insur-letter-spacing);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 500;
}

.main-menu .main-menu__list>li>a::before,
.stricky-header .main-menu__list>li>a::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 4px solid var(--insur-base);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .main-menu__list>li.current>a::before,
.main-menu .main-menu__list>li:hover>a::before,
.stricky-header .main-menu__list>li.current>a::before,
.stricky-header .main-menu__list>li:hover>a::before {
  opacity: 1;
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
  color: var(--insur-black);
}

.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  position: absolute;
  top: 160%;
  left: 0;
  min-width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 99;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  border-radius: var(--insur-bdr-radius);
  padding: 31px 20px 31px;
}

.main-menu .main-menu__list>li>ul>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu .main-menu__list>li:hover>ul,
.main-menu .main-menu__list>li>ul>li:hover>ul,
.stricky-header .main-menu__list>li:hover>ul,
.stricky-header .main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list>li>ul>li,
.main-menu .main-menu__list>li>ul>li>ul>li,
.stricky-header .main-menu__list>li>ul>li,
.stricky-header .main-menu__list>li>ul>li>ul>li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list>li>ul>li+li,
.main-menu .main-menu__list>li>ul>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li>ul>li+li {
  border-top: none;
  margin-top: 10px;
}

.main-menu .main-menu__list>li>ul>li>a,
.main-menu .main-menu__list>li>ul>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--insur-gray);
  letter-spacing: 0;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 20px 3px;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: 500ms;
  transition: 500ms;
  background-color: var(--insur-white);
  border-radius: var(--insur-bdr-radius);
}

.main-menu .main-menu__list>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a {
  background-color: var(--insur-extra);
  color: var(--insur-black);
}

.main-menu .main-menu__list>li>ul>li>a::before,
.main-menu .main-menu__list>li>ul>li>ul>li>a::before,
.stricky-header .main-menu__list>li>ul>li>a::before,
.stricky-header .main-menu__list>li>ul>li>ul>li>a::before {
  position: absolute;
  top: 50%;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f101";
  font-size: 14px;
  color: var(--insur-base);
  transform: translateY(-50%) scale(0);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .main-menu__list>li>ul>li:hover>a::before,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a::before,
.stricky-header .main-menu__list>li>ul>li:hover>a::before,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a::before {
  transform: translateY(-50%) scale(1);
}

.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu,
.main-menu-two__main-menu-box .main-menu__list>.megamenu,
.main-menu__wrapper .main-menu__list>.megamenu {
  position: static;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu>ul,
.main-menu-two__main-menu-box .main-menu__list>.megamenu>ul,
.main-menu__wrapper .main-menu__list>.megamenu>ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu>ul>li,
.main-menu-two__main-menu-box .main-menu__list>.megamenu>ul>li,
.main-menu__wrapper .main-menu__list>.megamenu>ul>li {
  padding: 0 !important;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--insur-base);
  cursor: pointer;
}

.mobile-nav__buttons a+a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--insur-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--insur-white);
  cursor: pointer;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--insur-primary);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--insur-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--insur-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li>ul>li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list>li>a>.main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--insur-font, "Rubik", sans-serif);
  font-weight: 500;
  height: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>ul>li>a.expanded {
  color: var(--insur-base);
}

.mobile-nav__content .main-menu__list>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button {
  width: 30px;
  height: 30px;
  background-color: var(--insur-primary);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--insur-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--insur-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--insur-white);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__social a+a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--insur-primary);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--insur-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__contact li+li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--insur-primary);
}

.mobile-nav__contact li>i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--insur-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}



/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.home-showcase {
  margin-top: -31px;
  margin-bottom: -31px;
}

.home-showcase__inner {
  background-color: #fff;
  padding: 40px 40px 34px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  border-radius: var(--insur-bdr-radius);
}

.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}

.home-showcase__image {
  position: relative;
  background-color: var(--insur-base);
  overflow: hidden;
  border-radius: 10px;
}

.home-showcase__image>img {
  width: 100%;
  border-radius: 10px;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-showcase__image:hover>img {
  opacity: 0.75;
}

.home-showcase__image:hover .home-showcase__buttons {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.home-showcase__buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.home-showcase__buttons__item {
  padding: 10px 20px;
  width: 150px;
  text-align: center;
}

.home-showcase__buttons__item+.home-showcase__buttons__item {
  margin-top: 10px;
}

.home-showcase__title {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: var(--insur-black);
  margin-top: 16px;
}

.mobile-nav__wrapper .home-showcase .row [class*=col-] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0);
}

.mobile-nav__wrapper .home-showcase__title {
  color: var(--insur-white, #ffffff);
}



/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  width: 100%;
  background: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 99;
  position: relative;
}

.main-header-two__top {
  position: relative;
  display: block;
  background-color: transparent;
}

.main-header-two__top-social-box {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 7px 0;
  z-index: 1;
}

.main-header-two__top-social-box-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header-two__top-social-text {
  font-size: 13px;
  letter-spacing: var(--insur-letter-spacing);
}

.main-header-two__top-social-text i {
  color: var(--insur-base);
}

.main-header-two__top-social-text span {
  color: var(--insur-black);
  font-weight: 700;
}

.main-header-two__top-menu-social-box {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-menu-box {
  position: relative;
  display: block;
}

.main-header-two__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-menu li+li {
  margin-left: 20px;
}

.main-header-two__top-menu li a {
  position: relative;
  display: flex;
  font-size: 13px;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-two__top-menu li a:hover {
  color: var(--insur-base);
}

.main-header-two__top-social {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.main-header-two__top-social a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-two__top-social a:hover {
  color: var(--insur-base);
}

.main-header-two__top-social a+a {
  margin-left: 25px;
}

.main-header-two__top-details {
  position: relative;
  display: block;
  padding: 29px 0 64px;
  background-color: rgb(22, 36, 61);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

.main-header-two__top-details-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header-two__top-details-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main-header-two__top-details-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-details-list li+li {
  margin-left: 40px;
}

.main-header-two__top-details-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-details-list li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 39px;
  background: rgb(1, 200, 248);
  background: linear-gradient(90deg, rgba(1, 200, 248, 0.8407738095238095) 35%, rgba(1, 102, 195, 0.8099614845938375) 71%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-header-two__top-details-list li .text {
  margin-left: 20px;
}

.main-header-two__top-details-list li .text h5 {
  font-size: 16px;
  font-weight: 700;
  color: var(--insur-white);
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

.main-header-two__top-details-list li .text p {
  font-size: 16px;
  color: #97a2b7;
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

.main-header-two__top-details-list li .text p a {
  color: #97a2b7;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-header-two__top-details-list li .text p a:hover {
  color: var(--insur-primary);
}

.main-menu-two {
  margin-top: -35px;
}

.main-menu-two__wrapper {
  position: relative;
  display: block;
}

.main-menu-two__wrapper-inner {
  display: block;
  background-color: var(--insur-white);
  padding-left: 40px;
  border-radius: var(--insur-bdr-radius);
  box-shadow: 0px 10px 60px 0px rgba(105, 72, 72, 0.05);
}

.main-menu-two__left {
  display: block;
  float: left;
}

.main-menu-two__main-menu-box {
  display: block;
}

.main-menu-two__right {
  position: relative;
  display: block;
  float: right;
}

.main-menu-two__search-box-get-quote {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-two__search-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 30px;
  padding: 25px 0;
}

.main-menu-two__search-box:before {
  position: absolute;
  top: 20px;
  left: -20px;
  bottom: 20px;
  content: "";
  width: 1px;
  background-color: var(--insur-bdr-color);
}

.main-menu-two__search {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__search:hover {
  color: var(--insur-base);
}

.main-menu-two__get-quote-btn {
  padding-top: 22px;
  padding-bottom: 22px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-menu-two .main-menu__list>li,
.stricky-header.main-menu-two .main-menu__list>li {
  padding-top: 22px;
  padding-bottom: 22px;
}

.stricky-header.main-menu-two {
  margin-top: 0;
}

.stricky-header .main-menu-two__wrapper-inner {
  display: block;
  background-color: var(--insur-white);
  padding-left: 0px;
  border-radius: 0;
  box-shadow: none;
}

.stricky-header .main-menu-two__get-quote-btn {
  border-radius: 0;
}

.main-menu-two .main-menu__list>li>ul,
.main-menu-two .main-menu__list>li>ul>li>ul,
.stricky-header.main-menu-two .main-menu__list>li>ul,
.stricky-header.main-menu-two .main-menu__list>li>ul>li>ul {
  top: 100%;
}


.main-menu-two .main-menu__list>li>a::before,
.stricky-header.main-menu-two .main-menu__list>li>a::before {
  display: none;
}

/*--------------------------------------------------------------
# Navigations Three
--------------------------------------------------------------*/
.main-header-three {
  position: relative;
  display: block;
}

.main-menu-three__wrapper {
  position: relative;
  display: block;
}

.main-menu-three__wrapper-inner {
  display: block;
}

.main-menu-three__left {
  display: block;
  float: left;
}

.main-menu-three__logo {
  display: block;
  float: left;
  padding: 41px 0;
  padding-right: 170px;
}

.main-menu-three__main-menu-box {
  display: block;
  float: left;
}

.main-menu-three__right {
  display: block;
  float: right;
}

.main-menu-three__search-get-quote-btn {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.main-menu-three__search-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 15px 0;
}

.main-menu-three__search-box:before {
  position: absolute;
  top: 10px;
  left: -20px;
  bottom: 10px;
  content: "";
  width: 1px;
  background-color: var(--insur-bdr-color);
}

.main-menu-three__search {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__search:hover {
  color: var(--insur-base);
}

.main-menu-three__get-quote-btn {
  font-size: 14px;
  padding: 12px 30px 12px;
}

.main-menu-three .main-menu__list>li,
.stricky-header.main-menu-three .main-menu__list>li {
  padding-top: 42px;
  padding-bottom: 42px;
}

.main-menu-three .main-menu__list>li+li,
.stricky-header.main-menu-three .main-menu__list>li+li {
  margin-left: 50px;
}

.main-menu-three .main-menu__list>li>ul,
.main-menu-three .main-menu__list>li>ul>li>ul,
.stricky-header.main-menu-three .main-menu__list>li>ul,
.stricky-header.main-menu-three .main-menu__list>li>ul>li>ul {
  top: 100%;
}

.main-menu-three .main-menu__list>li>a::before,
.stricky-header.main-menu-three .main-menu__list>li>a::before {
  display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--insur-base);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--insur-base);
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.search-popup__content .thm-btn:hover {
  background-color: var(--insur-black);
}

.search-popup__content .thm-btn:before,
.search-popup__content .thm-btn:after {
  display: none;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  position: relative;
  display: block;
  z-index: 91;
}

.main-slider .swiper-slide {
  position: relative;
  background-color: var(--insur-extra);
}

.main-slider .container {
  position: relative;
  padding-top: 134px;
  padding-bottom: 115px;
  z-index: 30;
}

.main-slider .image-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 63%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: transform 7000ms ease, opacity 1500ms ease-in;
  transition: transform 7000ms ease, opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  z-index: 1;
}

.main-slider .swiper-slide-active .image-layer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.main-slider .image-layer:before {
  position: absolute;
  left: -1px;
  top: 0px;
  bottom: 0;
  width: 535px;
  content: "";
  background-image: -moz-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
  z-index: -1;
}

.main-slider-shape-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  mix-blend-mode: luminosity;
  opacity: .20;
  z-index: 0;
}

.main-slider-shape-1 img {
  width: auto;
}

.main-slider__content {
  position: relative;
  display: block;
  text-align: left;
}

.main-slider__title {
  position: relative;
  font-size: 80px;
  color: var(--insur-black);
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.04em;
  margin-bottom: 29px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__title span {
  color: var(--insur-base);
  font-weight: 700;
}

.main-slider__title:before {
  position: absolute;
  top: -22px;
  left: 5px;
  height: 13px;
  width: 324px;
  content: "";
  background-image: -moz-linear-gradient(100deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(100deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(100deg, #151d41 0%, #009289 100%);
  background-image: linear-gradient(100deg, #151d41 0%, #009289 100%);
  border-radius: 6.5px;
}

.main-slider__text {
  font-size: 18px;
  color: var(--insur-gray);
  font-weight: 400;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 30px;
  padding-bottom: 41px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}


.main-slider .swiper-slide-active .main-slider__title,
.main-slider .swiper-slide-active .main-slider__text,
.main-slider .swiper-slide-active .main-slider__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider__nav {
  position: absolute;
  top: 50%;
  left: 120px;
  z-index: 100;
  transform: translateY(-50%) translateX(0);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}


.main-slider__nav .swiper-button-next,
.main-slider__nav .swiper-button-prev,
.main-slider__nav .swiper-button-next i,
.main-slider__nav .swiper-button-prev i {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgba(var(--insur-primary-rgb), .70);
  border: 2px solid rgba(var(--insur-primary-rgb), .70);
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
}

// .main-slider__nav .swiper-button-next i,
// .main-slider__nav .swiper-button-prev i {
//   color: rgba(var(--insur-primary-rgb), 1);
// }

.main-slider__nav .swiper-button-next:hover,
.main-slider__nav .swiper-button-prev:hover,
.main-slider__nav .swiper-button-next i:hover,
.main-slider__nav .swiper-button-prev i:hover {
  color: rgba(var(--insur-primary-rgb), 1);
  border: 2px solid rgba(var(--insur-primary-rgb), 1);
}

.main-slider__nav .swiper-button-next::after,
.main-slider__nav .swiper-button-prev::after {
  display: none;
}

.main-slider__nav .swiper-button-prev {
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Main Slider Two
--------------------------------------------------------------*/
.main-slider-two {
  position: relative;
  display: block;
  top: -35px;
  margin-bottom: -35px;
}

.main-slider-two .swiper-slide {
  position: relative;
}

.main-slider-two .container {
  position: relative;
  padding-top: 188px;
  padding-bottom: 160px;
  z-index: 30;
}

.image-layer-two {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: transform 7000ms ease, opacity 1500ms ease-in;
  transition: transform 7000ms ease, opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  z-index: 1;
}

.main-slider-two .swiper-slide-active .image-layer-two {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.image-layer-two:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 940px;
  width: 100%;
  content: "";
  background-color: rgba(var(--insur-primary-rgb), .85);
  clip-path: polygon(0 0, 87% 0, 100% 100%, 0% 100%);
  z-index: -1;
}

.image-layer-two:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 48%;
  width: 40px;
  content: "";
  background-color: rgba(var(--insur-primary-rgb), .85);
  z-index: -1;
  transform: translateX(-50%) rotate(-10deg);
}

.main-slider-two__inner {
  position: relative;
  display: block;
}

.main-slider-two__content {
  position: relative;
  display: block;
}

.main-slider-two__content:before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 188px;
  width: 5px;
  background-color: rgba(var(--insur-white-rgb), .10);
  content: "";
}

.main-slider-two__title {
  position: relative;
  font-size: 60px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.04em;
  margin-bottom: 21px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__text {
  font-size: 18px;
  color: var(--insur-white);
  font-weight: 400;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 30px;
  margin-left: 30px;
  padding-bottom: 46px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two .swiper-slide-active .main-slider-two__text {
  visibility: visible;
  opacity: 0.70;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-two__btn-box {
  position: relative;
  display: block;
  margin-left: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two .swiper-slide-active .main-slider-two__title,
.main-slider-two .swiper-slide-active .main-slider-two__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-two__right {
  position: relative;
  display: block;
}

.main-slider-two__video-link {
  position: absolute;
  left: 30px;
  top: 290px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-slider-two__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  font-size: 18px;
  color: var(--insur-black);
  background-color: var(--insur-white);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.main-slider-two__video-icon:hover {
  background-color: var(--insur-primary);
  color: var(--insur-black);
}

.main-slider-two__video-icon:before {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  content: "";
  border-radius: 50%;
  border: 1px solid var(--insur-white);
  opacity: 0.1;
  z-index: 1;
}

.main-slider-two__video-link .ripple,
.main-slider-two__video-icon .ripple:before,
.main-slider-two__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125px;
  height: 125px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.main-slider-two__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.main-slider-two__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.main-slider-two__video-text {
  font-size: 16px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 19px;
  letter-spacing: var(--insur-letter-spacing);
  margin-left: 35px;
}

.main-slider-two .main-slider__nav {
  top: 52%;
}

.main-slider-two .main-slider__nav .swiper-button-next,
.main-slider-two .main-slider__nav .swiper-button-prev {
  color: rgba(var(--insur-white-rgb), .20);
  border: 2px solid rgba(var(--insur-white-rgb), .20);
}

.main-slider-two .main-slider__nav .swiper-button-next:hover,
.main-slider-two .main-slider__nav .swiper-button-prev:hover {
  color: rgba(var(--insur-white-rgb), 1);
  border: 2px solid rgba(var(--insur-white-rgb), 1);
}

/*--------------------------------------------------------------
# Main Slider Three
--------------------------------------------------------------*/
.main-slider-three {
  position: relative;
  display: block;
}

.main-slider-three .swiper-slide {
  position: relative;
}

.main-slider-three .container {
  position: relative;
  padding-top: 177px;
  padding-bottom: 185px;
  z-index: 30;
}

.image-layer-three {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  transition: transform 7000ms ease, opacity 1500ms ease-in;
  transition: transform 7000ms ease, opacity 1500ms ease-in, -webkit-transform 7000ms ease;
  z-index: 1;
}

.main-slider-three .swiper-slide-active .image-layer-three {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.image-layer-three:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(17, 29, 50, .60);
  z-index: -1;
}

.main-slider-three__content {
  position: relative;
  display: block;
}

.main-slider-three__title {
  position: relative;
  font-size: 70px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 19px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__text {
  font-size: 18px;
  color: var(--insur-white);
  font-weight: 400;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 30px;
  padding-bottom: 41px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three .swiper-slide-active .main-slider-three__text {
  visibility: visible;
  opacity: 0.70;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-three__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three .swiper-slide-active .main-slider-three__title,
.main-slider-three .swiper-slide-active .main-slider-three__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-three__nav {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}


.main-slider-three__nav .swiper-button-next,
.main-slider-three__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgba(var(--insur-white-rgb), .20);
  border: 2px solid rgba(var(--insur-white-rgb), .20);
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
}

.main-slider-three__nav .swiper-button-next:hover,
.main-slider-three__nav .swiper-button-prev:hover {
  color: rgba(var(--insur-white-rgb), 1);
  border: 2px solid rgba(var(--insur-white-rgb), 1);
}

.main-slider-three__nav .swiper-button-next::after,
.main-slider-three__nav .swiper-button-prev::after {
  display: none;
}

.main-slider-three__nav .swiper-button-prev {
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Feature One
--------------------------------------------------------------*/
.feature-one {
  position: relative;
  display: block;
  padding: 60px 0 90px;
}

.feature-one__inner {
  position: relative;
  display: block;
  counter-reset: count;
}

.feature-one__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  border-radius: var(--insur-bdr-radius);
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.feature-one__single:hover {
  transform: translateY(-10px);
}

.feature-one__single-inner {
  position: relative;
  display: block;
  padding: 132px 50px 32px;
  border-radius: var(--insur-bdr-radius);
  overflow: hidden;
  z-index: 1;
}

.feature-one__single-inner:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: var(--insur-black);
  border-radius: var(--insur-bdr-radius);
  transition: all 500ms ease;
  transform: translate3d(0px, 100%, 0px);
  z-index: -1;
}

.feature-one__single:hover .feature-one__single-inner:before {
  transform: translate3d(0px, 0, 0px);
}

.feature-one__icon {
  position: absolute;
  left: -40px;
  top: -90px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(90deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(90deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(90deg, #151d41 0%, #009289 100%);
  background-image: linear-gradient(90deg, #151d41 0%, #009289 100%);
  width: 197px;
  height: 197px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-one__single:hover .feature-one__icon {
  background-image: -moz-linear-gradient(0deg, #009289 0%, #151d41 100%);
  background-image: -webkit-linear-gradient(0deg, #009289 0%, #151d41 100%);
  background-image: -ms-linear-gradient(0deg, #009289 0%, #151d41 100%);
}

.feature-one__icon span {
  font-size: 65px;
  color: var(--insur-white);
  position: relative;
  display: inline-block;
  top: 107px;
  left: 14px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.feature-one__single:hover .feature-one__icon span {
  transform: scale(.9);
}

.feature-one__count {
  position: absolute;
  top: 50px;
  right: 54px;
  height: 45px;
  width: 56px;
  text-align: center;
}

.feature-one__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  -webkit-text-stroke: 1px #e0e4e8;
  font-size: 60px;
  line-height: 45px;
  font-weight: 700;
  counter-increment: count;
  content: counters(count, ".", decimal-leading-zero);
  letter-spacing: var(--insur-letter-spacing);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.feature-one__single:hover .feature-one__count:before {
  -webkit-text-stroke: 1px var(--insur-primary);
}

.feature-one__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  transition-delay: 400ms;
  transform: translateX(100%);
  z-index: -1;
}

.feature-one__single:hover .feature-one__shape {
  transform: translateX(0%);
}

.feature-one__shape img {
  width: auto;
  mix-blend-mode: luminosity;
  opacity: 0.07;
}

.feature-one__title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 34px;
  margin-bottom: 14px;
  text-decoration: none;
}

.feature-one__title a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-decoration: none;
}

.feature-one__single:hover .feature-one__title a {
  color: var(--insur-white);
}

.feature-one__text {
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-one__single:hover .feature-one__text {
  color: #97a2b7;
}

/*--------------------------------------------------------------
# About One
--------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  padding: 0 0 120px;
  background-color: var(--insur-white);
  z-index: 1;
}

.about-one-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 826px;
  height: 627px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  mix-blend-mode: luminosity;
  opacity: 0.05;
  z-index: -1;
}

.about-one__left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.about-one__img-box {
  position: relative;
  display: block;
}

.about-one__img {
  position: relative;
  display: block;
}

.about-one__img>img {
  width: 100%;
  border-radius: 20px;
}

.about-one__img-two {
  position: absolute;
  bottom: -105px;
  left: -80px;
}

.about-one__img-two>img {
  width: auto;
  border-radius: 20px;
}

.about-one__experience {
  position: absolute;
  bottom: -62px;
  left: 80px;
  display: flex;
  align-items: center;
  background-color: var(--insur-base);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 27px 40px 23px;
}

.about-one__experience:before {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  content: "";
  border: 2px dashed var(--insur-primary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.about-one__experience:after {
  position: absolute;
  bottom: 0px;
  right: -65px;
  content: "";
  border-top: 0px solid transparent;
  border-left: 65px solid #0ce0ff;
  border-bottom: 62px solid transparent;
}

.about-one__experience-year {
  font-size: 60px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-one__experience-text {
  font-size: 20px;
  color: var(--insur-white);
  line-height: 24px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  margin-left: 18px;
}

.about-one__shape-1 {
  position: absolute;
  top: 35px;
  right: -30px;
  opacity: .30;
  z-index: -1;
}

.about-one__shape-1 img {
  width: auto;
}

.about-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-one__right .section-title {
  margin-bottom: 27px;
}

.about-one__text-1 {
  font-size: 24px;
  color: var(--insur-base);
  font-weight: 500;
  line-height: 40px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-one__points {
  position: relative;
  display: block;
  margin-top: 33px;
  margin-bottom: 37px;
}

.about-one__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-one__points li+li {
  margin-top: 8px;
}

.about-one__points li .icon {
  height: 16px;
  width: 16px;
  background-color: var(--insur-primary);
  font-size: 10px;
  color: var(--insur-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-one__points li .text {
  margin-left: 15px;
}

.about-one__points li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.about-one__text-2 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-one__btn-call {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 41px;
}

.about-one__call {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.about-one__call-icon {
  position: relative;
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-bdr-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--insur-primary);
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-one__call-icon:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
  border: 2px solid var(--insur-base);
}

.about-one__call-content {
  position: relative;
  margin-left: 10px;
  top: -2px;
}

.about-one__call-content a {
  font-size: 16px;
  font-weight: 700;
  color: var(--insur-black);
  display: inline-block;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-decoration: none;
}

.about-one__call-content a:hover {
  color: var(--insur-base);
}

.about-one__call-content p {
  font-size: 14px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 16px;
  margin-bottom: 0px;
}

/*--------------------------------------------------------------
# Services One
--------------------------------------------------------------*/
.services-one {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 90px;
}

.services-one__top {
  position: relative;
  display: block;
}

.services-one__top-left {
  position: relative;
  display: block;
}

.services-one__top-right {
  position: relative;
  display: block;
  margin-left: 170px;
  margin-top: 16px;
}

.services-one__top-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.services-one__bottom {
  position: relative;
  display: block;
}

.services-one__container {
  position: relative;
  display: block;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.services-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.service-one__img {
  position: relative;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.service-one__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--insur-primary-rgb), .30);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 500ms ease;
  transform: translate3d(0px, -100%, 0px);
  z-index: 1;
}

.services-one__single:hover .service-one__img:before {
  transform: translate3d(0px, 0, 0px);
}

.service-one__img img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__single:hover .service-one__img img {
  transform: scale(1.05);
}

.service-one__content {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 37px 30px 28px;
}

.services-one__icon {
  position: absolute;
  top: -40px;
  right: 20px;
  height: 60px;
  width: 60px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: var(--insur-bdr-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 3;
}

.services-one__single:hover .services-one__icon {
  background-color: var(--insur-base);
}

.services-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 36px;
  color: var(--insur-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-one__single:hover .services-one__icon span {
  color: var(--insur-white);
  transform: scale(0.9);
}

.service-one__title {
  position: relative;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 5px;
}

.service-one__title:before {
  position: absolute;
  content: "";
  left: 0;
  top: -16px;
  height: 4px;
  width: 40px;
  background-image: -moz-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(0deg, #151d41 0%, #009289 100%);
}

.service-one__title a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-decoration: none;
}

.services-one__single:hover .service-one__title a {
  color: var(--insur-base);
}

.service-one__text {
  font-size: 14px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 24px;
}

/*--------------------------------------------------------------
# Why Choose One
--------------------------------------------------------------*/
.why-choose-one {
  position: relative;
  display: block;
  background-color: var(--insur-black);
  padding: 120px 0 110px;
  overflow: hidden;
  z-index: 1;
}

.why-choose-one-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.02;
  z-index: -1;
}

.why-choose-one-shape-2 {
  position: absolute;
  right: 596px;
  top: -215px;
  z-index: 2;
}

.why-choose-one-shape-2 img {
  width: auto;
}

.why-choose-one-shape-3 {
  position: absolute;
  top: -260px;
  right: 150px;
}

.why-choose-one-shape-3 img {
  width: auto;
}

.why-choose-one-shape-4 {
  position: absolute;
  top: -81px;
  right: 0;
}

.why-choose-one-shape-4 img {
  width: auto;
}

.why-choose-one-shape-5 {
  position: absolute;
  bottom: -215px;
  right: 0;
  z-index: 1;
}

.why-choose-one-shape-5 img {
  width: auto;
}

.why-choose-one-shape-6 {
  position: absolute;
  bottom: -295px;
  right: 160px;
}

.why-choose-one-shape-6 img {
  width: auto;
}

.why-choose-one-img {
  position: absolute;
  top: -111px;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.why-choose-one-img img {
  width: auto;
}

.why-choose-one__left {
  position: relative;
  display: block;
}

.why-choose-one__left .section-title {
  margin-bottom: 31px;
}

.why-choose-one__left .section-sub-title {
  color: var(--insur-white);
}

.why-choose-one__left .section-title__title {
  color: var(--insur-white);
}

.why-choose-one__text {
  color: #97a2b7;
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.why-choose-one__list-box {
  position: relative;
  display: block;
  max-width: 510px;
  width: 100%;
  margin-top: 31px;
}

.why-choose-one__list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.why-choose-one__list li {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.why-choose-one__single {
  position: relative;
  display: block;
  background-color: #111d32;
  padding: 35px 20px 32px;
  overflow: hidden;
}

.why-choose-one__list-icon {
  position: relative;
  display: block;
  margin-bottom: 9px;
}

.why-choose-one__list-icon span {
  font-size: 45px;
  color: var(--insur-primary);
  position: relative;
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.why-choose-one__single:hover .why-choose-one__list-icon span {
  transform: scale(.9);
}

.why-choose-one__list-title-box {
  position: relative;
  display: block;
}

.why-choose-one__list-title-inner {
  position: relative;
  display: block;
  transform: translateY(0%);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: visible;
  opacity: 1;
}

.why-choose-one__single:hover .why-choose-one__list-title-inner {
  transform: translateY(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
}

.why-choose-one__list-title {
  font-size: 18px;
  color: var(--insur-white);
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.why-choose-one__list-text-box {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  transform: translateY(-100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
}

.why-choose-one__single:hover .why-choose-one__list-text-box {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

.why-choose-one__list-text {
  font-size: 14px;
  color: #97a2b7;
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Get Insurance
--------------------------------------------------------------*/
.get-insurance {
  position: relative;
  display: block;
  padding: 120px 0px 130px;
  background-color: var(--insur-white);
  z-index: 1;
}

.get-insurance:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-image: -moz-linear-gradient(90deg, rgb(242, 245, 249) 0%, rgba(242, 245, 249, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(242, 245, 249) 0%, rgba(242, 245, 249, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(242, 245, 249) 0%, rgba(242, 245, 249, 0) 100%);
  z-index: -1;
}

.get-insurance-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: luminosity;
  background-color: var(--insur-white);
  opacity: 0.06;
  z-index: -1;
}

.get-insurance__left {
  position: absolute;
  bottom: 0;
  left: 105px;
}

.get-insurance__img {
  position: relative;
  display: block;
  z-index: 3;
}

.get-insurance__img img {
  width: 100%;
}

.get-insurance__author {
  position: absolute;
  top: 40px;
  left: -176px;
  border-radius: 50%;
  background-color: rgb(242, 245, 249);
  width: 550px;
  height: 550px;
}

.get-insurance__author p {
  position: relative;
  display: inline-block;
  font-size: 34px;
  color: var(--insur-black);
  font-family: var(--insur-reey-font);
  top: 194px;
  left: 70px;
}

.get-insurance__circle {
  position: absolute;
  left: 313px;
  top: 173px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  width: 244px;
  height: 244px;
  z-index: 1;
}

.get-insurance__shape-1 {
  position: absolute;
  bottom: 0;
  left: -145px;
}

.get-insurance__shape-1 img {
  width: auto;
}

.get-insurance__right {
  position: relative;
  display: block;
}

.get-insurance__tab {
  position: relative;
  display: block;
}

.get-insurance__tab-box {
  position: relative;
  display: block;
  z-index: 2;
}

.get-insurance__tab-box .tab-buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}

.get-insurance__tab-box .tab-buttons .tab-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.get-insurance__tab-box .tab-buttons .tab-btn+.tab-btn {
  margin-left: 10px;
}

.get-insurance__tab-box .tab-buttons .tab-btn span {
  position: relative;
  display: inline-block;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  font-size: 14px;
  color: var(--insur-white);
  background-color: var(--insur-black);
  padding: 7px 25px 7px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 1;
}

.get-insurance__tab-box .tab-buttons .tab-btn.active-btn span {
  color: var(--insur-white);
  background-color: var(--insur-base);
}

.get-insurance__tab-box .tab-buttons .tab-btn span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  border-radius: 5px;
  content: "";
  background-color: var(--insur-base);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
}

.get-insurance__tab-box .tab-buttons .tab-btn.active-btn span:before {
  height: 100%;
}

.get-insurance__tab-box .tabs-content {
  position: relative;
  display: block;
}

.get-insurance__tab-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}

.get-insurance__tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.get-insurance__content {
  position: relative;
  display: block;
}

.get-insurance__form {
  position: relative;
  display: block;
}

.get-insurance__content-box {
  position: relative;
  display: block;
}

.get-insurance__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.get-insurance__input-box input[type="text"],
.get-insurance__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: 1px solid #dfe3e7;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 16px;
  color: var(--insur-gray);
  font-weight: 400;
  padding: 0 30px 0;
  border-radius: var(--insur-bdr-radius);
}

.get-insurance__input-box textarea{

  width: 100%;
  border: 1px solid #dfe3e7;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 16px;
  color: var(--insur-gray);
  font-weight: 400;
  padding: 1rem 30px 0;
  border-radius: var(--insur-bdr-radius);
  min-height: 100px;
}

.get-insurance__input-box .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 100% !important;

}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle::after {
  display: none;
}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle {
  position: relative;
  height: 60px;
  outline: none !important;
  border-radius: var(--insur-bdr-radius);
  border: 1px solid #dfe3e7;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--insur-gray) !important;
  font-size: 16px;
  line-height: 60px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 14px;
  color: var(--insur-gray);
}

.get-insurance__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 23px;
  margin-bottom: 50px;
}

.get-insurance__progress-single {
  position: relative;
  display: block;
}

.get-insurance__progress-title {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 14px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insurance__progress .bar {
  position: relative;
  width: 100%;
  height: 11px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #dfe3e7;
}

.get-insurance__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 11px;
  border-radius: 5px;
  background-image: -moz-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(0deg, #151d41 0%, #009289 100%);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.get-insurance__progress .count-text {
  position: absolute;
  right: -30px;
  bottom: -8px;
  opacity: 0;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(22, 36, 61);
  width: 34px;
  height: 34px;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.get-insurance__balance-box {
  position: absolute;
  top: 0;
  right: 0;
}

.get-insurance__balance {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insurance__btn {
  border: none;
}

/*--------------------------------------------------------------
# Counter One
--------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
  background-color: var(--insur-base);
  padding: 79px 0 71px;
}

.counter-one-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: .20;
}

.counter-one-shape-1 img {
  width: auto;
}

.counter-one-shape-2 {
  position: absolute;
  bottom: -35px;
  right: 0;
  opacity: .20;
}

.counter-one-shape-2 img {
  width: auto;
}

.counter-one__single {
  position: relative;
  display: block;
}

.counter-one__top {
  position: relative;
  display: flex;
  margin-bottom: 13px;
}

.counter-one__icon {
  position: relative;
  display: block;
}

.counter-one__icon span {
  position: relative;
  display: flex;
  font-size: 60px;
  color: var(--insur-primary);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.counter-one__single:hover .counter-one__icon span {
  color: var(--insur-white);
  transform: scale(.9);
}

.counter-one__count-box {
  position: relative;
  margin-left: 16px;
  margin-top: 27px;
}

.counter-one__count-box:before {
  position: absolute;
  top: -26px;
  left: 0;
  height: 4px;
  width: 40px;
  border-radius: 2px;
  content: "";
  background-image: -moz-linear-gradient(0deg, rgb(242, 245, 249) 0%, #009289 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(242, 245, 249) 0%, #009289 100%);
  background-image: -ms-linear-gradient(0deg, rgb(242, 245, 249) 0%, #009289 100%);
}

.counter-one__count-box-inner {
  position: relative;
  display: flex;
  align-items: center;
}

.counter-one__count-box-inner h3 {
  font-size: 36px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 36px !important;
  font-family: var(--insur-font) !important;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 0px;
}

.counter-one__plus {
  font-size: 36px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 36px !important;
  font-family: var(--insur-font) !important;
  letter-spacing: var(--insur-letter-spacing);
}

.counter-one__text {
  font-size: 24px;
  color: #c2dfff;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Team One
--------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  overflow: hidden;
}

.team-one__shape-1 {
  position: absolute;
  top: -90px;
  right: 0;
  mix-blend-mode: luminosity;
}

.team-one__shape-1 img {
  width: auto;
  mix-blend-mode: luminosity;
  opacity: .15;
  z-index: -1;
}

.team-one .container {
  max-width: 1070px;
}

.team-one .row {
  --bs-gutter-x: 108px;
}

.team-one .section-title {
  margin-bottom: 63px;
}

.team-one .section-sub-title-box {
  margin-left: 0;
}

.team-one__single {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.team-one__img {
  position: relative;
  display: block;
  border-radius: 20px;
  z-index: 1;
}

.team-one__img:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-radius: 20px;
  border: 2px dashed var(--insur-bdr-color);
  transform: rotate(-7deg);
  transition: all 500ms ease;
  z-index: -1;
}

.team-one__single:hover .team-one__img:after {
  border: 2px dashed var(--insur-primary);
}

.team-one__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  z-index: 1;
}

.team-one__img-box:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-radius: 20px;
  background-color: rgba(var(--insur-primary-rgb), .50);
  -webkit-transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  -ms-transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  z-index: 1;
}

.team-one__single:hover .team-one__img-box:before {
  -webkit-transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  -ms-transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  transform: perspective(400px) rotateX(0deg) scaleY(1.0);
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.team-one__img-box img {
  width: 100%;
  border-radius: 20px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 500ms ease;
}

.team-one__single:hover .team-one__img-box img {
  transform: scale(1.05);
}

.team-one__social {
  position: absolute;
  right: 20px;
  bottom: 25px;
  background-color: var(--insur-base);
  text-align: center;
  padding: 20px 17.5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: scaleY(0.0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  transform-origin: bottom center;
  z-index: 2;
}

.team-one__single:hover .team-one__social {
  transform: scaleY(1.0);
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -ms-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
}

.team-one__social li+li {
  margin-top: 25px;
}

.team-one__social li a {
  font-size: 15px;
  color: var(--insur-white);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__social li a:hover {
  color: var(--insur-primary);
}


.team-one__content {
  position: relative;
  display: block;
  padding-top: 28px;
}

.team-one__sub-title {
  font-size: 14px;
  color: var(--insur-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
}

.team-one__name {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 30px;
}

.team-one__name a {
  color: var(--insur-black);
  transition: all 500ms ease;
}

.team-one__name a:hover {
  color: var(--insur-base);
}

.team-one__social-two {
  position: absolute;
  right: 20px;
  top: -25px;
  background-color: var(--insur-primary);
  padding: 17.5px 18.4px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.team-one__single:hover .team-one__social-two {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--insur-base);
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -ms-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
}

.team-one__social-two li a {
  font-size: 15px;
  color: var(--insur-white);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__social-two li a:hover {
  color: var(--insur-primary);
}

.team-one__bottom {
  position: relative;
  display: block;
  text-align: center;
  max-width: 560px;
  width: 100%;
  margin: 35px auto 0;
  border: 2px dashed var(--insur-base);
  border-radius: var(--insur-bdr-radius);
  padding: 10px 0;
}

.team-one__bottom-text {
  font-weight: 500;
}

.team-one__bottom-text span {
  color: var(--insur-primary);
  font-weight: 700;
}

/*--------------------------------------------------------------
# Testimonial One
--------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-one-shape-2 {
  position: absolute;
  top: -40px;
  right: 0;
  mix-blend-mode: luminosity;
}

.testimonial-one-shape-2 img {
  width: auto;
  mix-blend-mode: luminosity;
  opacity: .15;
}

.testimonial-one-shape-3 {
  position: absolute;
  bottom: -90px;
  left: 0;
  mix-blend-mode: luminosity;
}

.testimonial-one-shape-3 img {
  width: auto;
  mix-blend-mode: luminosity;
  opacity: .15;
}

.testimonial-one .container {
  max-width: 1160px;
}

.testimonial-one__top {
  position: relative;
  display: block;
}

.testimonial-one__top-left {
  position: relative;
  display: block;
}

.testimonial-one__top-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 16px;
}

.testimonial-one__top-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.testimonial-one__bottom {
  position: relative;
  display: block;
}

.testimonial-one__carousel {
  position: relative;
  display: block;
}

.testimonial-one__single {
  position: relative;
  display: block;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-one__single:hover {
  transform: translateY(-10px);
}

.testimonial-one__single-inner {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 65px 60px 55px;
  border-radius: 20px;
  background-color: var(--insur-white);
}

.testimonial-one__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.testimonial-one__shape-1>img {
  width: auto !important;
  mix-blend-mode: luminosity;
  border-top-right-radius: 20px;
  opacity: .20;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-one__single:hover .testimonial-one__shape-1>img {
  mix-blend-mode: normal;
  opacity: 1;
}

.testimonial-one__client-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 26px;
  text-align: left;
}

.testimonial-one__client-img-box {
  position: relative;
  display: block;
  width: 100%;
  max-width: 115px;
  z-index: 1;
}

.testimonial-one__client-img-box:before {
  position: absolute;
  content: "";
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  background-image: -moz-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: linear-gradient(-29deg, #151d41 0%, #009289 100%);
  border-top-left-radius: 45px;
  z-index: -1;
}

.testimonial-one__client-img-box>img {
  width: 100% !important;
  border-top-left-radius: 45px;
  border: 6px solid var(--insur-white);
}

.testimonial-one__quote {
  position: absolute;
  top: -28px;
  right: -33px;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-one__quote>img {
  width: auto !important;
}

.testimonial-one__client-content {
  margin-left: 45px;
  position: relative;
  top: 3px;
}

.testimonial-one__client-review {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.testimonial-one__client-review i {
  font-size: 15px;
  color: #fab600;
}

.testimonial-one__client-details {
  position: relative;
  display: block;
}

.testimonial-one__client-name {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 34px;
}

.testimonial-one__client-sub-title {
  font-size: 14px;
  color: var(--insur-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.testimonial-one__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}


.testimonial-one__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-one__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;

}

.testimonial-one__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-one__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# News One
--------------------------------------------------------------*/
.news-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.news-one .section-sub-title-box {
  margin-left: 0;
}

.news-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.news-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  z-index: 1;
}

.news-one__img:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  background-color: rgba(var(--insur-primary-rgb), .50);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  z-index: 1;
}

.news-one__single:hover .news-one__img:before {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}

.news-one__img img {
  width: 100%;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  transition: all 500ms ease;
}

.news-one__single:hover .news-one__img img {
  transform: scale(1.05);
}

.news-one__tag {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--insur-base);
  padding: 14px 20px;
  border-top-left-radius: var(--insur-bdr-radius);
  z-index: 2;
}

.news-one__tag p {
  font-size: 12px;
  color: var(--insur-white);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
}

.news-one__tag p i {
  font-size: 12px;
  padding-right: 3px;
}

.news-one__arrow-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.news-one__arrow {
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--insur-white);
  font-size: 15px;
  opacity: 0;
  transform: translateY(70px);
  transition: all 500ms ease;
}

.news-one__single:hover .news-one__arrow {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 500ms;
}

.news-one__arrow:hover {
  border: 2px solid var(--insur-primary);
  color: var(--insur-primary);
}

.news-one__content {
  position: relative;
  display: block;
  border-bottom-left-radius: var(--insur-bdr-radius);
  border-bottom-right-radius: var(--insur-bdr-radius);
  border: 1px solid var(--insur-bdr-color);
  border-top: 0;
  padding: 25px 38px 32px;
  transition: all 500ms ease;
}

.news-one__single:hover .news-one__content {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.news-one__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-one__meta li+li {
  margin-left: 10px;
}

.news-one__meta li a {
  font-size: 14px;
  color: var(--insur-gray);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  transition: all 500ms ease;
}

.news-one__meta li a:hover {
  color: var(--insur-base);
}

.news-one__meta li a i {
  font-size: 15px;
  color: var(--insur-base);
  padding-right: 2px;
}

.news-one__title {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 15px;
}

.news-one__title a {
  color: var(--insur-black);
  transition: all 500ms ease;
}

.news-one__single:hover .news-one__title a {
  color: var(--insur-base);
}

.news-one__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-one__read-more {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 11px;
}

.news-one__read-more a {
  font-size: 15px;
  color: var(--insur-black);
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 500ms ease;
}

.news-one__read-more a i {
  font-size: 13px;
  position: relative;
  padding-left: 5px;
  top: 2px;
}

.news-one__single:hover .news-one__read-more a {
  color: var(--insur-primary);
}

/*--------------------------------------------------------------
# Tracking
--------------------------------------------------------------*/
.tracking {
  position: relative;
  display: block;
}

.tracking__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 57px 80px 57px;
  background-color: var(--insur-base);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.tracking-shape-1 {
  position: absolute;
  bottom: 0;
  left: -55px;
}

.tracking-shape-1 img {
  width: auto;
}

.tracking-shape-2 {
  position: absolute;
  top: 50px;
  left: -100px;
}

.tracking-shape-2 img {
  width: auto;
}

.tracking-shape-3 {
  position: absolute;
  top: -15px;
  right: -192px;
  z-index: 2;
}

.tracking-shape-3 img {
  width: auto;
}

.tracking-shape-4 {
  position: absolute;
  top: 50px;
  right: -55px;
}

.tracking-shape-4 img {
  width: auto;
}

.tracking__left {
  position: relative;
  display: flex;
}

.tracking__icon {
  position: relative;
  display: flex;
}

.tracking__icon span {
  font-size: 65px;
  color: var(--insur-white);
  position: relative;
  display: inline-block;
}

.tracking__content {
  position: relative;
  margin-left: 30px;
  top: -7px;
}

.tracking__sub-title {
  font-size: 14px;
  color: #c2dfff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
  font-weight: 500;
}

.tracking__title {
  font-size: 36px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 47px;
}

.tracking__btn-box {
  position: relative;
  display: block;
  z-index: 2;
}

.tracking__btn {
  background-color: var(--insur-white);
  color: var(--insur-black);
}



/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--insur-black);
  z-index: 1;
}

.site-footer-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1709px;
  mix-blend-mode: color-burn;
  opacity: .15;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.site-footer__top {
  position: relative;
  display: block;
  padding: 100px 0 82px;
}

.footer-widget__logo {
  position: relative;
  display: inline-block;
  margin-bottom: 33px;
  margin-top: -8px;
}

.footer-widget__about {
  position: relative;
  display: block;
}

.footer-widget__about-text-box {
  position: relative;
  display: block;
}

.footer-widget__about-text {
  color: #97a2b7;
  line-height: 26px;
  font-size: 15px;
  letter-spacing: var(--insur-letter-spacing);
}

.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 32px;
}

.site-footer__social a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--insur-white);
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--insur-base);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.site-footer__social a:hover {
  color: var(--insur-base);
  background-color: var(--insur-white);
  border: 2px solid var(--insur-white);
}

.site-footer__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--insur-white);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.site-footer__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.site-footer__social a+a {
  margin-left: 10px;
}

.footer-widget__title {
  font-size: 20px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 20px;
  margin-bottom: 40px;
}

.footer-widget__contact {
  position: relative;
  display: block;
}

.footer-widget__contact-list {
  position: relative;
  display: block;
}

.footer-widget__contact-list li {
  position: relative;
  display: flex;
}

.footer-widget__contact-list li+li {
  margin-top: 20px;
}

.footer-widget__contact-list li .icon {
  position: relative;
  display: block;
}

.footer-widget__contact-list li .icon span {
  font-size: 14px;
  color: var(--insur-base);
}

.footer-widget__contact-list li .text {
  margin-left: 14px;
}

.footer-widget__contact-list li .text p {
  font-size: 15px;
  color: #97a2b7;
  line-height: 25px;
  letter-spacing: var(--insur-letter-spacing);
}

.footer-widget__contact-list li .text p a {
  color: #97a2b7;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__contact-list li .text p a:hover {
  color: var(--insur-primary);
}

.footer-widget__open-hour {
  position: relative;
  display: block;
  margin-top: 26px;
}

.footer-widget__open-hour-title {
  font-size: 15px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 25px;
  margin-bottom: 10px;
}

.footer-widget__open-hour-text {
  font-size: 15px;
  color: #97a2b7;
  line-height: 26px;
  letter-spacing: var(--insur-letter-spacing);
}

.footer-widget__gallery {
  position: relative;
  display: block;
}

.footer-widget__gallery-list {
  position: relative;
  display: block;
  margin-right: -5px;
  margin-left: -5px;
  padding-top: 7px;
}

.footer-widget__gallery-list li {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 15px;
}

.footer-widget__gallery-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}

.footer-widget__gallery-img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--insur-base-rgb), .90);
  transition: all 700ms ease;
  transform: translateY(-100%);
  border-radius: 5px;
  z-index: 1;
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img:before {
  transform: translateY(0%);
}

.footer-widget__gallery-img img {
  width: 100%;
  border-radius: 5px;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
  transform: scale(1);
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img img {
  transform: scale(1.05);
}

.footer-widget__gallery-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--insur-white);
  transform: translateY(100px);
  opacity: 0;
  transition: all 700ms ease;
  z-index: 2;
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img a {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}

.footer-widget__newsletter {
  position: relative;
  display: block;
}

.footer-widget__newsletter-text {
  color: #97a2b7;
  line-height: 26px;
  font-size: 15px;
  letter-spacing: var(--insur-letter-spacing);
}

.footer-widget__newsletter-form {
  position: relative;
  display: block;
  margin-top: 27px;
  margin-bottom: 27px;
}

.footer-widget__newsletter-input-box {
  position: relative;
  display: block;
}

.footer-widget__newsletter-input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 60px;
  background-color: #111d32;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #97a2b7;
  letter-spacing: var(--insur-letter-spacing);
}

.footer-widget__newsletter-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  height: 35px;
  width: 35px;
  border: none;
  font-size: 15px;
  background-color: transparent;
  color: var(--insur-white);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__newsletter-btn:hover {
  color: var(--insur-base);
}

.footer-widget__phone {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-widget__phone-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-widget__phone-icon span {
  font-size: 37px;
  color: var(--insur-primary);
  position: relative;
  display: inline-block;
}

.footer-widget__phone-text {
  position: relative;
  margin-left: 15px;
}

.footer-widget__phone-text a {
  font-size: 18px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__phone-text a:hover {
  color: var(--insur-primary);
}

.footer-widget__phone-text p {
  font-size: 14px;
  color: #97a2b7;
  line-height: 26px;
  letter-spacing: var(--insur-letter-spacing);
}

.site-footer__bottom {
  position: relative;
  display: block;
}

.site-footer__bottom-inner {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid rgba(var(--insur-white-rgb), .10);
  padding: 34px 0;
}

.site-footer__bottom-text {
  color: #97a2b7;
  letter-spacing: var(--insur-letter-spacing);
}

.site-footer__bottom-text a {
  color: #97a2b7;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-text a:hover {
  color: var(--insur-white);
}

/*--------------------------------------------------------------
# Feature Two
--------------------------------------------------------------*/
.feature-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.feature-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__single:hover {
  transform: translateY(-10px);
}

.feature-two__img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
  overflow: hidden;
}

.feature-two__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-radius: var(--insur-bdr-radius);
  background-color: rgba(var(--insur-base-rgb), .30);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: 1;
}

.feature-two__single:hover .feature-two__img:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.feature-two__img img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__img img {
  transform: scale(1.05);
}

.feature-two__content {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  margin-right: 70px;
  margin-top: -55px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
}

.feature-two__content-inner {
  position: relative;
  display: flex;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.feature-two__content-inner:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: var(--insur-base);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translateX(-100%);
  transition: all 700ms ease;
  z-index: -1;
}

.feature-two__single:hover .feature-two__content-inner:before {
  transform: translateX(0);
}

.feature-two__icon {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 84px;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  background-color: var(--insur-base);
  border-bottom-left-radius: 10px;
}

.feature-two__icon span {
  font-size: 40px;
  color: var(--insur-white);
  position: relative;
  display: inline-block;
}

.feature-two__text-box {
  position: relative;
  display: block;
  padding-left: 20px;
}

.feature-two__text-box:before {
  position: absolute;
  content: "";
  top: 1px;
  bottom: -2px;
  left: 0;
  width: 2px;
  background-color: rgba(var(--insur-white-rgb), .10);
  transform: scaleY(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__text-box:before {
  transform: scaleY(1);
  transition-delay: 700ms;
}

.feature-two__text {
  font-size: 20px;
  font-weight: 700;
  color: var(--insur-black);
  letter-spacing: var(--insur-letter-spacing);
  line-height: 25px;
  transition: all 700ms ease;
}

.feature-two__text a {
  color: var(--insur-black);
  transition: all 700ms ease;
}

.feature-two__single:hover .feature-two__text a {
  color: var(--insur-white);
}

/*--------------------------------------------------------------
# About Two
--------------------------------------------------------------*/
.about-two {
  position: relative;
  display: block;
  padding: 0 0 120px;
}

.about-two__left {
  position: relative;
  display: block;
  margin-right: 42px;
}

.about-two__left .section-title {
  margin-bottom: 39px;
}

.about-two__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__points {
  position: relative;
  display: block;
  margin-top: 36px;
  margin-bottom: 42px;
}

.about-two__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-two__points li+li {
  margin-top: 8px;
}

.about-two__points li .icon {
  height: 16px;
  width: 16px;
  background-color: var(--insur-primary);
  font-size: 10px;
  color: var(--insur-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-two__points li .text {
  margin-left: 20px;
}

.about-two__points li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__middle {
  position: relative;
  display: block;
  margin-right: 40px;
}

.about-two__img-box {
  position: relative;
  display: block;
}

.about-two__img {
  position: relative;
  display: block;
}

.about-two__img img {
  width: 100%;
  border-radius: 20px;
}

.about-two__awards-box {
  position: absolute;
  bottom: 45px;
  left: -150px;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 15px;
  z-index: 1;
}

.about-two__awards-box:before {
  position: absolute;
  bottom: -46px;
  left: 70px;
  content: "";
  background-image: url(/assets/images/shapes/about-two-awards-shape-1.png);
  background-repeat: no-repeat;
  width: 73px;
  height: 46px;
  z-index: -1;
}

.about-two__awards-inner {
  position: relative;
  display: block;
  background-color: var(--insur-black);
  border: 4px solid var(--insur-primary);
  border-radius: 20px;
  padding-top: 17px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 40px;
  overflow: hidden;
}

.about-two__awards-shape-2 {
  position: absolute;
  top: -36px;
  right: -38px;
}

.about-two__awards-shape-2 img {
  width: auto;
}

.about-two__awards-year {
  font-size: 72px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 72px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__awards-content {
  font-size: 20px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 23px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__dots {
  position: absolute;
  bottom: 225px;
  left: -80px;
  z-index: -1;
}

.about-two__dots img {
  width: auto;
  opacity: .20;
}

.about-two__counter {
  position: relative;
  display: block;
  text-align: right;
  margin-left: -20px;
  margin-top: -8px;
}

.about-two__counter-list {
  position: relative;
  display: block;
}

.about-two__counter-list li {
  position: relative;
  display: block;
  padding-bottom: 33px;
  margin-bottom: 32px;
}

.about-two__counter-list li:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 14px;
  right: 0;
  background-color: var(--insur-bdr-color);
  height: 2px;
}

.about-two__counter-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.about-two__counter-list li:last-child:before {
  display: none;
}

.about-two__counter-single {
  position: relative;
  display: block;
}

.about-two__counter-count {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.about-two__counter-count h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px !important;
  color: var(--insur-base);
  font-family: var(--insur-font) !important;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__counter-percent {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px !important;
  color: var(--insur-base);
  font-family: var(--insur-font) !important;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__counter-text-1 {
  font-size: 20px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
  margin-top: 15px;
  margin-bottom: 4px;
}

.about-two__counter-text-2 {
  font-size: 15px;
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-two__counter .odometer-formatting-mark {
  display: none;
}

/*--------------------------------------------------------------
# Services Two
--------------------------------------------------------------*/
.services-two {
  position: relative;
  display: block;
  background-color: var(--insur-black);
  padding: 120px 0 90px;
  z-index: 1;
}

.services-two-shape-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  opacity: 0.02;
  z-index: -1;
}

.services-two__top {
  position: relative;
  display: block;
}

.services-two__top-left {
  position: relative;
  display: block;
}

.services-two__top-left .section-sub-title {
  color: #97a2b7;
}

.services-two__top-left .section-title__title {
  color: var(--insur-white);
}

.services-two__top-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 14px;
}

.services-two__top-text {
  font-size: 18px;
  color: #97a2b7;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.services-two__bottom {
  position: relative;
  display: block;
}

.services-two__single {
  position: relative;
  display: block;
  background-color: #1c2a44;
  border-radius: var(--insur-bdr-radius);
  text-align: center;
  padding: 40px 35px 33px;
  border-bottom: 3px solid transparent;
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__single:hover {
  border-bottom: 3px solid var(--insur-primary);
  transform: translateY(-10px);
  background-color: #111d32;
}

.services-two__icon-box {
  position: relative;
  display: block;
}

.services-two__icon-box:before {
  position: absolute;
  content: "";
  bottom: -9px;
  left: 50%;
  height: 4px;
  width: 40px;
  background-color: #111d32;
  transform: translateX(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__icon-box:before {
  background-color: #070e1b;
}

.services-two__icon {
  position: relative;
  height: 85px;
  width: 85px;
  background-color: #111d32;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.services-two__icon:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  content: "";
  background-image: -moz-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(0deg, #151d41 0%, #009289 100%);
  border-radius: 50%;
  background-color: var(--insur-white);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.services-two__single:hover .services-two__icon:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.services-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 36px;
  background: rgb(1, 97, 202);
  background: linear-gradient(90deg, rgba(1, 97, 202, 1) 41%, rgba(12, 222, 254, 1) 67%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__icon span {
  -webkit-text-fill-color: var(--insur-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 30px;
  margin-top: 26px;
  margin-bottom: 5px;
}

.services-two__title a {
  color: var(--insur-white);
}

.services-two__text {
  font-size: 14px;
  color: #97a2b7;
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Work Together
--------------------------------------------------------------*/
.work-together {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.work-together__left {
  position: relative;
  display: block;
}

.work-together__left .section-title {
  margin-bottom: 38px;
}

.work-together__content-box {
  position: relative;
  display: flex;
  align-items: center;
}

.work-together__img {
  position: relative;
  display: block;
}

.work-together__img img {
  width: auto;
  border-radius: 7px;
}

.work-together__text-box {
  margin-left: 30px;
}

.work-together__text {
  font-weight: 500;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.work-together__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 24px;
}

.work-together__progress-single {
  position: relative;
  display: block;
}

.work-together__progress-title {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 7px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.work-together__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--insur-extra);
  border-radius: 6px;
}

.work-together__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 6px;
  background-color: var(--insur-primary);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.work-together__progress .count-text {
  position: absolute;
  right: -13px;
  bottom: 20px;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 14px;
  font-weight: 500;
  color: var(--insur-gray);
  letter-spacing: var(--insur-letter-spacing);
}

.work-together__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.work-together__right {
  position: relative;
  display: block;
}

.faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  border: 1px solid var(--insur-bdr-color);
  border-radius: 20px;
  background-color: var(--insur-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion.active {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  border-radius: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 15px;
  padding-right: 40px;
  border-bottom: 1px solid transparent;
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--insur-black);
  letter-spacing: var(--insur-letter-spacing);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion-title h4 span {
  position: relative;
  height: 37px;
  width: 37px;
  background-color: var(--insur-base);
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 37px;
  color: var(--insur-white);
  margin-right: 10px;
}

.faq-one-accrodion .accrodion+.accrodion {
  margin-top: 20px;
}

.faq-one-accrodion .accrodion.active .accrodion-title {
  color: var(--insur-base);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid var(--insur-bdr-color);
}

.faq-one-accrodion .accrodion.active .accrodion-title h4 {
  color: var(--insur-base);
}

.faq-one-accrodion .accrodion-title h4::before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  color: var(--insur-black);
  position: absolute;
  top: 51%;
  right: -15px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f107";
  color: var(--insur-primary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-one-accrodion .accrodion-content {
  position: relative;
  padding: 12px 30px 20px;
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.cta-one {
  position: relative;
  display: block;
  padding: 0 0 80px;
  z-index: 1;
}

.cta-one__content {
  position: relative;
  display: block;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1;
}

.cta-one__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--insur-black);
  border-radius: 20px;
  border: 4px solid var(--insur-primary);
  padding: 51px 55px 51px;
  overflow: hidden;
  z-index: 1;
}

.cta-one__left {
  position: relative;
  display: block;
}

.cta-one__title {
  font-size: 36px;
  font-weight: 700;
  color: var(--insur-white);
  line-height: 36px;
  letter-spacing: var(--insur-letter-spacing);
}

.cta-one__right {
  position: relative;
  display: flex;
  align-items: center;
}

.cta-one__call {
  position: relative;
  display: flex;
  align-items: center;
}

.cta-one__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-primary);
  border-radius: 50%;
  color: var(--insur-primary);
  font-size: 20px;
}

.cta-one__call-number {
  position: relative;
  top: -1px;
  margin-left: 10px;
}

.cta-one__call-number a {
  font-size: 16px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cta-one__call-number a:hover {
  color: var(--insur-primary);
}

.cta-one__call-number p {
  font-size: 14px;
  color: #97a2b7;
  line-height: 18px;
  letter-spacing: var(--insur-letter-spacing);
}

.cta-one__btn-box {
  margin-left: 40px;
}

.cta-one__btn:hover {
  color: var(--insur-black);
}

.cta-one__btn:before {
  background-color: var(--insur-white);
}

.cta-one__btn:after {
  background-color: var(--insur-white);
}

.cta-one__img {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: luminosity;
  opacity: .10;
  z-index: -1;
}

.cta-one__img img {
  width: auto;
}

.cta-two {
  padding: 120px 0 120px;
}

/*--------------------------------------------------------------
# Brand One
--------------------------------------------------------------*/
.brand-one {
  position: relative;
  display: block;
  border-top: 1px solid var(--insur-bdr-color);
  padding: 59px 0 60px;
  z-index: 1;
}

.brand-one__title {
  position: relative;
  display: block;
  margin-right: 60px;
  margin-top: 9px;
}

.brand-one__title h2 {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.brand-one__main-content {
  position: relative;
  display: block;
}

.brand-one__main-content .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.brand-one__main-content .swiper-slide img {
  -webkit-transition: 500ms;
  transition: 500ms;
  opacity: .30;
}

.brand-one__main-content .swiper-slide img:hover {
  opacity: 1;
}

/*--------------------------------------------------------------
# Get Insuracne Two
--------------------------------------------------------------*/
.get-insuracne-two {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  background-color: var(--insur-white);
  z-index: 1;
}

.get-insuracne-two:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 208px;
  right: 0;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 227, 231);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  content: "";
  border-top-left-radius: 377.5px;
  border-bottom-left-radius: 377.5px;
  z-index: -1;
}

.get-insuracne-two-shape-3 {
  position: absolute;
  top: 0;
  right: -18px;
  mix-blend-mode: luminosity;
  opacity: 0.03;
  z-index: -1;
}

.get-insuracne-two-shape-3 img {
  width: auto;
}

.get-insuracne-two__left {
  position: relative;
  display: block;
}

.get-insuracne-two__shape-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.get-insuracne-two-shape-1 {
  position: absolute;
  top: -44px;
  bottom: -42px;
  left: -375px;
  width: 814px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
}

.get-insuracne-two-shape-2 {
  position: absolute;
  top: -34px;
  bottom: 0;
  left: -375px;
  mix-blend-mode: luminosity;
  opacity: .15;
}

.get-insuracne-two-shape-2 img {
  width: auto;
}

.get-insuracne-two__img {
  position: relative;
  display: block;
  border-radius: 50%;
  margin-left: -139px;
  margin-right: 102px;
}

.get-insuracne-two__img img {
  width: 100%;
  border-radius: 50%;
}

.get-insuracne-two__right {
  position: relative;
  display: block;
}

.get-insuracne-two__right .section-title {
  margin-bottom: 38px;
}

.get-insuracne-two__tab {
  position: relative;
  display: block;
}

.get-insuracne-two__tab-box {
  position: relative;
  display: block;
  z-index: 2;
}

.get-insuracne-two__inner {
  position: relative;
  display: block;
}

.get-insuracne-two__tab-left {
  position: relative;
  display: block;
  max-width: 160px;
  width: 100%;
  float: left;
}

.get-insuracne-two__tab-box .tab-buttons {
  position: relative;
  display: block;
}

.get-insuracne-two__tab-box .tab-buttons .tab-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
}

.get-insuracne-two__tab-box .tab-buttons .tab-btn+.tab-btn {
  margin-top: 10px;
}

.get-insuracne-two__tab-btn-content {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dfe3e7;
  border-radius: var(--insur-bdr-radius);
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 30px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.get-insuracne-two__tab-box .tab-buttons .tab-btn.active-btn .get-insuracne-two__tab-btn-content {
  border: 1px solid var(--insur-base);
}

.get-insuracne-two__tab-btn-content:before {
  position: absolute;
  top: 50%;
  right: -6px;
  content: "";
  border-top: 8px solid transparent;
  border-left: 6px solid var(--insur-base);
  border-bottom: 8px solid transparent;
  transform: translateY(-50%) scale(0);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.get-insuracne-two__tab-box .tab-buttons .tab-btn.active-btn .get-insuracne-two__tab-btn-content:before {
  transform: translateY(-50%) scale(1);
}

.get-insuracne-two__tab-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.get-insuracne-two__tab-icon img {
  width: auto;
}

.get-insuracne-two__tab-text-box {
  margin-left: 14px;
}

.get-insuracne-two__tab-text {
  font-size: 14px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 17px;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insuracne-two__tab-box .tabs-content {
  position: relative;
  display: block;
}

.get-insuracne-two__tab-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}

.get-insuracne-two__tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.get-insuracne-two__tab-right {
  position: relative;
  display: block;
  max-width: 510px;
  width: 100%;
  padding-left: 40px;
  float: right;
}

.get-insuracne-two__content {
  position: relative;
  display: block;
}

.get-insuracne-two__form {
  position: relative;
  display: block;
}

.get-insuracne-two__content-box {
  position: relative;
  display: block;
}

.get-insuracne-two__input-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.get-insuracne-two__input-box input[type="text"],
.get-insuracne-two__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: 0;
  background-color: var(--insur-extra);
  outline: none;
  font-size: 16px;
  color: var(--insur-gray);
  font-weight: 400;
  padding: 0 30px 0;
  border-radius: var(--insur-bdr-radius);
}

.get-insuracne-two__input-box .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 100% !important;

}

.get-insuracne-two__input-box .bootstrap-select>.dropdown-toggle::after {
  display: none;
}

.get-insuracne-two__input-box .bootstrap-select>.dropdown-toggle {
  position: relative;
  height: 60px;
  outline: none !important;
  border-radius: var(--insur-bdr-radius);
  border: 0;
  background-color: var(--insur-extra) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--insur-gray) !important;
  font-size: 16px;
  line-height: 60px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}

.get-insuracne-two__input-box .bootstrap-select>.dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 14px;
  color: var(--insur-gray);
}

.get-insuracne-two__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 20px;
}

.get-insuracne-two__progress-single {
  position: relative;
  display: block;
}

.get-insuracne-two__progress-title {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 14px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insuracne-two__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--insur-extra);
  border-radius: 6px;
  border: 0
}

.get-insuracne-two__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 6px;
  background-image: -moz-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(0deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(0deg, #151d41 0%, #009289 100%);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.get-insuracne-two__progress .count-text {
  position: absolute;
  right: -30px;
  bottom: -8px;
  opacity: 0;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(22, 36, 61);
  width: 30px;
  height: 30px;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insuracne-two__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.get-insuracne-two__balance-box {
  position: absolute;
  top: 0;
  right: 0;
}

.get-insuracne-two__balance {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insuracne-two__content-bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-insuracne-two__btn {
  border: none;
}

.get-insuracne-two__content-bottom-text-box p {
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insuracne-two__content-bottom-text-box h5 {
  font-size: 16px;
  color: var(--insur-black);
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Process
--------------------------------------------------------------*/
.process {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.process .section-title {
  margin-bottom: 18px;
}

.process .section-sub-title-box {
  margin-left: 0;
}

.process__inner {
  position: relative;
  display: block;
  counter-reset: count;
}

.process-shape-1 {
  position: absolute;
  top: 0;
  left: 135px;
}

.process-shape-1 img {
  width: auto;
}

.process__single {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 40px 0;
  margin-bottom: 22px;
}

.process__icon-box {
  position: relative;
  display: block;
  height: 150px;
  width: 150px;
  margin: 0 auto;
}

.process__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 227, 231);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.1);
  height: 148px;
  width: 148px;
  margin: 0 auto;
  z-index: 1;
}

.process__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  background-color: var(--insur-base);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.process__single:hover .process__icon:before {
  transform: scaleX(1);
}

.process__icon span {
  position: relative;
  display: inline-block;
  font-size: 60px;
  color: var(--insur-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.process__single:hover .process__icon span {
  transform: scale(0.9);
  color: var(--insur-white);
}

.process__count {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--insur-primary);
  z-index: 2;
}

.process__count:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--insur-white);
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
  border-radius: 50%;
  counter-increment: count;
  content: counters(count, ".", decimal-leading-zero);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.process__single:hover .process__count:before {
  background-color: var(--insur-black);
}

.process__content {
  position: relative;
  display: block;
  margin-top: 21px;
}

.process__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 7px;
}

.process__text {
  font-size: 15px;
  letter-spacing: var(--insur-letter-spacing);
}

.process__bottom {
  position: relative;
  display: block;
  margin-top: 35px;
}

.process__contact {
  position: relative;
  display: block;
}

.process__input-box {
  position: relative;
  display: block;
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
}

.process__input-box input[type="text"] {
  height: 50px;
  width: 100%;
  border: 0;
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
  font-size: 16px;
  font-weight: 500;
  color: var(--insur-gray);
  letter-spacing: var(--insur-letter-spacing);
  padding-left: 50px;
  padding-right: 195px;
  outline: none;
}

.process__btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: 0;
  width: 158px;
  background-color: var(--insur-base);
  font-size: 14px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  border-top-right-radius: var(--insur-bdr-radius);
  border-bottom-right-radius: var(--insur-bdr-radius);
  text-align: center;
  transition: all 500ms ease;
}

.process__btn:hover {
  background-color: var(--insur-primary);
  color: var(--insur-black);
}

.process__single-2 {
  margin-top: 100px;
}

.process__single-2 .process__count {
  bottom: 0;
  right: 0;
  top: inherit;
  left: inherit;
}

.process__single-3 {
  margin-top: 50px;
}

.process__single-4 .process__count {
  bottom: 0;
  right: 0;
  top: inherit;
  left: inherit;
}

/*--------------------------------------------------------------
# Testimonial Two
--------------------------------------------------------------*/
.testimonial-two {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 90px;
  z-index: 1;
}

.testimonial-two-shape-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1432px;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  opacity: .30;
  z-index: -1;
}

.testimonial-two__left {
  position: relative;
  display: block;
  margin-right: 50px;
  z-index: 5;
}

.testimonial-two__left .section-title {
  margin-bottom: 28px;
}

.testimonial-two__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.testimonial-two__point-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 37px;
}

.testimonial-two__point {
  position: relative;
  display: block;
  float: left;
}

.testimonial-two__point li {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-two__point li+li {
  margin-top: 31px;
}

.testimonial-two__point li .icon {
  position: relative;
  height: 43px;
  width: 43px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-two__point li:hover .icon {
  background-color: var(--insur-base);
  color: var(--insur-white);
}

.testimonial-two__point li .text {
  margin-left: 14px;
}

.testimonial-two__point li .text p {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 26px;
}

.testimonial-two__point-two {
  margin-left: 33px;
}

.testimonial-two__right {
  position: relative;
  display: block;
  margin-left: 20px;
}

.testimonial-two__carousel {
  position: relative;
  display: block;
}

.testimonial-two__wrap {
  position: relative;
  display: block;
}

.testimonial-two__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 30px;
}

.testimonial-two__single:before {
  position: absolute;
  top: 0;
  left: -39px;
  content: "";
  border-top: 0px solid transparent;
  border-right: 40px solid #fff;
  border-bottom: 40px solid transparent;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

.testimonial-two__single-inner {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 42px;
  padding-bottom: 26px;
  background-color: var(--insur-white);
  border-top-right-radius: 20px;
  z-index: 1;
}

.testimonial-two-shape-2 {
  position: absolute;
  top: -20px;
  right: 0;
  mix-blend-mode: luminosity;
  opacity: .10;
  z-index: -1;
}

.testimonial-two-shape-2>img {
  width: auto;
}

.testimonial-two__content-box {
  position: relative;
  display: block;
  max-width: 296px;
  width: 100%;
  margin-left: 50px;
}

.testimonial-two__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 2px;
}

.testimonial-two__text-2 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.testimonial-two__client-review {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50px;
  right: 55px;
}

.testimonial-two__client-review i {
  font-size: 15px;
  color: #fab600;
}

.testimonial-two__client-review i+i {
  margin-left: 2px;
}

.testimonial-two__founder-box {
  position: relative;
  display: block;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--insur-base);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.testimonial-two__founder {
  position: relative;
  display: block;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 50px;
  padding-top: 23px;
  padding-bottom: 23px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-two__founder-text {
  font-size: 14px;
  color: var(--insur-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 14px;
}

.testimonial-two__client-img-box {
  position: absolute;
  bottom: 20px;
  right: 30px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: var(--insur-white);
  padding: 10px;
  z-index: 1;
}

.testimonial-two__client-img {
  position: relative;
  display: block;
  width: 131px;
  height: 131px;
  padding: 6px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(-29deg, #151d41 0%, #009289 100%);
}

.testimonial-two__client-img>img {
  width: 100% !important;
  border-radius: 50%;
  border: 6px solid var(--insur-white);
}

.testimonial-two__quote {
  position: absolute;
  top: 50%;
  left: -28px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-two__quote>img {
  width: auto !important;
}

.testimonial-two__founder-shape {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.testimonial-two__founder-shape>img {
  width: auto;
}

.testimonial-two__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-two__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-two__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}


.testimonial-two__carousel.owl-carousel .owl-dots {
  position: absolute;
  right: -185px;
  top: 265px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 10px;
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--insur-white);
  margin-top: 0px !important;
  transform: rotate(-90deg);
}

.testimonial-two__carousel.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 100px;
  border-radius: 5px;
  background-color: transparent;
  margin: 0;
  padding: 0 !important;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.testimonial-two__carousel.owl-carousel .owl-dot.active {
  background-color: var(--insur-primary);
  opacity: 1;
}

.testimonial-two__carousel.owl-carousel .owl-dot:focus {
  outline: none;
}

.testimonial-two__carousel.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

/*--------------------------------------------------------------
# News Two
--------------------------------------------------------------*/
.news-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.news-two .section-sub-title-box {
  margin-left: 0;
}

.news-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.news-two__img {
  position: relative;
  display: block;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  overflow: hidden;
  z-index: 1;
}

.news-two__img:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  background-color: rgba(var(--insur-primary-rgb), .50);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  z-index: 1;
}

.news-two__single:hover .news-two__img:before {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}

.news-two__img img {
  width: 100%;
  border-top-left-radius: var(--insur-bdr-radius);
  border-top-right-radius: var(--insur-bdr-radius);
  transition: all 500ms ease;
}

.news-two__single:hover .news-two__img img {
  transform: scale(1.05);
}

.news-two__arrow-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.news-two__arrow {
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--insur-white);
  font-size: 15px;
  opacity: 0;
  transform: translateY(70px);
  transition: all 500ms ease;
}

.news-two__single:hover .news-two__arrow {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 500ms;
}

.news-two__arrow:hover {
  border: 2px solid var(--insur-primary);
  color: var(--insur-primary);
}

.news-two__content {
  position: relative;
  display: block;
  border-bottom-left-radius: var(--insur-bdr-radius);
  border-bottom-right-radius: var(--insur-bdr-radius);
  border: 1px solid var(--insur-bdr-color);
  background-color: var(--insur-white);
  border-top: 0;
  padding: 33px 40px 40px;
  transition: all 500ms ease;
}

.news-two__single:hover .news-two__content {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.news-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 23px;
}

.news-two__title a {
  color: var(--insur-black);
  transition: all 500ms ease;
}

.news-two__single:hover .news-two__title a {
  color: var(--insur-base);
}

.news-two__client {
  position: relative;
  display: flex;
  align-items: center;
}

.news-two__client-img {
  position: relative;
  display: inline-block;
}

.news-two__client-img img {
  border: 2px solid var(--insur-primary);
  border-radius: 50%;
}

.news-two__client-content {
  margin-left: 15px;
}

.news-two__client-content p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 24px;
}

.news-two__client-content h5 {
  font-size: 14px;
  color: var(--insur-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
}

.news-two__tag {
  position: absolute;
  top: -20px;
  right: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 14px 20px;
  border-radius: var(--insur-bdr-radius);
  z-index: 2;
}

.news-two__tag p {
  font-size: 12px;
  color: var(--insur-black);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
}

.news-two__tag p i {
  font-size: 12px;
  padding-right: 3px;
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 420px;
  width: 100%;
  mix-blend-mode: luminosity;
}


/*--------------------------------------------------------------
# Feature Three
--------------------------------------------------------------*/
.feature-three {
  position: relative;
  display: block;
  padding: 0 0 90px;
  margin-top: -30px;
  z-index: 2;
}

.feature-three-shape {
  position: absolute;
  top: 30px;
  right: -30px;
  z-index: -1;
}

.feature-three-shape img {
  width: auto;
}

.feature-three__single {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
  border-radius: var(--insur-bdr-radius);
  padding: 30px 40px 30px;
  margin-bottom: 30px;
  z-index: 1;
}

.feature-three__single:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  border-radius: var(--insur-bdr-radius);
  background-color: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.feature-three__single:hover:after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.feature-three__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  width: 85px;
  background-color: var(--insur-extra);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-three__single:hover .feature-three__icon {
  background-color: var(--insur-primary);
}

.feature-three__icon span {
  position: relative;
  display: inline-block;
  font-size: 36px;
  color: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-three__single:hover .feature-three__icon span {
  color: var(--insur-white);
}

.feature-three__content {
  position: relative;
  margin-left: 20px;
}

.feature-three__sub-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 2px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-three__single:hover .feature-three__sub-title {
  color: var(--insur-white);
}

.feature-three__title {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 26px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-three__single:hover .feature-three__title {
  color: var(--insur-white);
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.about-three {
  position: relative;
  display: block;
  padding: 0 0 120px;
}

.about-three__left {
  position: relative;
  display: block;
  margin-right: 70px;
}

.about-three__img-box {
  position: relative;
  display: block;
}

.about-three__img {
  position: relative;
  display: block;
  z-index: 1;
}

.about-three__img>img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.about-three__shape-1 {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
}

.about-three__shape-1>img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.about-three__location {
  position: absolute;
  bottom: 135px;
  left: -68px;
  height: 150px;
  width: 190px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.about-three__location-text {
  font-size: 20px;
  color: var(--insur-white);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 24px;
}

.about-three__location-bg {
  position: absolute;
  top: 0;
  left: 8px;
  right: -8px;
  bottom: 0;
  z-index: -1;
}

.about-three__right {
  position: relative;
  display: block;
}

.about-three__right .section-title {
  margin-bottom: 34px;
}

.about-three__business-start {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.about-three__business-start-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.about-three__business-start-icon span {
  font-size: 60px;
  color: var(--insur-base);
  position: relative;
  display: inline-block;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.about-three__business-start:hover .about-three__business-start-icon span {
  transform: scale(.9);
}

.about-three__business-start-text-box {
  position: relative;
  margin-left: 30px;
}

.about-three__business-start-text {
  font-size: 24px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 36px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-three__business-start-text span {
  color: var(--insur-primary);
  position: relative;
  display: inline-block;
}

.about-three__business-start-text span:before {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-color: var(--insur-primary);
}

.about-three__text {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-three__bottom {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 41px;
}

.about-three__founder {
  position: relative;
  display: flex;
  align-items: center;
}

.about-three__founder-img {
  position: relative;
  display: flex;
  align-items: center;
}

.about-three__founder-img img {
  border-radius: 50%;
  border: 2px solid var(--insur-primary);
  width: auto;
}

.about-three__founder-content {
  position: relative;
  margin-left: 30px;
  top: 10px;
}

.about-three__founder-name {
  font-size: 20px;
  letter-spacing: var(--insur-letter-spacing);
  font-family: var(--insur-reey-font);
  line-height: 22px;
}

.about-three__founder-sub-title {
  font-size: 14px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 26px;
  margin-left: -16px;
}

.about-three__btn-box {
  margin-left: 20px;
}

/*--------------------------------------------------------------
# Services Three
--------------------------------------------------------------*/
.services-three {
  position: relative;
  display: block;
  padding: 0 0 90px;
  z-index: 1;
}

.services-three__inner {
  position: relative;
  display: block;
  z-index: 1;
}

.services-three-shape-1 {
  position: absolute;
  bottom: -126px;
  right: -160px;
  opacity: 0.05;
  -webkit-animation-name: shapeMover-2;
  animation-name: shapeMover-2;
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
}

.services-three-shape-1 img {
  width: auto;
}

.services-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.services-three .section-title {
  margin-bottom: 0;
}

.services-three__img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
  overflow: hidden;
  z-index: 1;
}

.services-three__img::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgb(22, 36, 61);
  background: linear-gradient(0deg, rgba(22, 36, 61, 0.9164040616246498) 14%, rgba(22, 36, 61, 0.036852240896358524) 58%);
  z-index: 1;
}

.services-three__img:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--insur-primary-rgb), .30);
  transition: all 500ms ease;
  transform: translate3d(0px, -100%, 0px);
}

.services-three__single:hover .services-three__img:after {
  transform: translate3d(0px, 0, 0px);
}

.services-three__img img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__single:hover .services-three__img img {
  transform: scale(1.05);
}

.services-three__content {
  position: absolute;
  bottom: 25px;
  left: 30px;
  z-index: 2;
}

.services-three__title {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

.services-three__title a {
  color: var(--insur-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-three__single:hover .services-three__title a {
  color: var(--insur-primary);
}

.services-three__get-quote {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
  padding: 39px 60px 50px;
}

.services-three__get-quote-sub-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 33px;
}

.services-three__get-quote-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 26px;
}

.services-three__get-quote-btn {
  padding: 12px 30px 12px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Benefits
--------------------------------------------------------------*/
.benefits {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 110px;
  z-index: 1;
}

.benefits-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 886px;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  z-index: 1;
}

.benefits-bg-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1424px;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  opacity: .30;
  z-index: -1;
}

.benefits__left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.benefits__left .section-title {
  margin-bottom: 30px;
}

.benefits__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.benefits__point-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 41px;
}

.benefits__point {
  position: relative;
  display: block;
  float: left;
}

.benefits__point li {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--insur-white);
  border-radius: var(--insur-bdr-radius);
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 30px;
  padding-right: 35px;
  margin-bottom: 10px;
  transition: all 500ms ease;
}

.benefits__point li:hover {
  background-color: var(--insur-base);
}

.benefits__point li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: var(--insur-base);
  border-radius: 50%;
  color: var(--insur-white);
  font-size: 8px;
  transition: all 500ms ease;
}

.benefits__point li:hover .icon {
  background-color: var(--insur-primary);
  color: var(--insur-base);
}

.benefits__point li .text {
  margin-left: 10px;
}

.benefits__point li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  transition: all 500ms ease;
}

.benefits__point li:hover .text p {
  color: var(--insur-white);
}

.benefits__point-two {
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Testimonial Three
--------------------------------------------------------------*/
.testimonial-three {
  position: relative;
  display: block;
  padding: 120px 0 170px;
}

.testimonial-three__top {
  position: relative;
  display: block;
  margin-bottom: 108px;
}

.testimonial-three__left {
  position: relative;
  display: block;
}

.testimonial-three__left .section-title {
  margin-bottom: 0px;
}

.testimonial-three__right {
  position: relative;
  display: block;
  margin-left: 100px;
  margin-top: 16px;
}

.testimonial-three__right-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.testimonial-three__bottom {
  position: relative;
  display: block;
}

.testimonial-three__carousel {
  position: relative;
  display: block;
}

.testimonial-three__single {
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid var(--insur-bdr-color);
  border-radius: 20px;
  padding: 108px 48px 51px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-three__single:hover {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  transform: translateY(-10px);
}

.testimonial-three__client-img-box {
  position: absolute;
  top: -73px;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: var(--insur-white);
  padding: 10px;
  z-index: 1;
}

.testimonial-three__client-img {
  position: relative;
  display: block;
  width: 131px;
  height: 131px;
  padding: 6px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -webkit-linear-gradient(-29deg, #151d41 0%, #009289 100%);
  background-image: -ms-linear-gradient(-29deg, #151d41 0%, #009289 100%);
}

.testimonial-three__client-img>img {
  width: 100% !important;
  border-radius: 50%;
  border: 6px solid var(--insur-white);
}

.testimonial-three__quote {
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-three__quote>img {
  width: auto !important;
}

.testimonial-three__star {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-three__star i {
  font-size: 15px;
  color: #fab600;
}

.testimonial-three__text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  padding-top: 23px;
  padding-bottom: 23px;
}

.testimonial-three__client {
  position: relative;
  display: block;
}

.testimonial-three__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.testimonial-three__client-sub-title {
  font-size: 14px;
  color: var(--insur-base);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.testimonial-three__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-three__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;

}

.testimonial-three__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-three__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.testimonial-three__carousel.owl-carousel .owl-dots {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  margin: 0 auto !important;
  border-radius: 5px;
  height: 10px;
  background-color: var(--insur-extra);
}

.testimonial-three__carousel.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  margin: 0;
  padding: 0 !important;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.testimonial-three__carousel.owl-carousel .owl-dot.active {
  background-color: var(--insur-primary);
  opacity: 1;
}

.testimonial-three__carousel.owl-carousel .owl-dot:focus {
  outline: none;
}

.testimonial-three__carousel.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

/*--------------------------------------------------------------
# Download
--------------------------------------------------------------*/
.download {
  position: relative;
  display: block;
  background-color: var(--insur-base);
  padding: 120px 0 0;
  overflow: hidden;
  z-index: 1;
}

.download-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  width: 828px;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-position: top center;
  opacity: 0.08;
  z-index: -1;
}

.download-shape-1 {
  position: absolute;
  top: -240px;
  left: 0;
}

.download-shape-1 img {
  width: auto;
}

.download-shape-2 {
  position: absolute;
  bottom: 87px;
  left: 0;
}

.download-shape-2 img {
  width: auto;
}

.download__left {
  position: relative;
  display: block;
  margin-top: 9px;
}

.download__sub-title {
  font-size: 18px;
  color: var(--insur-white);
  letter-spacing: var(--insur-letter-spacing);
}

.download__title {
  font-size: 50px;
  color: var(--insur-white);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: var(--insur-letter-spacing);
  margin-top: 7px;
  margin-bottom: 47px;
}

.download__apps {
  position: relative;
  display: flex;
  align-items: center;
}

.download__app-one {
  position: relative;
  display: block;
  max-width: 215px;
  width: 100%;
}

.download__app-one>a {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--insur-white);
  border-radius: var(--insur-bdr-radius);
  padding: 9px 40px 18px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.download__app-one:hover>a {
  background-color: var(--insur-primary);
}

.download__app-one>a>i {
  font-size: 20px;
  color: var(--insur-black);
  position: relative;
  top: 6px;
}

.download__app-one>a>p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 700;
  margin-left: 15px;
  line-height: 20px;
}

.download__app-one>a>p>span {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.download__app-one--two {
  margin-left: 10px;
}

.download__app-one--two>a {
  background-color: var(--insur-black);
}

.download__app-one--two>a>i {
  color: var(--insur-white);
}

.download__app-one--two>a>p {
  color: var(--insur-white);
}

.download__right {
  position: relative;
  display: block;
  margin-left: 58px;
}

.download__img {
  position: relative;
  display: block;
  top: 1px;
}

.download__img>img {
  width: 100%;
}

.download__badge {
  position: absolute;
  top: 10px;
  left: -98px;
}

.download__badge>img {
  width: auto;
}

/*--------------------------------------------------------------
# We Provide
--------------------------------------------------------------*/
.we-provide {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 120px;
  z-index: 1;
}

.we-provide-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  opacity: .20;
  z-index: -1;
}

.we-provide .section-sub-title-box {
  margin-left: 0;
}

.we-provide__tab {
  position: relative;
  display: block;
}

.we-provide__tab-box {
  position: relative;
  display: block;
  z-index: 2;
}

.we-provide__tab-btn-box {
  position: relative;
  display: block;
  margin-right: -30px;
}

.we-provide__tab-box .tab-buttons {
  position: relative;
  display: block;
  background-color: var(--insur-white);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 18px;
  z-index: 1;
}

.we-provide__tab-box .tab-buttons:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  width: 6px;
  background-color: var(--insur-bdr-color);
  z-index: -1;
}

.we-provide__tab-box .tab-buttons .tab-btn {
  position: relative;
  display: block;
  border-bottom: 2px solid var(--insur-bdr-color);
}

.we-provide__tab-box .tab-buttons .tab-btn.active-btn {
  border-bottom: 2px solid transparent;
}

.we-provide__tab-box .tab-buttons .tab-btn:last-child {
  border-bottom: 0;
}

.we-provide__tab-box .tab-buttons .tab-btn+.tab-btn {
  margin-left: 0px;
}

.we-provide__tab-box .tab-buttons .tab-btn span {
  position: relative;
  display: block;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 47px;
  max-width: 282px;
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  z-index: 1;
}

.we-provide__tab-box .tab-buttons .tab-btn.active-btn span {
  color: var(--insur-white);
}

.we-provide__tab-box .tab-buttons .tab-btn span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  content: "";
  background-color: var(--insur-black);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
}

.we-provide__tab-box .tab-buttons .tab-btn.active-btn span:before {
  height: 100%;
}

.we-provide__tab-box .tab-buttons .tab-btn span:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  content: "";
  background-color: var(--insur-primary);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: scaleY(0);
  z-index: -1;
}

.we-provide__tab-box .tab-buttons .tab-btn.active-btn span:after {
  transform: scaleY(1);
}

.we-provide__tab-main-content {
  position: relative;
  display: block;
  margin-left: 30px;
}

.we-provide__tab-box .tabs-content {
  position: relative;
  display: block;
}

.we-provide__tab-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}

.we-provide__tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.we-provide__tab-main-content-inner {
  position: relative;
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border-radius: var(--insur-bdr-radius);
  padding-left: 60px;
  padding-right: 50px;
  padding-top: 49px;
  padding-bottom: 49px;
  z-index: 1;
}

.we-provide-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .10;
  z-index: -1;
}

.we-provide-shape-1 img {
  width: auto;
}

.we-provide__tab-main-content-left {
  position: relative;
  display: block;
  max-width: 396px;
  width: 100%;
}

.we-provide__tab-main-content-icon {
  position: relative;
  display: block;
}

.we-provide__tab-main-content-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--insur-base);
}

.we-provide__tab-main-content-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  margin-top: 15px;
  margin-bottom: 13px;
}

.we-provide__tab-main-content-text {
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.we-provide__tab-main-content-points {
  position: relative;
  display: block;
  margin-top: 16px;
}

.we-provide__tab-main-content-points li {
  position: relative;
  display: flex;
  align-items: center;
}

.we-provide__tab-main-content-points li+li {
  margin-top: 8px;
}

.we-provide__tab-main-content-points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: var(--insur-primary);
  font-size: 9px;
  color: var(--insur-white);
  border-radius: 50%;
}

.we-provide__tab-main-content-points li .text {
  margin-left: 10px;
}

.we-provide__tab-main-content-points li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 700;
}

.we-provide__tab-main-content-right {
  position: relative;
  display: block;
  margin-left: 64px;
}

.we-provide__tab-main-content-right-img {
  position: relative;
  display: block;
}

.we-provide__tab-main-content-right-img img {
  width: auto;
  border-radius: var(--insur-bdr-radius);
}

/*--------------------------------------------------------------
# News Three
--------------------------------------------------------------*/
.news-three {
  position: relative;
  display: block;
  padding: 0 0 90px;
}

.news-three .section-sub-title-box {
  margin-left: 0;
}

.news-three__single {
  position: relative;
  display: block;
  border: 1px solid var(--insur-bdr-color);
  border-radius: var(--insur-bdr-radius);
  padding: 59px 59px 55px;
  overflow: hidden;
  transition: all 500ms ease;
  margin-bottom: 30px;
  z-index: 1;
}

.news-three__single:hover {
  border: 1px solid transparent;
}

.news-three-bg {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--insur-bdr-radius);
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.news-three-bg:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-radius: var(--insur-bdr-radius);
  background-color: rgba(var(--insur-primary-rgb), .70);
}

.news-three__single:hover .news-three-bg {
  transform: scaleY(1.0);
}

.news-three__client-info {
  position: relative;
  display: flex;
  align-items: center;
}

.news-three__client-img {
  position: relative;
  display: inline-block;
}

.news-three__client-img img {
  border: 2px solid var(--insur-primary);
  border-radius: 50%;
}

.news-three__client-content {
  position: relative;
  display: block;
  margin-left: 15px;
}

.news-three__client-content p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 24px;
  transition: all 500ms ease;
}

.news-three__single:hover .news-three__client-content p {
  color: var(--insur-white);
}

.news-three__client-content p i {
  color: var(--insur-primary);
  padding-right: 5px;
  transition: all 500ms ease;
}

.news-three__single:hover .news-three__client-content p i {
  color: var(--insur-white);
}

.news-three__client-content h5 {
  font-size: 14px;
  color: var(--insur-base);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
  transition: all 500ms ease;
}

.news-three__single:hover .news-three__client-content h5 {
  color: var(--insur-white);
}

.news-three__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.news-three__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-three__title a {
  color: var(--insur-black);
  transition: all 500ms ease;
}

.news-three__single:hover .news-three__title a {
  color: var(--insur-white);
}

.news-three__arrow {
  position: relative;
  display: block;
  margin-top: 27px;
}

.news-three__arrow a {
  font-size: 16px;
  color: var(--insur-black);
  display: flex;
  align-items: center;
  transition: all 500ms ease;
}

.news-three__single:hover .news-three__arrow a {
  color: var(--insur-white);
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  padding: 117px 0 113px;
  background-color: var(--insur-extra);
  z-index: 1;
  min-height: 60vh;
}

.page-header-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1221px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.page-header-bg:before {
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  width: 250px;
  content: "";
  background-image: -moz-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(243, 246, 248) 0%, rgba(243, 246, 248, 0) 100%);
}

.page-header-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  mix-blend-mode: luminosity;
  opacity: .20;
}

.page-header-shape-1 img {
  width: auto;
}

.page-header__inner {
  position: relative;
  display: block;
  z-index: 15;
}

.page-header__inner h2 {
  font-size: 40px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.04em;
}

.thm-breadcrumb {
  position: relative;
  display: block;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  color: var(--insur-gray);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li+li {
  margin-left: 5px;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  color: var(--insur-gray);
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--insur-base);
}

/*--------------------------------------------------------------
# News Carousel Page
--------------------------------------------------------------*/
.news-carousel-page {
  position: relative;
  display: block;
  padding: 120px 0 133px;
}

.carousel-dot-style.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.carousel-dot-style.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.carousel-dot-style.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.carousel-dot-style.owl-carousel .owl-dots {
  position: absolute;
  bottom: -13px;
  left: 0px;
  right: 0;
  text-align: center;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-dot-style.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: rgba(var(--insur-base-rgb), 0.3);
  margin: 0px 5px;
  padding: 0px;
  transition: all 100ms linear;
  transition-delay: 0.1s;
}

.carousel-dot-style.owl-carousel .owl-dot.active {
  background-color: rgba(var(--insur-primary-rgb), 1);
}

.carousel-dot-style.owl-carousel .owl-dot:focus {
  outline: none;
}

.carousel-dot-style.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

/*--------------------------------------------------------------
# News Sidebar
--------------------------------------------------------------*/
.news-sidebar {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.news-sideabr__left {
  position: relative;
  display: block;
}

.news-sideabr__content {
  position: relative;
  display: block;
}

.news-sideabr__single {
  position: relative;
  display: block;
}

.news-sideabr__single+.news-sideabr__single {
  margin-top: 30px;
}

.news-sideabr__img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
}

.news-sideabr__img img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.news-sideabr__content-box {
  position: relative;
  display: block;
  margin-top: 24px;
}

.news-sideabr__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-sideabr__meta li+li {
  margin-left: 15px;
}

.news-sideabr__meta li a {
  font-size: 14px;
  color: var(--insur-gray);
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-sideabr__meta li a:hover {
  color: var(--insur-base);
}

.news-sideabr__meta li a i {
  color: var(--insur-base);
}

.news-sideabr__title {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 4px;
  margin-bottom: 12px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-sideabr__title a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-sideabr__title a:hover {
  color: var(--insur-base);
}

.news-sideabr__text {
  padding-bottom: 25px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-sideabr__btn {
  font-size: 12px;
  font-weight: 500;
  color: var(--insur-gray);
  letter-spacing: var(--insur-letter-spacing);
  padding: 7px 30px 7px;
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
}

.news-sideabr__bottom-box {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 50px 60px 45px;
  margin-top: 30px;
  border-radius: var(--insur-bdr-radius);
}

.news-sideabr__bottom-box-icon {
  margin-bottom: 21px;
}

.news-sideabr__bottom-box-text {
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-sideabr__delivering-services {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 50px 60px 47px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: var(--insur-bdr-radius);
}

.news-sideabr__delivering-services-icon {
  margin-bottom: 21px;
}

.news-sideabr__delivering-services-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

.news-sideabr__delivering-services-title a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-sideabr__delivering-services-title a:hover {
  color: var(--insur-base);
}

.news-sideabr__video-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.news-sideabr__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 97px;
  height: 97px;
  line-height: 97px;
  text-align: center;
  font-size: 23px;
  color: var(--insur-white);
  background-color: var(--insur-primary);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.news-sideabr__video-icon:hover {
  background-color: var(--insur-white);
  color: var(--insur-base);
}

.news-sideabr__video-icon:before {
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  content: "";
  border-radius: 50%;
  border: 1px solid var(--insur-white);
  opacity: 0.1;
  z-index: 1;
}

.news-sideabr__video-link .ripple,
.news-sideabr__video-icon .ripple:before,
.news-sideabr__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 125px;
  height: 125px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--insur-primary-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.news-sideabr__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.news-sideabr__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__single+.sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}

.sidebar__search-form input[type="search"] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--insur-base);
  color: var(--insur-white);
  font-size: 18px;
  font-weight: 400;
  padding-left: 50px;
  height: 80px;
  width: 100%;
  padding-right: 80px;
  border-radius: 10px;
}

.sidebar__search-form ::-webkit-input-placeholder {
  color: var(--insur-white);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--insur-white);
  opacity: 1;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--insur-white);
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: var(--insur-white);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--insur-white);
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--insur-white);
}

.sidebar__search-form button[type="submit"] {
  background-color: transparent;
  color: var(--insur-white);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 45px 30px 30px;
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
}

.sidebar__post .sidebar__title {
  margin-left: 20px;
}

.sidebar__post-list {
  margin: 0;
}

.sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 20px 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__post-list li:hover {
  background-color: var(--insur-white);
}

.sidebar__post-list li+li {
  margin-top: 10px;
}

.sidebar__post-image {
  margin-right: 20px;
}

.sidebar__post-image>img {
  width: 70px;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__post-content {
  position: relative;
}

.sidebar__post-content h3 {
  font-size: 16px;
  margin: 0;
  line-height: 26px;
}

.sidebar__post-content-meta {
  font-size: 14px;
  font-weight: 500;
  color: var(--insur-gray) !important;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post-content-meta i {
  color: var(--insur-base);
  font-size: 14px;
  padding-right: 1px;
}

.sidebar__post-content h3 a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: 1px;
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.sidebar__category {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 45px 30px 35px;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__category .sidebar__title {
  padding-left: 20px;
  margin-bottom: 11px;
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li+li {
  margin-top: 8px;
}

.sidebar__category-list li a {
  color: var(--insur-gray);
  font-size: 16px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 7px 20px 7px;
  border-radius: var(--insur-bdr-radius);
  font-weight: 500;
}

.sidebar__category-list li a:hover {
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: var(--insur-base);
}

.sidebar__category-list li.active a {
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: var(--insur-base);
}

.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--insur-base);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}

.sidebar__category-list li a:hover span {
  color: var(--insur-base);
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}

.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: var(--insur-base);
}

.sidebar__tags {
  position: relative;
  display: block;
  background: var(--insur-extra);
  padding: 45px 45px 50px;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}

.sidebar__tags-list a {
  font-size: 14px;
  color: var(--insur-gray);
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: var(--insur-white);
  display: inline-block;
  padding: 7px 20px 7px;
  margin-left: 5px;
  font-weight: 500;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__tags-list a+a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__tags-list a:hover {
  color: var(--insur-white);
  background: var(--insur-base);
}

.sidebar__comments {
  position: relative;
  display: block;
  background: var(--insur-extra);
  padding: 45px 50px 43px;
  border-radius: var(--insur-bdr-radius);
}

.sidebar__comments .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}

.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}

.sidebar__comments-list li+li {
  margin-top: 23px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--insur-white);
  border-radius: 50%;
  font-size: 15px;
  color: var(--insur-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--insur-primary);
  color: var(--insur-white);
}

.sidebar__comments-text-box p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.sidebar__comments-text-box p span {
  color: var(--insur-base);
}

.sidebar__comments-text-box h5 {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  color: var(--insur-gray);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.news-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.news-details__left {
  position: relative;
  display: block;
}

.news-details__img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
}

.news-details__img img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.news-details__content {
  position: relative;
  display: block;
  margin-top: 24px;
}

.news-details__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-details__meta li+li {
  margin-left: 15px;
}

.news-details__meta li a {
  font-size: 14px;
  color: var(--insur-gray);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-details__meta li a:hover {
  color: var(--insur-base);
}

.news-details__meta li a i {
  color: var(--insur-base);
}

.news-details__title {
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 12px;
  margin-top: 4px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.news-details__text-1 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.news-details__text-2 {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  padding-top: 31px;
}

.news-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 56px;
  border-top: 1px solid var(--insur-bdr-color);
}

.news-details__bottom p {
  margin: 0;
}

.news-details__tags span {
  color: var(--insur-black);
  font-size: 20px;
  margin-right: 15px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.news-details__tags a {
  position: relative;
  color: var(--insur-gray);
  font-size: 14px;
  background-color: var(--insur-extra);
  display: inline-block;
  padding: 7px 20px 7px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  border-radius: var(--insur-bdr-radius);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-details__tags a:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
}

.news-details__tags a+a {
  margin-left: 6px;
}

.news-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--insur-base);
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--insur-base);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.news-details__social-list a:hover {
  color: var(--insur-white);
}

.news-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--insur-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.news-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.news-details__social-list a+a {
  margin-left: 10px;
}

.blgo-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}

.news-details__pagenation {
  position: relative;
  display: block;
}

.news-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: var(--insur-black);
  font-weight: 700;
  background-color: var(--insur-extra);
  line-height: 34px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: var(--insur-bdr-radius);
}

.news-details__pagenation li+li {
  margin-left: 30px;
}

.news-details__pagenation li:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-one__title,
.comment-form__title {
  margin: 0;
  color: var(--insur-black);
  font-size: 30px;
  margin-bottom: 52px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid var(--insur-bdr-color);
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.comment-one__content {
  position: relative;
  margin-left: 45px;
}

.comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  color: var(--insur-black);
  margin-bottom: 26px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.comment-one__content p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.comment-one__btn {
  padding: 7px 30px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--insur-extra);
  font-size: 14px;
  font-weight: 500;
  color: var(--insur-gray);
}

.comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
}

.comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

.comment-one__form .row {
  --bs-gutter-x: 20px;
}

.comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.comment-form__input-box input[type="text"],
.comment-form__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: none;
  background-color: var(--insur-extra);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--insur-gray);
  display: block;
  border-radius: var(--insur-bdr-radius);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.comment-form__input-box textarea {
  font-size: 14px;
  color: var(--insur-gray);
  height: 180px;
  width: 100%;
  background-color: var(--insur-extra);
  padding: 15px 30px 30px;
  border: none;
  border-radius: var(--insur-bdr-radius);
  outline: none;
  margin-bottom: 0px;
  font-weight: 500;
}

.comment-form__btn {
  border: none;
}

.comment-form__input-box.text-message-box {
  height: 180px;
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.contact-page__left {
  position: relative;
  display: block;
}

.contact-page__left .section-title {
  margin-bottom: 43px;
}

.contact-page__call-email {
  position: relative;
  display: block;
  padding-left: 70px;
}

.contact-page__call-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-bdr-color);
  border-radius: 50%;
  color: var(--insur-primary);
  font-size: 19px;
  top: 5px;
  left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__call-icon:hover {
  background-color: var(--insur-base);
  border: 2px solid var(--insur-base);
  color: var(--insur-white);
}

.contact-page__call-email-content {
  position: relative;
  display: block;
}

.contact-page__call-email-content h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.contact-page__call-number {
  position: relative;
  display: block;
  color: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__call-number:hover {
  color: var(--insur-primary);
}

.contact-page__email {
  position: relative;
  display: block;
  color: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__email:hover {
  color: var(--insur-primary);
}

.contact-page__location-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  font-weight: 500;
  padding-top: 36px;
}

.contact-page__right {
  position: relative;
  display: block;
}

.contact-page__form {
  position: relative;
  display: block;
}


/*--------------------------------------------------------------
# CTA Three
--------------------------------------------------------------*/
.cta-three {
  padding: 0 0 0;
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map-two {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  margin-top: -60px;
}

.google-map__two {
  position: relative;
  display: block;
  border: none;
  height: 420px;
  width: 100%;
  mix-blend-mode: luminosity;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
  position: relative;
  display: block;
  padding: 114px 0 90px;
}

.portfolio-filter {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 26px;
}

.portfolio-filter.style1 li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-filter.style1 li .filter-text {
  position: relative;
  display: inline-block;
  color: var(--insur-gray);
  font-size: 18px;
  font-weight: 500;
  display: block;
  cursor: pointer;
  transition: all 0.4s ease;
}

.portfolio-filter.style1 li .filter-text:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  content: "";
  background: var(--insur-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 0;
  transform: scale(0);
}

.portfolio-filter.style1 li .filter-text:hover:before,
.portfolio-filter.style1 li.active .filter-text:before {
  transform: scale(1.0);
}

.portfolio-filter.style1 li:hover .filter-text,
.portfolio-filter.style1 li.active .filter-text {
  color: var(--insur-black);
}

.portfolio-filter.style1 li .count {
  color: var(--insur-black);
  font-size: 12px;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  padding: 0 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  top: -4px;
}

.portfolio-filter.style1 li:hover .count,
.portfolio-filter.style1 li.active .count {
  color: var(--insur-black);
  opacity: 1;
}

.portfolio-filter.style1 li+li {
  margin-left: 30px;
}

.portfolio__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.portfolio__img {
  position: relative;
  display: block;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
}

.portfolio__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-radius: 20px;
  background-image: -moz-linear-gradient(90deg, rgb(17, 29, 50) 0%, rgba(17, 29, 50, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(17, 29, 50) 0%, rgba(17, 29, 50, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(17, 29, 50) 0%, rgba(17, 29, 50, 0) 100%);
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: top center;
  z-index: 1;
}

.portfolio__single:hover .portfolio__img:before {
  transform: scale(1, 1);
  transform-origin: bottom center;
}

.portfolio__img img {
  width: 100%;
  border-radius: 20px;
}

.portfolio__plus {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 50px;
  width: 50px;
  background-color: var(--insur-primary);
  border-radius: var(--insur-bdr-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.portfolio__single:hover .portfolio__plus {
  transform: scale(1);
  transition-delay: 500ms;
}

.portfolio__plus a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--insur-white);
}

.portfolio__content {
  position: absolute;
  bottom: 38px;
  left: 40px;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.portfolio__single:hover .portfolio__content {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.portfolio__sub-title {
  font-size: 14px;
  color: var(--insur-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
  opacity: .6;
}

.portfolio__title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.portfolio__title a {
  color: var(--insur-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio__title a:hover {
  color: var(--insur-primary);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.portfolio-details__img {
  position: relative;
  display: block;
  border-radius: 20px;
}

.portfolio-details__img img {
  width: 100%;
  border-radius: 20px;
}

.portfolio-details__content {
  position: relative;
  display: block;
  padding-top: 50px;
}

.portfolio-details__content-left {
  position: relative;
  display: block;
  margin-top: -4px;
}

.portfolio-details__title {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 34px;
  margin-bottom: 24px;
}

.portfolio-details__text-1 {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  padding-bottom: 34px;
}

.portfolio-details__text-2 {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.portfolio-details__content-right {
  position: relative;
  display: block;
}

.portfolio-details__details-box {
  position: relative;
  border-radius: var(--insur-bdr-radius);
  background-color: var(--insur-extra);
  padding: 42px 50px 50px;
}

.portfolio-details__details-list {
  position: relative;
  display: block;
}

.portfolio-details__details-list li {
  position: relative;
  display: block;
}

.portfolio-details__details-list li+li {
  margin-top: 32px;
}

.portfolio-details__client {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.portfolio-details__name {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.portfolio-details__social {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 9px;
}

.portfolio-details__social a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--insur-base);
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--insur-base);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.portfolio-details__social a:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
}

.portfolio-details__social a+a {
  margin-left: 10px;
}

.projectc-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid var(--insur-bdr-color);
  border-bottom: 1px solid var(--insur-bdr-color);
  padding: 30px 0;
  margin-top: 109px;
}

.projectc-details__pagination {
  position: relative;
  display: block;
}

.projectc-details__pagination li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.projectc-details__pagination li.next {
  float: left;
  position: relative;
}

.projectc-details__pagination li a {
  font-size: 14px;
  color: var(--insur-gray);
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.projectc-details__pagination li a:hover {
  color: var(--insur-base);
}

.projectc-details__pagination li p {
  color: var(--insur-black);
  font-weight: 700;
  line-height: 18px;
  letter-spacing: var(--insur-letter-spacing);
  margin-top: 3px;
}

.projectc-details__pagination li span {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: var(--insur-letter-spacing);
}

.projectc-details__pagination li.next i {
  position: relative;
  height: 50px;
  width: 50px;
  border: 2px solid rgba(var(--insur-primary-rgb), .20);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: rgba(var(--insur-primary-rgb), .20);
  font-size: 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}

.projectc-details__pagination li.previous {
  position: relative;
  float: right;
}

.projectc-details__pagination li.previous i {
  position: relative;
  height: 50px;
  width: 50px;
  border: 2px solid rgba(var(--insur-primary-rgb), .20);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: rgba(var(--insur-primary-rgb), .20);
  font-size: 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}

.projectc-details__pagination li a:hover i {
  color: rgba(var(--insur-primary-rgb), 1);
  border: 2px solid rgba(var(--insur-primary-rgb), 1);
}

.projectc-details__pagination li .content {
  position: relative;
  display: block;
  top: -3px;
}

.projectc-details__pagination li.next .content {
  text-align: left;
}

.projectc-details__pagination li.previous .content {
  text-align: right;
}

/*--------------------------------------------------------------
# Similar Portfolio
--------------------------------------------------------------*/
.similar-portfolio {
  position: relative;
  display: block;
  padding: 0 0 90px;
}

.similar-portfolio .section-sub-title-box {
  margin-left: 0;
}

/*--------------------------------------------------------------
# Portfolio Carousel
--------------------------------------------------------------*/
.portfolio-carousel-page {
  position: relative;
  display: block;
  padding: 120px 0 133px;
}

/*--------------------------------------------------------------
# Insurance Page One
--------------------------------------------------------------*/
.insurance-page-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

/*--------------------------------------------------------------
# Insurance Page Two
--------------------------------------------------------------*/
.insurance-page-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.insurance-page-two .services-two__single {
  background-color: transparent;
  border-radius: var(--insur-bdr-radius);
  border: 1px solid var(--insur-bdr-color);
}

.insurance-page-two .services-two__single:hover {
  border: 1px solid transparent;
  border-bottom: 3px solid var(--insur-primary);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07), inset 0px -3px 0px 0px rgba(12, 224, 255, 0.004);
}

.insurance-page-two .services-two__icon {
  background-color: var(--insur-extra);
}

.insurance-page-two .services-two__icon-box:before {
  background-color: var(--insur-extra);
}

.insurance-page-two .services-two__single:hover .services-two__icon-box:before {
  background-color: var(--insur-extra);
}

.insurance-page-two .services-two__title a {
  color: var(--insur-black);
}

/*--------------------------------------------------------------
# Why Choose Two
--------------------------------------------------------------*/
.why-choose-two {
  position: relative;
  display: block;
  padding: 120px 0 107px;
}

.why-choose-two__left {
  position: relative;
  display: block;
  margin-right: -23px;
}

.why-choose-two__left .section-title {
  margin-bottom: 27px;
}

.why-choose-two__text {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.why-choose-two__right {
  position: relative;
  display: block;
  margin-left: 60px;
}

.why-choose-two__list-box {
  position: relative;
  display: block;
  max-width: 510px;
  width: 100%;
}

.why-choose-two__list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.why-choose-two__list li {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.why-choose-two__single {
  position: relative;
  display: block;
  padding: 35px 20px 27px;
  border: 2px solid var(--insur-bdr-color);
  overflow: hidden;
}

.why-choose-two__list-icon {
  position: relative;
  display: block;
  margin-bottom: 9px;
}

.why-choose-two__list-icon span {
  font-size: 45px;
  color: var(--insur-base);
  position: relative;
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.why-choose-two__single:hover .why-choose-two__list-icon span {
  transform: scale(.9);
}

.why-choose-two__list-title-box {
  position: relative;
  display: block;
}

.why-choose-two__list-title-inner {
  position: relative;
  display: block;
  transform: translateY(0%);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: visible;
  opacity: 1;
}

.why-choose-two__single:hover .why-choose-two__list-title-inner {
  transform: translateY(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
}

.why-choose-two__list-title {
  font-size: 18px;
  color: var(--insur-black);
  line-height: 28px;
  letter-spacing: var(--insur-letter-spacing);
}

.why-choose-two__list-text-box {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  transform: translateY(-100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
}

.why-choose-two__single:hover .why-choose-two__list-text-box {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

.why-choose-two__list-text {
  font-size: 14px;
  color: var(--insur-gray);
  line-height: 24px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.benefits-two {
  position: relative;
  display: block;
  padding: 0 0 120px;
}

.benefits-two__left {
  position: relative;
  display: block;
  margin-right: 30px;
}

.benefits-two__img {
  position: relative;
  display: block;
}

.benefits-two__img img {
  width: 100%;
  border-radius: 20px;
}

.benefits-two__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.benefits-two__right .section-title {
  margin-bottom: 33px;
}

.benefits-two__points {
  position: relative;
  display: block;
}

.benefits-two__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.benefits-two__points li+li {
  margin-top: 14px;
}

.benefits-two__points li .icon {
  position: relative;
  height: 16px;
  width: 16px;
  background-color: var(--insur-primary);
  font-size: 10px;
  color: var(--insur-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1) rotateY(0deg);
  transform: scale(1) rotateY(0deg);
}

.benefits-two__points li:hover .icon {
  -webkit-transform: scale(0.9) rotateY(360deg);
  transform: scale(0.9) rotateY(360deg);
  background-color: var(--insur-base);
}

.benefits-two__points li .text {
  margin-left: 15px;
}

.benefits-two__points li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Feature Four
--------------------------------------------------------------*/
.feature-four {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
}

.feature-four__top {
  position: relative;
  display: block;
  padding: 100px 0 100px;
}

.feature-four__top-inner {
  position: relative;
  display: block;
  padding-left: 305px;
  min-height: 245px;
  margin-left: 70px;
}

.feature-four__top-call {
  position: absolute;
  top: 0;
  left: 0;
}

.feature-four__top-call-box {
  position: relative;
  border: 1px solid var(--insur-bdr-color);
  border-radius: 50%;
  height: 245px;
  width: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-four__top-call-box-two {
  position: relative;
  border: 1px solid var(--insur-bdr-color);
  border-radius: 50%;
  height: 175px;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-four__top-call-box-three {
  position: relative;
  border: 1px solid var(--insur-bdr-color);
  border-radius: 50%;
  height: 107px;
  width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-four__top-call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 2px solid var(--insur-primary);
  border-radius: 50%;
  color: var(--insur-primary);
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-four__top-call-icon:hover {
  border: 2px solid var(--insur-base);
  color: var(--insur-base);
}

.feature-four__top-call-number-box {
  position: absolute;
  top: 65px;
  left: calc(50% - 91px);
  max-width: 182px;
  width: 100%;
  background-color: var(--insur-base);
  text-align: center;
  border-radius: var(--insur-bdr-radius);
  padding: 4px 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 2;
}

.feature-four__top-call-number-box:hover {
  background-color: var(--insur-primary);
}

.feature-four__top-call-number-box:before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 6px solid var(--insur-base);
  transform: translateX(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-four__top-call-number-box:hover:before {
  border-top: 6px solid var(--insur-primary);
}

.feature-four__top-call-number-box a {
  font-size: 14px;
  color: var(--insur-white);
  letter-spacing: var(--insur-letter-spacing);
}

.feature-four__top-content {
  position: relative;
  display: block;
  top: 11px;
}

.feature-four__top-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: var(--insur-letter-spacing);
}

.feature-four__top-text {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  padding-bottom: 29px;
  padding-top: 20px;
}

.feature-four__bottom {
  position: relative;
  display: block;
  padding: 0 0 90px;
}

.feature-four__single {
  position: relative;
  display: block;
  background-color: var(--insur-white);
  border-radius: var(--insur-bdr-radius);
  padding: 50px 50px 45px;
  margin-bottom: 30px;
}

.feature-four__single-top {
  position: relative;
  display: flex;
  align-items: center;
}

.feature-four__icon {
  font-size: 40px;
  color: var(--insur-base);
  display: flex;
  align-items: center;
}

.feature-four__icon span {
  position: relative;
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.feature-four__single:hover .feature-four__icon span {
  transform: scale(0.9);
  color: var(--insur-primary);
}

.feature-four__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  margin-left: 20px;
}

.feature-four__text {
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  padding-top: 19px;
  padding-bottom: 34px;
}

.feature-four__points {
  position: relative;
  display: block;
}

.feature-four__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.feature-four__points li+li {
  margin-top: 5px;
}

.feature-four__points li .icon {
  height: 15px;
  width: 15px;
  background-color: var(--insur-base);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: var(--insur-white);
}

.feature-four__points li .text {
  margin-left: 10px;
}

.feature-four__points li .text p {
  color: var(--insur-black);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.feature-four__points-two {
  position: relative;
  display: block;
}

.feature-four__points-two li {
  position: relative;
  display: block;
}

.feature-four__points-two li+li {
  margin-top: 6px;
}

.feature-four__points-two li a {
  font-size: 16px;
  color: var(--insur-base);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  transition: all 500ms;
  -webkit-transition: all 500ms;
}

.feature-four__points-two li a:hover {
  color: var(--insur-primary);
}

/*--------------------------------------------------------------
# FaQ One
--------------------------------------------------------------*/
.faq-one {
  position: relative;
  display: block;
  padding: 120px 0 100px;
}

.faq-one .section-sub-title-box {
  margin-left: 0px;
}

.faq-one__single {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
# About Four
--------------------------------------------------------------*/
.about-four {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.about-four__left {
  position: relative;
  display: block;
  margin-right: 100px;
}

.about-four__img-box {
  position: relative;
  display: block;
}

.about-four__img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
}

.about-four__img>img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.about-four__img-two {
  position: absolute;
  right: -130px;
  bottom: -145px;
  border-radius: var(--insur-bdr-radius);
}

.about-four__img-two>img {
  width: auto;
  border-radius: var(--insur-bdr-radius);
}

.about-four__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-four__right .section-title {
  margin-bottom: 33px;
}

.about-four__text-1 {
  font-size: 22px;
  color: var(--insur-base);
  font-weight: 500;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
  padding-bottom: 9px;
}

.about-four__text-2 {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.about-four__founder {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 51px;
}

.about-four__founder-img {
  position: relative;
  display: flex;
  align-items: center;
}

.about-four__founder-img img {
  border-radius: 50%;
  border: 2px solid var(--insur-primary);
  width: auto;
}

.about-four__founder-content {
  position: relative;
  margin-left: 20px;
  top: 3px;
}

.about-four__founder-name {
  font-size: 24px;
  letter-spacing: var(--insur-letter-spacing);
  font-family: var(--insur-reey-font);
  line-height: 36px;
  margin-left: 20px;
}

.about-four__founder-sub-title {
  font-size: 16px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 26px;
  color: var(--insur-base);
}

/*--------------------------------------------------------------
# CTA Four
--------------------------------------------------------------*/
.cta-four {
  padding: 120px 0 120px;
  background-color: var(--insur-white);
  overflow: hidden;
}

.cta-four-shape-1 {
  position: absolute;
  top: -90px;
  right: -29px;
  mix-blend-mode: luminosity;
}

.cta-four-shape-1 img {
  width: auto;
  mix-blend-mode: luminosity;
  opacity: .15;
  ;
}

/*--------------------------------------------------------------
# Team Two
--------------------------------------------------------------*/
.team-two {
  padding: 0 0 90px;
}

/*--------------------------------------------------------------
# Team Page
--------------------------------------------------------------*/
.team-page {
  padding: 135px 0 70px;
}

.team-page .container {
  max-width: 1070px;
}

.team-page .row {
  --bs-gutter-x: 108px;
}

.team-page .team-one__single {
  margin-bottom: 43px;
}

/*--------------------------------------------------------------
# Team Page Carousel
--------------------------------------------------------------*/
.team-page-carousel {
  padding: 135px 0 133px;
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
}

.team-details__top {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.team-details__top-left {
  position: relative;
  display: block;
}

.team-details__top-img {
  position: relative;
  display: block;
  border-radius: var(--insur-bdr-radius);
}

.team-details__top-img img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.team-details__big-text {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: var(--insur-extra);
  letter-spacing: 0.35em;
  position: absolute;
  transform: rotate(-90deg);
  top: 209px;
  left: -319px;
}

.team-details__top-right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.team-details__top-content {
  position: relative;
  display: block;
  margin-top: -7px;
}

.team-details__top-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: var(--insur-letter-spacing);
  margin-top: 2px;
}

.team-details__top-title {
  font-size: 14px;
  color: var(--insur-gray);
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 24px;
}

.team-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 38px;
}

.team-details__social a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--insur-base);
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--insur-base);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.team-details__social a:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
}

.team-details__social a+a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 30px;
  color: var(--insur-base);
  line-height: 46px;
  letter-spacing: var(--insur-letter-spacing);
}

.team-details__top-text-2 {
  font-size: 18px;
  line-height: 34px;
  padding-top: 33px;
  padding-bottom: 35px;
  letter-spacing: var(--insur-letter-spacing);
}

.team-details__top-text-3 {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--insur-letter-spacing);
}

.team-details__bottom {
  position: relative;
  display: block;
  border-top: 1px solid var(--insur-bdr-color);
  padding-top: 112px;
  padding-bottom: 120px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 70px;
  margin-top: -2px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: var(--insur-letter-spacing);
}

.team-details__bottom-left-text {
  font-size: 18px;
  line-height: 34px;
  padding-top: 30px;
  letter-spacing: var(--insur-letter-spacing);
}


.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
}

.team-details__progress-single {
  position: relative;
  display: block;
}

.team-details__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--insur-black);
  margin-bottom: 6px;
  letter-spacing: var(--insur-letter-spacing);
}

.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--insur-extra);
  border-radius: 7px;
  margin-bottom: 12px;
}

.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 7px;
  background-color: var(--insur-primary);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.team-details__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--insur-gray);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# Message One
--------------------------------------------------------------*/
.message-one {
  position: relative;
  display: block;
  background-color: var(--insur-extra);
  padding: 120px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.message-one-shape-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.02;
  z-index: -1;
}

.message-one-shape-2 {
  position: absolute;
  top: -110px;
  left: 0;
  right: 0;
  height: 414px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  mix-blend-mode: luminosity;
  opacity: .15;
  z-index: -1;
}

.message-one .section-sub-title-box {
  margin-left: 0px;
}

.message-one .container {
  max-width: 770px;
  width: 100%;
}

.message-one .comment-form__btn {
  margin: 20px auto 0;
  display: block;
}

.message-one .comment-form__input-box input[type="text"],
.message-one .comment-form__input-box input[type="email"] {
  background-color: var(--insur-white);
}

.message-one .comment-form__input-box.text-message-box {
  height: 180px;
}

.message-one .comment-form__input-box textarea {
  background-color: var(--insur-white);
  height: 180px;
}

/*--------------------------------------------------------------
# Testimonial Page
--------------------------------------------------------------*/
.testimonial-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.testimonial-page .container {
  max-width: 1160px;
}

.testimonial-page .testimonial-one__single {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Testimonial Carousel Page
--------------------------------------------------------------*/
.testimonial-carousel-page {
  position: relative;
  display: block;
  padding: 120px 0 133px;
}

.testimonial-carousel-page .testimonial-one__single {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page--two {
  background-color: var(--insur-extra);
  overflow: hidden;
  z-index: 1;
}

.contact-page-two-shape-1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.02;
  z-index: -1;
}

.contact-page-two-shape-2 {
  position: absolute;
  top: -165px;
  left: 0;
  right: 0;
  height: 414px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  mix-blend-mode: luminosity;
  opacity: .15;
  z-index: -1;
}

.contact-page--two .contact-page__call-icon {
  background-color: var(--insur-white);
}

.contact-page--two .contact-page__call-icon:hover {
  background-color: var(--insur-base);
  border: 2px solid var(--insur-base);
  color: var(--insur-white);
}

.contact-page--two .comment-form__input-box input[type="text"],
.contact-page--two .comment-form__input-box input[type="email"] {
  background-color: var(--insur-white);
}

.contact-page--two .comment-form__input-box textarea {
  background-color: var(--insur-white);
}

/*--------------------------------------------------------------
## Error Page
--------------------------------------------------------------*/
.error-page {
  position: relative;
  display: block;
  padding: 81px 0 120px;
}

.error-page-shape-1 {
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center 53%;
  background-size: 100%;
}

@media (max-width: 991px) {
  .error-page-shape-1 {
    background-position: center 55%;

  }
}

@media (max-width: 767px) {
  .error-page-shape-1 {
    display: none;
  }
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}

.error-page__title-box {
  position: relative;
  display: block;
  margin-bottom: 192px;
}

@media (max-width: 991px) {
  .error-page__title-box {
    margin-bottom: 100px;
  }
}


@media (max-width: 767px) {
  .error-page__title-box {
    margin-bottom: 30px;
  }
}

.error-page__title {
  position: relative;
  display: inline-block;
  font-size: 280px;
  line-height: 280px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--insur-black);
}

.error-page__sub-title {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -16px;
}

.error-page__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  text-align: center;
}

.error-page__form {
  position: relative;
  display: block;
  margin: 42px auto 20px;
}

.error-page__form-input {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

.error-page__form input[type="search"] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--insur-extra);
  font-size: 18px;
  color: var(--insur-gray);
  font-weight: 400;
  letter-spacing: var(--insur-letter-spacing);
  padding-left: 50px;
  padding-right: 75px;
  border-radius: var(--insur-bdr-radius);
}

.error-page__form button[type="submit"] {
  background-color: transparent;
  color: var(--insur-black);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.pricing .section-title {
  margin-bottom: 38px;
}

.pricing .section-sub-title-box {
  margin-left: 0px;
}

.pricing__tab {
  position: relative;
  display: block;
}

.pricing__tab-box {
  position: relative;
  display: block;
  z-index: 2;
}

.pricing__tab-box .tab-buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.pricing__tab-box .tab-buttons .tab-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pricing__tab-box .tab-buttons .tab-btn:first-child span {
  border-top-left-radius: var(--insur-bdr-radius);
  border-bottom-left-radius: var(--insur-bdr-radius);
}

.pricing__tab-box .tab-buttons .tab-btn:last-child span {
  border-top-right-radius: var(--insur-bdr-radius);
  border-bottom-right-radius: var(--insur-bdr-radius);
}

.pricing__tab-box .tab-buttons .tab-btn span {
  position: relative;
  display: inline-block;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  font-size: 14px;
  color: var(--insur-black);
  background-color: var(--insur-extra);
  padding: 12px 30px 12px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 1;
}

.pricing__tab-box .tab-buttons .tab-btn.active-btn span {
  color: var(--insur-white);
  background-color: var(--insur-base);
}

.pricing__tab-box .tab-buttons .tab-btn span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  border-radius: 5px;
  content: "";
  background-color: var(--insur-base);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;
}

.pricing__tab-box .tab-buttons .tab-btn:first-child span:before {
  border-top-left-radius: var(--insur-bdr-radius);
  border-bottom-left-radius: var(--insur-bdr-radius);
}

.pricing__tab-box .tab-buttons .tab-btn:last-child span:before {
  border-top-right-radius: var(--insur-bdr-radius);
  border-bottom-right-radius: var(--insur-bdr-radius);
}

.pricing__tab-box .tab-buttons .tab-btn.active-btn span:before {
  height: 100%;
}

.pricing__tab-box .tabs-content {
  position: relative;
  display: block;
}

.pricing__tab-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}

.pricing__tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.pricing__main-content-box {
  position: relative;
  display: block;
}

.pricing__single {
  position: relative;
  display: block;
  border: 1px solid #dfe3e7;
  border-radius: var(--insur-bdr-radius);
  padding: 40px 40px 40px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.pricing__single:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border: 2px solid transparent;
  border-radius: var(--insur-bdr-radius);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing__single:hover:before {
  border: 2px solid var(--insur-base);
}

.pricing__single:hover {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pricing-shape-1 {
  position: absolute;
  bottom: 30px;
  right: 0;
  z-index: -1;
}

.pricing-shape-1 img {
  width: auto;
}

.pricing__single-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing__img img {
  width: auto;
}

.pricing__content {
  position: relative;
  display: block;
  text-align: right;
}

.pricing__content h3 {
  font-size: 60px;
  color: var(--insur-base);
  font-weight: 700;
  line-height: 60px;
  letter-spacing: var(--insur-letter-spacing);
}

.pricing__content p {
  color: var(--insur-primary);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.pricing__single-bottom {
  position: relative;
  display: block;
  margin-top: 34px;
}

.pricing__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
  margin-bottom: 26px;
}

.pricing__points {
  position: relative;
  display: block;
}

.pricing__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.pricing__points li+li {
  margin-top: 10px;
}

.pricing__points li .icon i {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--insur-primary);
}

.pricing__points li .text {
  margin-left: 20px;
}

.pricing__points li .text p {
  letter-spacing: var(--insur-letter-spacing);
}

.pricing__btn-box {
  position: relative;
  display: block;
  margin-top: 32px;
}

.pricing__btn {
  background-color: var(--insur-black);
}

.pricing__btn:before {
  background-color: var(--insur-base);
}


.pricing__btn:after {
  background-color: var(--insur-base);
}

.pricing__single:hover .pricing__btn:before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.pricing__single:hover .pricing__btn:after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}

/*--------------------------------------------------------------
# Boxed Version
--------------------------------------------------------------*/

body.boxed-wrapper {
  background-color: var(--ambed-extra, #f2f5f9);
}

.boxed-wrapper .page-wrapper {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
}

@media (min-width: 1531px) {
  body.boxed-wrapper .get-insurance__left {
    left: 120px;
  }
}


/* insur get balance updated css */

.get-insurance__progress-range .irs-single,
.get-insurance__progress-range .irs-from,
.get-insurance__progress-range .irs-to {
  display: none;
}

.get-insurance__progress-range .irs--flat {
  height: 29px;
}

.get-insurance__progress-range .irs--flat .irs-handle,
.get-insurance__progress-range .irs--flat .irs-line,
.get-insurance__progress-range .irs--flat .irs-bar {
  top: 0;
}

.get-insurance__progress-range .irs--flat .irs-line {
  height: 12px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #dfe3e7;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.get-insurance__progress-range .irs--flat .irs-bar {
  height: 12px;
  border-radius: 6px;
  background-image: linear-gradient(90deg, var(--insur-base) 0%, var(--insur-primary) 100%);

}

.get-insurance__progress-range .irs--flat .irs-handle {
  height: 29px;
  width: 29px;
  background-color: var(--insur-black);
  border: 2px solid #ffffff;
  border-radius: 50%;
  top: -9px;
}

.get-insurance__progress-range .irs--flat .irs-handle i {
  display: none;
}

.get-insurance__progress {
  margin-bottom: 33px;
}

/*--------------------------------------------------------------
# Product
--------------------------------------------------------------*/
.product {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.product__items {
  position: relative;
  display: block;
}

.product__showing-result {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.product__showing-text-box {
  position: relative;
  display: block;
}

.product__showing-text {
  font-size: 18px;
}

.product__showing-sort {
  position: relative;
  display: block;
  max-width: 340px;
  width: 100%;
}

.product__showing-sort .select-box .nice-select {
  position: relative;
  height: 70px;
  outline: none;
  border-radius: var(--insur-bdr-radius);
  border: 0;
  background-color: var(--insur-extra);
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--insur-gray);
  font-size: 14px;
  line-height: 70px;
  font-weight: 400;
  box-shadow: none;
  letter-spacing: var(--insur-letter-spacing);
}

.product__showing-sort .select-box .nice-select:after {
  position: absolute;
  right: 30px;
}

.product__all {
  position: relative;
  display: block;
}

.product__all-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
  z-index: 1;
}

.product__all-img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  z-index: 1;
}

.product__all-img:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  background-color: rgba(var(--insur-primary-rgb), .50);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  z-index: 1;
}

.product__all-single:hover .product__all-img:before {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}

.product__all-img img {
  width: 100%;
  border-radius: 20px;
  transition: all 500ms ease;
}

.product__all-single:hover .product__all-img img {
  transform: scale(1.05);
}

.product__all-btn-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg) scale(1);
  transition: all 600ms ease;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.product__all-single:hover .product__all-btn-box {
  transform: rotate(0deg) scale(1);
  visibility: visible;
  opacity: 1;
  transition-delay: 400ms;
}

.product__all-content {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 14px;
}

.product__all-title {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  margin-bottom: 2px;
  letter-spacing: var(--insur-letter-spacing);
}

.product__all-title a {
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.product__all-title a:hover {
  color: var(--insur-base);
}

.product__all-price {
  font-weight: 500;
}

.product__all-review {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}

.product__all-review i {
  font-size: 15px;
  color: var(--insur-primary);
}

.product__all-review i+i {
  margin-left: 5px;
}


.product__sidebar {
  position: relative;
  display: block;
}

.product__sidebar-single+.product__sidebar-single {
  margin-top: 30px;
}

.product__sidebar-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  margin-bottom: 22px;
  letter-spacing: var(--insur-letter-spacing);
}

.shop-search {
  position: relative;
  display: block;
}

.shop-search form {
  border-radius: 0px;
  position: relative;
  background-color: var(--insur-base);
  border-radius: var(--insur-bdr-radius);
}

.shop-search form input[type=search],
.shop-search form input[type=text] {
  width: 100%;
  height: 70px;
  background-color: var(--insur-base);
  border-radius: var(--insur-bdr-radius);
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  color: var(--insur-white);
  font-family: var(--insur-font);
  border: none;
  outline: none;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.shop-search form ::placeholder {
  color: inherit;
  opacity: 1;
}



.product__price-ranger {
  position: relative;
  padding: 27px 30px 26px;
  margin: 0;
  border: 1px solid var(--insur-bdr-color);
  border-radius: var(--insur-bdr-radius);
}

.product__price-ranger.price-ranger {
  margin-top: 7px;
  margin-bottom: 0px;
}

.product__price-ranger .price-ranger .ui-widget-content {
  background: var(--insur-extra);
  border: none;
  height: 6px;
  border-radius: 2px;
}

.product__price-ranger .price-ranger .ui-slider-handle {
  position: absolute;
  top: -5px;
  background: var(--insur-primary);
  border: 0;
  height: 14px;
  width: 14px !important;
  border-radius: 50%;
  margin-left: -2px;
  outline: medium none;
  cursor: pointer;
  z-index: 2;
}

.product__price-ranger .price-ranger .ui-slider .ui-slider-range {
  background: var(--insur-primary);
}

.product__price-ranger .price-ranger #slider-range {
  margin-left: 3px;
  margin-right: 0;
  margin-top: 0;
}

.product__price-ranger .price-ranger .ranger-min-max-block {
  position: relative;
  display: block;
  margin-top: 11px;
}

.product__price-ranger .price-ranger .ranger-min-max-block input {
  display: inline-block;
}

.product__price-ranger .price-ranger .ranger-min-max-block input[type="submit"] {
  position: relative;
  display: block;
  background: var(--insur-base);
  float: right;
  text-align: center;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 10px;
}

.product__price-ranger .price-ranger .ranger-min-max-block input[type="text"] {
  position: relative;
  display: inline-block;
  color: var(--insur-gray);
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  line-height: 30px;
  border: none;
  padding: 0;
  text-align: center;
}

.product__price-ranger .price-ranger .ranger-min-max-block span {
  position: relative;
  display: inline-block;
  color: var(--insur-gray);
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  left: -2px;
}


.shop-category {
  position: relative;
  display: block;
  border: 1px solid var(--insur-bdr-color);
  padding: 27px 15px 15px;
  border-radius: var(--insur-bdr-radius);
}

.shop-category .product__sidebar-title {
  margin-left: 15px;
  margin-bottom: 13px;
}

.shop-category ul {
  position: relative;
  display: block;
}

.shop-category ul li {
  position: relative;
  line-height: 24px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--insur-gray);
  margin-bottom: 7px;
}

.shop-category ul li:last-child {
  margin-bottom: 0;
}

.shop-category ul li a {
  position: relative;
  display: block;
  line-height: 24px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--insur-gray);
  font-weight: 500;
  border-radius: 0;
  transition: all 0.3s ease;
  z-index: 1;
  padding: 8px 15px 8px;
  border-radius: var(--insur-bdr-radius);
}

.shop-category ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
  z-index: -1;
  transform: scaleY(0.0);
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.shop-category ul li a:hover:before {
  transform: scaleY(1.0);
}

.shop-category ul li:hover a,
.shop-category ul li.active a {
  font-weight: 500;
  background: var(--insur-extra);
}

.shop-category ul li a:after {
  position: absolute;
  right: 15px;
  top: 5px;
  font-family: "Font Awesome 5 Free";
  content: "\f101";
  opacity: 1;
  font-size: 10px;
  line-height: 30px;
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 1;
}

.shop-category ul li a:hover:after,
.shop-category ul li.active a:after {
  color: var(--insur-base);
}


/*--------------------------------------------------------------
# Product Details
--------------------------------------------------------------*/
.product-details {
  position: relative;
  display: block;
  padding: 120px 0px 56px;
}

.product-details__img {
  position: relative;
  display: block;
  overflow: hidden;
}

.product-details__img img {
  width: 100%;
  border-radius: 20px;
}


.product-details__top {
  position: relative;
  display: block;
  margin-top: -8px;
}

.product-details__title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  margin: 0;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__title span {
  position: relative;
  display: inline-block;
  color: var(--insur-base);
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-left: 20px;
  letter-spacing: 0;
}

.product-details__reveiw {
  display: flex;
  align-items: center;
  margin-top: 22px;
  padding-bottom: 20px;
  margin-bottom: 31px;
  border-bottom: 1px solid var(--insur-bdr-color);
}

.product-details__reveiw i {
  font-size: 16px;
  color: var(--insur-primary);
}

.product-details__reveiw i+i {
  margin-left: 4px;
}

.product-details__reveiw span {
  position: relative;
  top: 1px;
  line-height: 1;
  font-size: 16px;
  color: var(--insur-gray);
  margin-left: 18px;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__content {
  position: relative;
  display: block;
}

.product-details__content-text1 {
  font-size: 18px;
  line-height: 34px;
  margin: 0;
  margin-bottom: 33px;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__content-text2 {
  font-size: 18px;
  line-height: 34px;
  margin: 0;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__quantity {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.product-details__quantity-title {
  margin: 0;
  color: var(--insur-black);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 20px;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__quantity .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 10px;
  height: 60px;
}

.product-details__quantity .quantity-box input {
  width: 98px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid #e0e4e8;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-family: var(--insur-font);
  padding-left: 30px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--insur-gray);
}

.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--insur-gray);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #fff;
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top: 1px solid #eeede5;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.product-details__buttons {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 40px;
}

.product-details__buttons-1 {
  position: relative;
  display: block;
}

.product-details__buttons-2 {
  position: relative;
  display: block;
  margin-left: 10px;
}

.product-details__buttons-2 .thm-btn {
  background-color: var(--insur-black);
}

.product-details__buttons-2 .thm-btn:before {
  background-color: var(--insur-base);
}

.product-details__buttons-2 .thm-btn:after {
  background-color: var(--insur-base);
}

.product-details__social {
  position: relative;
  display: flex;
  align-items: center;
}

.product-details__social .title {
  position: relative;
  display: block;
}

.product-details__social .title h3 {
  color: var(--insur-black);
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.product-details__social-link {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.product-details__social-link a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--insur-base);
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--insur-base);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.product-details__social-link a+a {
  margin-left: 10px;
}

.product-details__social-link a:hover {
  background-color: var(--insur-base);
  color: var(--insur-white);
}


/*--------------------------------------------------------------
# Product Description
--------------------------------------------------------------*/
.product-description {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 0px 0px 47px;
}

.product-description__title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 26px;
  letter-spacing: var(--insur-letter-spacing);
}

.product-description__text1 {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.product-description__tex2 {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  padding-top: 28px;
}

/*--------------------------------------------------------------
# Review One
--------------------------------------------------------------*/
.review-one {
  position: relative;
  display: block;
}

.comments-area {
  position: relative;
  display: block;
}

.review-one__title {
  position: relative;
  display: block;
  margin-bottom: 36px;
}

.review-one__title h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  letter-spacing: var(--insur-letter-spacing);
}


.comments-area .comment-box {
  position: relative;
  display: block;
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--insur-bdr-color);
}

.comments-area .comment {
  position: relative;
  display: flex;
}

.comments-area .comment-box .author-thumb {
  position: relative;
  display: block;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  overflow: hidden;
}

.comments-area .comment-box .author-thumb img {
  width: 100%;
}

.comments-area .comment-box .author-thumb figure {
  margin: 0;
}

.review-one__content {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-top: -7px;
  flex: 1;
}

.review-one__content-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.review-one__content-top .info {
  position: relative;
  display: block;
}

.review-one__content-top .info h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.review-one__content-top .info h2 span {
  color: var(--insur-base);
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.review-one__content-top .reply-btn {
  position: relative;
  display: block;
}

.review-one__content-top .reply-btn i:before {
  color: var(--insur-primary);
  font-size: 15px;
}

.review-one__content-bottom {
  position: relative;
  display: block;
}

.review-one__content-bottom p {
  font-size: 16px;
  line-height: 30px;
  margin: 0px;
  letter-spacing: var(--insur-letter-spacing);
}

/*--------------------------------------------------------------
# Review Form One
--------------------------------------------------------------*/
.review-form-one {
  position: relative;
  display: block;
  padding: 0px 0 120px;
  margin-top: -4px;
}

.review-form-one__inner {
  position: relative;
  display: block;
}

.review-form-one__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.review-form-one__rate-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 33px;
}

.review-form-one__rate-text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: var(--insur-letter-spacing);
}

.review-form-one__rate {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.review-form-one__rate i {
  font-size: 16px;
  color: var(--insur-primary);
}

.review-form-one__rate i+i {
  margin-left: 5px;
}

.review-form-one__form {
  position: relative;
  display: block;
}

.review-form-one__form .row {
  --bs-gutter-x: 20px;
}

.review-form-one__input-box textarea {
  font-size: 14px;
  color: var(--insur-gray);
  height: 180px;
  width: 100%;
  background-color: var(--insur-extra);
  padding: 25px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  font-weight: 500;
  border-radius: var(--insur-bdr-radius);
  letter-spacing: var(--insur-letter-spacing);
}

.review-form-one__input-box.text-message-box {
  height: 180px;
}

.review-form-one__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.review-form-one__input-box input[type="text"],
.review-form-one__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: none;
  background-color: var(--insur-extra);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--insur-gray);
  display: block;
  font-weight: 500;
  border-radius: var(--insur-bdr-radius);
  letter-spacing: var(--insur-letter-spacing);
}

.review-form-one__btn {
  border: none;
}

/*--------------------------------------------------------------
# Cart Page
--------------------------------------------------------------*/
.cart-page {
  position: relative;
  display: block;
  background: var(--insur-white);
  padding: 113px 0px 120px;
}

.cart-page .table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media(max-width: 1199px) {
  .cart-table {
    min-width: 1170px;
  }
}

.cart-table {
  margin-bottom: 60px;
}

.cart-table thead th {
  color: var(--insur-black);
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding: 0;
  border: none;
  padding-bottom: 23px;
  letter-spacing: var(--insur-letter-spacing);
}

.cart-table thead th:last-child {
  text-align: right;
}

.cart-table tbody tr {
  vertical-align: middle;
}

.cart-table tbody tr:last-child {
  border-bottom: 1px solid #eeede5;
}

.cart-table tbody td {
  font-size: 18px;
  color: var(--insur-gray);
  vertical-align: middle;
  border-top: 1px solid #e0e4e8;
  border-bottom: 1px solid #e0e4e8;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.cart-table tbody td:last-child {
  text-align: right;
}

.cart-table .product-box {
  display: flex;
  align-items: center;
}

.cart-table .product-box .img-box {
  position: relative;
  display: block;
  width: 120px;
  border-radius: var(--insur-bdr-radius);
  overflow: hidden;
  margin-right: 35px;
}

.cart-table .product-box .img-box img {
  width: 100%;
  border-radius: var(--insur-bdr-radius);
}

.cart-table h3 {
  color: var(--insur-black);
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  letter-spacing: var(--insur-letter-spacing);
}

.cart-table h3 a {
  color: var(--insur-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.cart-table h3 a:hover {
  color: var(--insur-base);
}

.cart-table .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 10px;
  height: 60px;
}

.cart-table .quantity-box input {
  width: 98px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid #e0e4e8;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-family: var(--insur-font);
  padding-left: 30px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--insur-gray);
}

.cart-table .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--insur-gray);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #fff;
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.cart-table .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top: 1px solid #eeede5;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.cart-table .cross-icon {
  position: relative;
  display: block;
}

.cart-table .cross-icon i:before {
  position: relative;
  display: inline-block;
  color: var(--insur-black);
  font-size: 16px;
}

.cart-cupon__form {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.cart-cupon__input {
  width: 100%;
  max-width: 375px;
  border-radius: var(--insur-bdr-radius);
  background-color: var(--insur-extra);
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 20px;
  font-family: var(--insur-font);
  font-size: 14px;
  color: var(--insur-gray);
  height: 60px;
  margin-bottom: 20px;
  border: none;
  outline: none;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.cart-cupon__form ::placeholder {
  color: var(--insur-gray);
  opacity: 1;
}

.cart-cupon__form button {
  top: -1px;
  border: none;
  outline: none !important;
  background-color: var(--insur-base);
}

.cart-cupon__form button:before {
  background-color: var(--insur-black);
}

.cart-total {
  position: relative;
  display: block;
  margin-bottom: 25px;
  margin-top: -4px;
}

.cart-total li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--insur-gray);
  font-size: 18px;
  font-weight: 400;
}

.cart-total li+li {
  margin-top: 20px;
}

.cart-total li span:first-child {
  display: block;
  color: var(--insur-black);
  font-size: 24px;
  margin-right: 75px;
  font-weight: 700;
  width: 155px;
  text-align: right;
  letter-spacing: var(--insur-letter-spacing);
}

.cart-total li:nth-child(2) span:first-child {
  position: relative;
  right: 6px;
}

.cart-total-amount {
  color: var(--insur-primary);
  font-weight: 500;
}

.cart-page__buttons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cart-page__buttons-1 {
  position: relative;
  display: block;
}

.cart-page__buttons-1 .thm-btn {
  background: var(--insur-black);
}

.cart-page__buttons-1 .thm-btn::before {
  background: var(--insur-base);
}

.cart-page__buttons-1 .thm-btn::after {
  background: var(--insur-base);
}

.cart-page__buttons-2 {
  position: relative;
  display: block;
  margin-left: 10px;
}

.cart-page__buttons-2 .thm-btn {
  background-color: var(--insur-base);
}

.cart-page__buttons-2 .thm-btn:before {
  background-color: var(--insur-black);
}

/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.checkout-page {
  position: relative;
  display: block;
  padding: 113px 0 120px;
}

.checkout-page .billing_details {
  position: relative;
  display: block;
}

.billing_title {
  position: relative;
  display: block;
  margin-bottom: 41px;
}

.billing_title p {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.billing_title span {
  color: var(--insur-base);
}

.billing_title h2 {
  font-size: 40px;
  line-height: 45px;
  margin: 0;
  font-weight: 700;
  margin-top: 4px;
  letter-spacing: -0.04em;
}

.billing_details_form {
  position: relative;
  display: block;
}

.billing_details_form .bs-gutter-x-20 {
  --bs-gutter-x: 20px;
}


.billing_details_form .btn-light {
  border: none;
}

.billing_details_form .btn-light:hover {
  border: none;
}

.billing_input_box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.billing_input_box input[type="text"],
.billing_input_box input[type="email"],
.billing_input_box input[type="tel"] {
  height: 60px;
  width: 100%;
  border: none;
  background-color: var(--insur-extra);
  padding-left: 30px;
  padding-right: 30px;
  border-radius: var(--insur-bdr-radius);
  outline: none;
  font-size: 14px;
  color: var(--insur-gray);
  display: block;
  font-weight: 500;
}

.billing_details .checked-box {
  position: relative;
  display: block;
  margin-top: -2px;
}

.billing_details .checked-box label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: var(--insur-gray);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
  font-family: var(--insur-font);
}

.billing_details .checked-box input[type="checkbox"] {
  display: none;
}

.billing_details .checked-box input[type="checkbox"]+label span {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: transparent;
  background: var(--insur-primary);
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 0;
}

.billing_details .checked-box label span:before {
  position: absolute;
  top: 4px;
  left: 6px;
  display: block;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  content: '';
  width: 6px;
  height: 9px;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.billing_details.checked-box input[type="checkbox"]:checked+label span {
  border-color: #ffffff;
}

.billing_details .checked-box input[type="checkbox"]:checked+label span:before {
  opacity: 1;
}

.ship_different_address_title {
  margin-top: 28px;
}

.ship_different_address_title h2 span::before {
  position: relative;
  display: inline-block;
  color: var(--insur-primary);
  font-size: 19px;
  top: -2px;
  left: 15px;
}

.ship_different_address_form .ship_different_input {
  position: relative;
  display: block;
  height: 170px;
}

.ship_different_address_form .ship_different_input textarea {
  font-size: 14px;
  color: var(--insur-gray);
  height: 170px;
  width: 100%;
  background-color: var(--insur-extra);
  padding: 15px 30px 20px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  border-radius: var(--insur-bdr-radius);
  font-weight: 500;
}

.billing_details_form .select-box .nice-select {
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
  color: var(--insur-gray);
  font-size: 14px;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-bottom: 20px;
  letter-spacing: var(--insur-letter-spacing);
}

.billing_details_form .select-box .nice-select:after {
  position: absolute;
  right: 30px;
}

.your_order {
  position: relative;
  display: block;
  padding-top: 54px;
}

.your_order h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 54px;
  letter-spacing: -0.04em;
}

.order_table_box {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--insur-bdr-color);
  padding-bottom: 24px;
}

.order_table_detail {
  position: relative;
  width: 100%;
}

.order_table_head {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--insur-bdr-color);
  border-top: 1px solid var(--insur-bdr-color);
}

.order_table_head th {
  font-size: 20px;
  color: var(--insur-black);
  font-weight: 700;
  margin: 0;
  padding: 24px 0 24px;
  letter-spacing: var(--insur-letter-spacing);
}

.order_table_head th.right {
  float: right;
}

.order_table_detail tbody td {
  padding: 29px 0 2px;
}

.order_table_detail tbody td.pro__title {
  color: var(--insur-gray);
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

.order_table_detail tbody td.pro__price {
  color: var(--insur-gray);
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.checkout__payment {
  background-color: var(--insur-extra);
  border-radius: var(--insur-bdr-radius);
  padding: 49px 50px 18px;
  margin-bottom: 30px;
}

.checkout__payment__item+.checkout__payment__item {
  margin-top: 41px;
}


.checkout__payment__title {
  display: flex;
  color: var(--insur-black);
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  align-items: center;
  margin-bottom: 27px;
  cursor: pointer;
  letter-spacing: -0.04em;
}

.checkout__payment__title::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 2px solid #e0e4e8;
  border-radius: 50%;
  margin-right: 10px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
  top: 0px;
  transition: all 500ms ease;
}

.checkout__payment__title img {
  margin-left: 15px;
}

.checkout__payment__item--active .checkout__payment__title::before {
  background-color: var(--insur-primary);
  border-color: var(--insur-primary);
  content: '\f00c';
}

.checkout__payment__content {
  font-size: 16px;
  line-height: 30px;
  color: var(--insur-gray);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
  margin-left: 35px;
}

.main-menu__cart {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 10px;
}

.main-menu__cart:hover {
  color: var(--insur-base);
}

.main-menu__main-menu-box-search-get-quote-btn {
  padding-left: 44px;
}

.main-menu__main-menu-box-search:before {
  left: 0;
}

.main-menu__main-menu-box-search {
  padding-left: 20px;
}

.main-menu__right {
  float: left;
  margin-left: 20px;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 37px;
}


.main-menu-two__cart {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 10px;
}

.main-menu-two__cart:hover {
  color: var(--insur-base);
}

.main-menu-three__cart {
  font-size: 20px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 10px;
}

.main-menu-three__cart:hover {
  color: var(--insur-base);
}