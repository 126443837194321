@use "sass:map-get";

$theme-colors: (
    "info": tomato,
    "danger": teal,
    "primary": #151d41,
    "secondary": rgb(0, 146, 137),
);

.ova-store .banner{
    height: 100vh;
    position: relative;
    /* background-image: url(../img/store-banner.jpg); */
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: rgba(21, 29, 65, 1);
}
/* 
.ova-store .banner::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(21, 29, 65, .7);
} */

.ova-store .mySwiper{
    height: 100vh;
}

.ova-store .mySwiper .banner-image {
    margin: auto;
}

.ova-store .mySwiper .banner-text p {
    color: #fff;
}

.ova-store .mySwiper .container .row .col-12 {
    height: 100%;
}

.ova-store .mySwiper .banner-text {
    color: #fff !important;
    text-align: left;
    margin: auto;
}

.ova-store .mySwiper .banner-text .btn-primary {
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    box-shadow: 0px 0px 10px #008D9A;
    transition: .7s;
}

.ova-store .mySwiper .banner-text .btn-primary {
    animation: shake 2s ease infinite;
  }
  @keyframes shake {
      0%, 100% {transform: translateX(0);}
      10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
      20%, 40%, 60%, 80% {transform: translateX(10px);}
  }

.ova-store .mySwiper .banner-text .btn-primary:hover,.ova-store .mySwiper .banner-text .btn-primary:focus {
    background-color: #fff;
    color: #008D9A;
}

.ova-store .swiper-pagination span {
    height: 20px !important;
    width: 20px !important;
    transition: .7s;
}

.ova-store .swiper-pagination span.swiper-pagination-bullet-active {
    background: #fff !important;
}

.ova-store .scroll {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    width: 100px;
}

.ova-store .scroll .arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 20px;
}

.ova-store .scroll .arrows.end {
    transform: rotate(180deg);
}
  
.ova-store .scroll .arrows path {
    stroke: #2994D1;
    fill: transparent;
    stroke-width: 1px;  
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; 
}
  
@keyframes arrow
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
@-webkit-keyframes arrow /*Safari and Chrome*/
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  .ova-store .scroll .arrows path.a1 {
    animation-delay:-1s;
    -webkit-animation-delay:-1s; /* Safari 和 Chrome */
  }
  
  .ova-store .scroll .arrows path.a2 {
    animation-delay:-0.5s;
    -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
  }
  
  .ova-store .scroll .arrows path.a3 { 
    animation-delay:0s;
    -webkit-animation-delay:0s; /* Safari 和 Chrome */
  }

@media screen and (max-width: 600px) {
    .ova-store .swiper-pagination {
        right: 50px !important;
    }
    .ova-store .mySwiper .container .row .col-12 {
        height: 50%;
    }
    .ova-store .mySwiper .container .row {
        flex-direction: column-reverse;
    }
    .ova-store .mySwiper .container .row .banner-image img {
        width: 100%;
    }
    .ova-store .mySwiper .banner-text {
        margin-top: 0px;
    }
}


.ova-store .swiper-pagination {
    right: 100px;
}

.hosting .hosting-stats, .hosting .hosting-money-back {
    padding: 50px 0;
    background-color: rgba(0,146,137,.03);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1230.153' height='788.291'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.918' y1='-.129' x2='.154' y2='.922' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23151d41'/%3E%3Cstop offset='1' stop-color='%2304060d' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.767' y1='.041' x2='-1.269' y2='1.092' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23009289' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23004945'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath d='M1802.325 631.541l-177.148 337.5-.179.342-102.864-202.895-68.653-134.947h-221.424l389.143 787.824 115.943-230.236 280.792-557.589z' transform='translate(-1232.057 -631.541)' opacity='.099' fill='url(%23a)'/%3E%3Cpath d='M1695.243 633.378L1582.4 846.874l177.326 354.262h-340.549l-98.739 211.149h771.775z' transform='translate(-862.06 -623.994)' fill='url(%23b)'/%3E%3C/svg%3E");
    background-size: 50%;
    background-position: bottom 0 right -30px;
    background-repeat: no-repeat;
}

.hosting .hosting-stats .card, .hosting .hosting-problems .card, .hosting .hosting-services .card {
    border: none;
    padding: 2rem 1rem;
    text-align: center;
    box-shadow: 0px 10px 50px rgba(0, 59, 121, 0.0725798);
    border-radius: 10px;
}

.hosting .hosting-stats .card .avatar, .hosting .hosting-problems .card .avatar, .hosting .hosting-services .card .avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    margin: 0px auto;
}

.hosting .hosting-problems .card .avatar, .hosting .hosting-services .card .avatar {
    background-repeat: no-repeat;
    height: 100px;
    width: 100px;
    border-radius: 10px;
    background-color: #008d9a65;
    background-size: 50%;
}
.hosting .owl-carousel .owl-stage-outer {
    /* overflow-y: visible;
    overflow-x: hidden; */
    box-shadow: 0px 10px 50px rgba(0, 59, 121, 0.0725798);
}

.hosting .owl-partners .card .avatar {

    filter: grayscale(100%);
}

.hosting .owl-partners .card:hover .avatar {

    filter: grayscale(0%);
}

.hosting .hosting-problems {
    padding: 50px 0px;
    background: linear-gradient(45deg, rgba(21, 29, 65, 1) 40%, rgba(21, 29, 65, .8));
}

.hosting .hosting-problems h3, .hosting .hosting-problems p, .hosting .hosting-one-click h3, .hosting .hosting-one-click p {
    color: #fff;
}

.hosting .hosting-problems .card p {
    color: rgba(21, 29, 65, 1) !important;
}

/* Hostingor From Another World */

.hosting .hosting-another-world {
    padding: 50px 0px;
}

.hosting .hosting-another-world .list {

    text-align: left;
}

.hosting .hosting-another-world .list ul {
    list-style: none;
    padding: 0;
}

.hosting .hosting-another-world .list ul li {
    height: 20px;
}
.hosting .hosting-another-world .list ul li:before {
    content: "";
    background-image: url("../../assets/img/hosting/check-mark\ 2.png");
    background-size: 70%;
    height: 20px;
    width: 20px;
    background-position: bottom;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
}

.hosting .hosting-one-click, .hosting .hosting-statistics{
    padding: 50px 0px;
    /* background-color: linear-gradient(45deg, rgba(21, 29, 65, 1) 40%, rgba(21, 29, 65, .8)); */
    background-image: url("../../assets/img/hosting/wave-bg\ 1.png");
    background-color: rgba(21, 29, 65, 1);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.hosting .hosting-one-click .text-block {
    text-align: left;
    margin: auto 0px;
}

/* {Level Up your web hosting with these must-have.} */

.hosting .hosting-level-up {
    padding: 70px 0px;
    text-align: left;
}

.hosting .hosting-level-up .level-up-card {
    box-shadow: 0px 10px 50px 0px #003B7913;
    border-radius: 20px;
    display: flex;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
}

.hosting .hosting-level-up .level-up-card div:first-child div {
    border-radius: 1rem;
    border-radius: 10px;
    background-color: #008d9a65;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 70px;
    height: 70px;
    margin: auto ;
    /* margin-right: 1rem; */
}


.hosting .hosting-level-up .level-up-card div:first-child {
    display: flex;
    min-width: 150px;
}

.hosting .hosting-level-up .level-up-card .image {
    min-width: 150px;
    display: flex;
}

/* So what's in Shared Hosting? A whole lot. */

.hosting .hosting-shared-hosting {

    background-image: url("../../assets/img/hosting/wave-Cta\ 1.png");
    background-color: rgba(21, 29, 65, 1);
    padding: 50px 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hosting .hosting-shared-hosting .cards .card {

    box-shadow: 0px 10px 50px rgba(0, 59, 121, 0.0725798);
    border: none;
    border-radius: 20px;
    padding: 1rem;
}

.hosting .hosting-shared-hosting .cards .card .price {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;

    margin: 50px 0px;
}

.hosting .hosting-shared-hosting .cards .card .offer {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
}

.hosting .hosting-shared-hosting .btn-primary-outlined {
    border-radius: 10px;
    border: 2px solid rgba(0, 59, 121, 1);
    width: fit-content;
    margin: auto;
    position: relative;
}

.hosting .hosting-shared-hosting .btn-primary-outlined:focus, .hosting .hosting-shared-hosting .btn-primary-outlined:hover {
    box-shadow: none;
    background-color: rgba(0, 59, 121, .3);
    transition-duration: 0.1s;
}

/* Outlined button animation */

  
.hosting .hosting-shared-hosting .btn-primary-outlined:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px rgba(0, 59, 121, .3);
}
  
.hosting .hosting-shared-hosting .btn-primary-outlined:active:after {
    box-shadow: 0 0 0 0 rgba(0, 59, 121, .3);
    position: absolute;
    border-radius: 10px;
    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
}
  
.hosting .hosting-shared-hosting .btn-primary-outlined:active {
    top: 1px;
}


.hosting .hosting-shared-hosting .cards .card.recomended {
    background-color: #008D9A;
    color: #fff !important;
}

.hosting .hosting-shared-hosting .cards .card.recomended .price,
.hosting .hosting-shared-hosting .cards .card.recomended .btn,
.hosting .hosting-shared-hosting .cards .card.recomended p
{
    color: #fff;
}

/* {Frequently asked questions}*/

.hosting .hosting-faq {
    padding: 50px 0px;
}


.hosting .hosting-money-back {
    padding: 50px 0px;

}

.hosting .hosting-money-back .block-image {
    text-align: right;
    padding-right: 50px;
}

@media screen and (max-width: 600px) {
    .hosting .hosting-money-back .block-image {
        text-align: center;
    }
    .hosting .hosting-another-world .list {
        padding: 1rem;
    }
}
.hosting .hosting-money-back .block-text {
    text-align: left;
    margin: auto 0px;
}

.btn-primary:focus ,.btn-primary:hover {
    background-color: #008D9A;
}

/* Payment Options */

.hosting .hosting-payment-options {
    padding: 50px 0px;
}

/* Find domain */

.hosting .search-domain {
    text-align: left;
    padding: 50px 0px;
    background-color: #008d9a2c;
}

.mr-auto {
    margin-right: auto;
}

.border-none {
    border: none;
}

.hosting .search-domain .form-inline input {
    border: none;
    background-color: #F6F6F6;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: .7s;
}
.hosting .search-domain .form-inline input:focus {
    box-shadow: none;
    border: 1px solid #008D9A;
}
.hosting .search-domain button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.hosting .search-domain button:focus {
    box-shadow: none;
}

.hosting .domains {
    border-left: 1px solid #F6F6F6;
    padding-left: 1rem;
}

.hosting .domains .domain-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #008D9A;
    margin-bottom: 2px;
}

.hosting .domains .domain-price {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #03045E;

    opacity: 0.3;

}

/* Our hosting services */

.hosting .hosting-services {
    padding: 50px 0px;
    background-color: #F6F6F6;
} 


.hosting .hosting-statistics .stat .label {
    color: #fff;
}

.hosting .hosting-statistics .stat .value {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 69px;
}

/* Store page styles */

.store-main {
    background-color: #EDEDED;
    min-height: 100vh;
}

.article-card-title {
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
}

.article-card-content {
    text-align: left;
}

.cart-article-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.cart-article-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.product-actions{
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all .7s;
}

.product-card{
    position: relative;
    overflow: hidden;
}

.product-card:hover .product-actions {
    top: 50%;
}

.store-main {
    padding-top: 150px;
}

/* OVA store checkout page */

.checkout-page {
    padding: 120px 0px;
    background-color: #E5E5E5;
}

.text-error {
    color: red;
}

.ova-store .mySwiper .banner-text h1 {
    color: #ffffff;
}

.page-titles .page-title {
    text-align: center;
    color: map-get($theme-colors, "secondary");
    font-size: 25px;
    font-weight: 600;
} 

.page-titles .page-title-1 {
    color: map-get($theme-colors, "secondary");
    text-align: left;
    font-size: 20px;
    margin: 2rem 0px;
    padding-left: 50px;
    position: relative;
}

.page-titles .page-title-1::before, .page-title .title::before {
    content: "";
    width: 45px;
    height: 2px;
    background-color: map-get($theme-colors, "secondary");
    position: absolute;
    top: 50%;
    left: 0px;
}

.page-title {
    text-align: center;
    justify-content: center;
    display: grid;
}

.page-title .title {
    width: fit-content;
    padding-left: 50px;
    position: relative;
}