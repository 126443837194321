

.footer-text-description, .footer-link {
    font-family: Hurme;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
}

.footer-menu-title {
    font-family: Hurme;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.footer-link {
    font-size: 13px;
}

.footer-link:hover {
    color: #009289;
    transition: all ease-in-out;
}

.footer-bottom {
    padding-top: 2rem;
    border-top: 1px solid #fff;
    max-width: none !important;
}

.p-t-2 {
    padding-top: 2rem !important;
}

footer.main-footer .footer-bottom .links {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

/* for header */

.company {
    background-image: url("../../assets/img/logov2.png");
}

header.main-header .icon-menu .position {
    background-image: url("../../assets/svg/place.svg");
}

header.main-header .icon-menu .position:hover {
    background-image: url("../../assets/svg/place_black.svg") !important;
    background-position: 1rem 10px !important;
}

.phone {
    background-image: url("../../assets/svg/phone.svg");
}



.recruitment {
    background-image: url("../../assets/svg/work.svg");
}

.recruitment:hover {
    background-image: url("../../assets/svg/work.svg");
    background-size: 25px !important;
}


@media screen and (max-width:440px){
    footer.main-footer .footer-bottom .branding .socials::after {
        width: 50px;
    }
}

header .main-menu .menu-toggle ,header .main-menu .menu-links {
    z-index: 1;
}
/* 
header .main-menu .menu-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100000px;
    left: 0;
    opacity: .4;
    background-color: #009289;
} */

.main-footer .footer-text-description .footer-link {
    text-decoration: none;
}