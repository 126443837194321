.mySwiper2-1 {
    max-height: 40vh;
}

.mySwiper-1 {
    max-height: 100px;
}

.swipers-container {
    padding-left: 50px;
}

.back-btn-container {
    padding-left: 50px;
    padding-bottom: 2rem;
}

.product-details-title {
    font-style: normal;
    font-weight: 700;
    text-align: left;
    font-size: 34px;
    line-height: 34px;
}

.product-details-title-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical; 
    text-align: left;
}

.product-details-category {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    
}

.product-details-details {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;   
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4; 
    -webkit-box-orient: vertical; 
    text-align: left;
}

.product-details-price {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    text-align: left;
}